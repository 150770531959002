import React, { Component } from "react";
import TagManager from "react-gtm-module";
import { getLocalStorage, getCookie } from "./actions/auth";
import GoogleAnalytics from "react-ga";
import { TAG_MANAGER } from "./config";

GoogleAnalytics.initialize(process.env.REACT_APP_GAID || "UA-115105611-2");
GoogleAnalytics.ga("create", "UA-102113790-2", "auto", {
  name: "LaDecoraTokStok",
});

const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = (page) => {
    if (process.env.NODE_ENV !== "production") {
      return;
    }
    GoogleAnalytics.set({
      page,
      ...options,
    });

    GoogleAnalytics.ga("LaDecoraTokStok.send", "pageview", { page: page });

    const gaId = getCookie("_ga");

    const user = JSON.parse(getLocalStorage("user"));
    const tagManagerArgs = {
      gtmId: TAG_MANAGER,
      dataLayer: {
        dimension1: user
          ? user.userIdTokStok
            ? user.userIdTokStok
            : user._id
          : undefined,
        dimension2: gaId,
        dimension3: TAG_MANAGER,
      },
    };

    TagManager.initialize(tagManagerArgs);
  };

  const BASENAME = process.env.REACT_APP_BASENAME || "";

  // eslint-disable-next-line
  const HOC = class extends Component {
    componentDidMount() {
      // eslint-disable-next-line
      const page = this.props.location.pathname + this.props.location.search;
      trackPage(`${BASENAME}${page}`);
    }

    componentDidUpdate(prevProps) {
      const currentPage =
        prevProps.location.pathname + prevProps.location.search;
      const nextPage =
        this.props.location.pathname + this.props.location.search;

      if (currentPage !== nextPage) {
        trackPage(`${BASENAME}${nextPage}`);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};

export default withTracker;
