import React, { useState, useEffect } from "react";
import {
  Row,
  Card,
  CardBody,
  Col,
  Button,
  Fade,
  Modal,
  ModalBody,
  ModalHeader,
} from "shards-react";
import PageTitle from "../../common/PageTitle";
import DropzoneArea from "../../file-manager-cards/DropzoneArea";
import { BUCKET_URL } from "./../../../config";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { updateProject } from "../../../actions/project";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Inspiration = (props) => {
  const [countFiles, setCountFiles] = useState();
  const [files, setFiles] = useState([]);
  const [text, setText] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [filesUploaded, setFilesUploaded] = useState([]);
  const [lightbox, setLightbox] = useState({ open: false, img: "" });
  const toggle = () => {
    setOpenModal(!openModal);
    props.project.completedSteps.push(STEP);
    props.project.inspirations.details = text;
    props.updateStepCompleted({
      completedSteps: props.project.completedSteps,
      inspirations: {
        ...props.project.inspirations,
        details: text,
      },
      _id: props.project._id,
    });
    props.callbackReturnView();
  };

  useEffect(() => {
    if (props.project.inspirations.files.length > 0) {
      setCountFiles(props.project.inspirations.files.length);
      setFiles(props.project.inspirations.files);
      setFilesUploaded(props.project.inspirations.files);
    }
    if (props.project.inspirations.details) {
      let details = props.project.inspirations.details;
      setText(details);
    }
  }, []);

  const STEP = 5;
  const update = (data) => {
    data = data.map((file) => {
      return file.name;
    });
    setCountFiles([...files, ...data].length);

    props.project.inspirations.files = [...files, ...data];
    setFiles([...files, ...data]);

    setFilesUploaded(props.project.inspirations.files);

    updateProject(props.project._id, props.project);
  };

  const removeImg = (idx) => {
    const data = props.project.inspirations.files;
    data.splice(idx, 1);
    setCountFiles(data.length);
    props.project.inspirations.files = data;
    setFiles(data);
    setFilesUploaded(props.project.inspirations.files);
    updateProject(props.project._id, props.project);
  };

  const nextStep = () => {
    if (text.length > 0) {
      setOpenModal(true);
    } else {
      toast.error(
        "Para que a gente entenda bem as suas expectativas, esse passo é muito importante. Pedimos que descreva para a gente um pouco mais no campo de texto sobre o que você espera desse espaço, assim como seus desejos e como será o uso.",
        {
          position: "top-right",
          autoClose: 20000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  const sendLightbox = (img) => {
    setLightbox({ img: img, open: true });
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Row noGutters className="page-header py-4">
        <PageTitle
          title={`Ambiente:  ${props.project.name}`}
          subtitle="Visão Geral"
          md="12"
          className="ml-sm-auto mr-sm-auto"
        />
      </Row>
      {lightbox.open && (
        <Lightbox
          mainSrc={lightbox.img}
          reactModalStyle={{ overlay: { zIndex: 10000 } }}
          onCloseRequest={() => setLightbox({ ...lightbox, open: false })}
        />
      )}
      <Row>
        <Col>
          <Card>
            <CardBody>
              <label
                theme="accent"
                className="mb-1"
                style={{ border: "none", cursor: "pointer" }}
                onClick={() => {
                  props.goBack();
                }}
              >
                &#8592; VOLTAR
              </label>
              <h5>
                Gostaria de nos informar algo a mais sobre suas inspirações ou
                detalhes?
              </h5>
              <h6>
                Para criarmos um ambiente personalizado precisamos entender bem
                as suas expectativas e necessidades, portanto esse passo é muito
                importante. Pedimos que descreva para a gente um pouco sobre o
                que você espera desse espaço, assim como seus desejos e como
                será a utilização do ambiente.
                <br />
                Envie para nós algumas fotos sobre o que mais te agrada e
                inspira no dia a dia!
              </h6>

              <span></span>
              <DropzoneArea
                projectId={props.project._id}
                callbackHandleDrop={update}
              />
              {countFiles && (
                <Fade in={!!countFiles} theme="accent">
                  Arquivos enviados: <b>{countFiles}</b>
                </Fade>
              )}
              <br />
              {filesUploaded && filesUploaded.length > 0 && (
                <div className="user-details__user-data border-top border-bottom p-4">
                  <Row>
                    <Col>
                      <h6 className="text-center m-0 mt-2">Arquivos</h6>
                    </Col>
                  </Row>
                  <Row>
                    {filesUploaded.map((img, idx) => {
                      return (
                        <Col
                          sm="12"
                          md="6"
                          lg="4"
                          className="pt-2 text-center"
                          key={idx}
                        >
                          <Card
                            small
                            className="mb-3"
                            style={{ width: "200px" }}
                          >
                            <CardBody className="p-0">
                              <img
                                alt=""
                                className="border rounded img-fluid"
                                style={{ width: "200px", height: "200px" }}
                                src={
                                  BUCKET_URL +
                                  "/projects/" +
                                  props.project._id +
                                  "/customer/" +
                                  img
                                }
                                onClick={() =>
                                  sendLightbox(
                                    BUCKET_URL +
                                      "/projects/" +
                                      props.project._id +
                                      "/customer/" +
                                      img
                                  )
                                }
                              />
                              <Button
                                outline
                                style={{ width: "200px" }}
                                theme="danger"
                                onClick={() => {
                                  removeImg(idx);
                                }}
                              >
                                Excluir
                              </Button>
                            </CardBody>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              )}
              <textarea
                value={text}
                onChange={(e) => {
                  setText(e.target.value);
                }}
                rows="4"
                className="form-control mt-2"
                placeholder="Conte todos os detalhes de observação ou que gostaria que soubéssemos sobre as suas inspirações"
              />
              <br />
              <div style={{ float: "right" }}>
                <Button theme="accent" onClick={nextStep}>
                  Continuar
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <br />

      <br />
      <Modal open={openModal} animation toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <span role="img" aria-label="">
            👋
          </span>{" "}
          Olá
        </ModalHeader>
        <ModalBody>
          Legal! Estas informações são importantes para sabemos seus gostos e
          preferências para o ambiente, assim vamos desenvolver uma decoração
          personalizada, levando em consideração tudo o que nos pediu!
          <br />
          No próximo passo, você vai indicar se quer manter algum móvel ou
          objeto, favor nos enviar fotos e medidas.
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
export default Inspiration;
