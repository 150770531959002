import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";

import PrivateRoute from "./components/Auth/PrivateRoute";
import withTracker from "./withTracker";
import { LANDING_PAGE_TOKSTOK } from "./config";

// Layout Types
import { DefaultLayout } from "./layouts";
import WithoutSidebarLayout from "./layouts/WithoutSidebar";

// Route Views
import Validator from "./views/Validator";
import ValidatorUser from "./views/ValidatorUser";
import ValidatorDev from "./views/ValidatorDev";

import Environments from "./views/Environments";
import EnvironmentsList from "./views/EnvironmentsList";
import TransactionHistory from "./views/TransactionHistory";
import FaqPlataforma from "./views/FaqPlataforma";
import CheckoutTokStok from "./views/CheckoutTokStok";
import Gift from "./views/Gift";
import Error404 from "./components/common/404.js";

const PUBLIC_ROUTES = [
  {
    path: "/dev-validator",
    exact: true,
    layout: WithoutSidebarLayout,
    component: ValidatorDev,
  },
  {
    path: "/validator",
    layout: WithoutSidebarLayout,
    component: Validator,
  },
  {
    path: "/validator-user",
    layout: WithoutSidebarLayout,
    component: ValidatorUser,
  },
  {
    path: "/gift/tok_stok",
    layout: WithoutSidebarLayout,
    component: Gift,
  },
  {
    path: "/404",
    layout: WithoutSidebarLayout,
    component: Error404,
  },
];

const PRIVATE_ROUTES = [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/environments" />,
  },
  {
    path: "/environments",
    layout: DefaultLayout,
    component: Environments,
  },
  {
    path: "/checkout-tokstok",
    layout: DefaultLayout,
    component: CheckoutTokStok,
  },
  {
    path: "/environments-list",
    layout: DefaultLayout,
    component: EnvironmentsList,
  },
  {
    path: "/transaction-history",
    layout: DefaultLayout,
    component: TransactionHistory,
  },
  {
    path: "/faq",
    layout: DefaultLayout,
    component: FaqPlataforma,
  },
];

const Routes = () => (
  <Switch>
    {getRoutes()}
    <Redirect to="/" />
  </Switch>
);

function getRoutes() {
  let indexCounter = 1;
  const privateRoutes = PRIVATE_ROUTES.map((route) => {
    return (
      <PrivateRoute
        key={indexCounter}
        {...route}
        route={route}
        index={indexCounter++}
        redirectPath={LANDING_PAGE_TOKSTOK}
        component={withTracker((props) => {
          return (
            <route.layout {...props}>
              <route.component {...props} />
            </route.layout>
          );
        })}
      />
    );
  });
  const publicRoutes = PUBLIC_ROUTES.map((route) => {
    return (
      <Route
        key={indexCounter++}
        path={route.path}
        exact={route.exact}
        component={withTracker((props) => {
          return (
            <route.layout {...props}>
              <route.component {...props} />
            </route.layout>
          );
        })}
      />
    );
  });
  return publicRoutes.concat(privateRoutes);
}

export default Routes;
