import { API } from "../config";
import { getCookie } from "../actions/auth";

export const getAllVouchers = () => {
  const token = getCookie("token");

  return fetch(`${API}/voucher/all`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getVoucher = (id) => {
  const token = getCookie("token");

  return fetch(`${API}/voucher/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getVoucherByCode = (code) => {
  const token = getCookie("token");

  return fetch(`${API}/voucher/code/${code}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};
