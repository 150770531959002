import React, { useState, useEffect, useContext } from "react";
import {
  Row,
  Card,
  CardBody,
  CardFooter,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from "shards-react";
import classNames from "classnames";
import PageTitle from "../../common/PageTitle";
import Slider from "react-slick";
import { BUCKET_URL } from "../../../config";
import { updateProject, updateLogDates } from "../../../actions/project";
import { toast, ToastContainer } from "react-toastify";
import PropTypes from "prop-types";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import ReviewImage from "./ReviewImage";

import { Launcher } from "@julianograms/react-chat-elements";
import "@julianograms/react-chat-elements/dist/main.css";

import {
  sendMessage,
  getChatMessages,
  readMessages,
  uploadFile,
} from "../../../actions/message";

import { AuthContext } from "../../Auth/AuthContext";
import { formatBytes } from "../../../utils/sanitize";
import { downloadLink, download } from "../../../utils/util";

const ProjectChat = ({
  senderId,
  onNewMessages,
  show,
  onChatToggle,
  chatOpened,
}) => {
  const { user } = useContext(AuthContext);
  const [messages, setMessages] = useState([]);
  const [badgeCount, setBadgeCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [, setState] = useState();

  function onUpdate() {
    setState({});
  }
  function onUserNewMessage(message) {
    message.deletable = false;
    const time = message.time;
    const newMessages = [
      ...messages,
      {
        ...message,
        position: message.author === "me" ? "right" : "left",
        type: "text",
        text: message.data.text,
        deletable: false,
        date: message.time,
      },
    ];
    setMessages(newMessages);
    sendMessage(user._id, message.data.text, senderId, "preview").then(
      (res) => {
        setMessages([
          ...newMessages.filter((m) => m.time !== time),
          {
            ...res,
            author: res.type === "user" ? "me" : "them",
            type: "text",
            deletable: false,
            time: new Date(res.createdAt),
            text: res.text,
          },
        ]);
      }
    );
  }

  useEffect(() => {
    getChatMessages(senderId, "preview").then((res) => {
      const messageList = res.sort((a, b) => {
        let d1 = new Date(a.createdAt);
        let d2 = new Date(b.createdAt);
        if (d1 > d2) return 1;
        if (d2 > d1) return -1;
        return 0;
      });

      if (messageList.length) {
        onNewMessages();
        if (
          messageList.some(
            (message) => !message.isRead && message.type === "system"
          )
        ) {
          onChatToggle(true);
        }
      }

      setMessages(
        messageList.map((message) => {
          return {
            author: message.type === "system" ? "them" : "me",
            type: message.kind,
            data: {
              height: 250,
              width: 250,
              size: formatBytes(message.size),
              uri: message.kind === "photo" ? message.thumbnail : message.link,
              status: {
                click: false,
                loading: 0,
              },
            },
            time: new Date(message.createdAt),
            text: message.text,
          };
        })
      );

      const badgeCount = messageList.filter(
        (message) => !message.isRead && message.type === "system"
      ).length;

      setBadgeCount(badgeCount);
    });
  }, []);

  return show ? (
    <Launcher
      agentProfile={{
        teamName: "Meu Ambiente Tok&Stok",
        imageUrl: "",
      }}
      isOpen={isOpen || chatOpened}
      markdown={true}
      onMessageWasSent={onUserNewMessage}
      messageList={messages}
      newMessagesCount={badgeCount}
      showEmoji={false}
      placeholder="Escreva sua mensagem..."
      onFilesSelected={(files) => {
        const promises = [];
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          if (file.size > 10000000) {
            toast.error("Arquivo não pode ser maior que 10mb");
            return;
          }
          const kind = ["image/png", "image/jpeg", "image/jpg"].includes(
            file.type
          )
            ? "photo"
            : "file";

          const newMessage = {
            author: "me",
            type: kind,
            deletable: false,
            date: new Date(),
            text: file.name,
            data: {
              height: 250,
              width: 250,
              size: formatBytes(file.size),
              uri: "",
              status: {
                click: true,
                loading: 0,
                isSending: true,
              },
            },
          };
          promises.push(
            new Promise((success, failure) => {
              const reader = new FileReader();
              reader.addEventListener(
                "load",
                function() {
                  newMessage.data.uri = reader.result;
                  success({
                    message: newMessage,
                    kind,
                    file,
                  });
                },
                false
              );

              if (file) {
                reader.readAsDataURL(file);
              }
            })
          );
        }
        Promise.all(promises).then((results) => {
          const newMessages = messages.concat(
            results.map((result) => result.message)
          );
          setMessages(newMessages);
          results.forEach((result) => {
            const { file, message, kind } = result;
            uploadFile(file, senderId, kind, "user").then((res) => {
              message.data.status.click = false;

              message.data.status.isSending = false;
              message._id = res._id;
              message.data.uri = res.link;

              onUpdate();
            });
          });
        });
      }}
      onDownload={(message) => {
        download(message.data.uri, message.text);
      }}
      handleClick={() => {
        if (!isOpen && !chatOpened) {
          readMessages(senderId, "system").then(() => {});
          setBadgeCount(0);
        }
        setIsOpen(!(isOpen || chatOpened));
        onChatToggle(!(isOpen || chatOpened));
      }}
    />
  ) : null;
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const theme = createMuiTheme({
  overrides: {
    // Style sheet name
    MuiTab: {
      root: {
        "&$selected": {
          textColorPrimary: {
            color: "#0a775a !important",
          },
        },
      },
      wrapper: {
        selected: {
          color: "#0a775a",
        },
        color: "#0a775a",
      },
      textColorPrimary: {
        selected: {
          color: "#0a775a",
        },
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: "#0a775a",
      },
    },
  },
});

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, filter: "invert(1)" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, filter: "invert(1)" }}
      onClick={onClick}
    />
  );
}

const Review = (props) => {
  const [files, setFiles] = useState([]);
  const [filesFirstReview, setFilesFirstReview] = useState([]);
  const [filesSecondReview, setFilesSecondReview] = useState([]);
  const [projectId, setProjectId] = useState("");
  const [project, setProject] = useState();
  const [lightbox, setLightbox] = useState({ open: false, img: "" });
  const [valueTab, setValueTab] = useState(0);
  const [modalChooseProject, setModalChooseProject] = useState(false);
  const requestRevisionDetails = "";
  const [openModalRequestRevision, setOpenModalRequestRevision] = useState(
    false
  );
  const [showChat, setShowChat] = useState(false);
  const [chatOpened, setChatOpened] = useState(false);
  const [suggestionsEnabled, setSuggestionsEnabled] = useState(false);

  useEffect(() => {
    setFiles(props.project.finalProject.preview.files);

    /* eslint-disable  */

    if (props.project.finalProject.firstReview.files != "") {
      setFilesFirstReview(props.project.finalProject.firstReview.files);
    }
    if (props.project.finalProject.secondReview.files != "") {
      setFilesSecondReview(props.project.finalProject.secondReview.files);
    }

    if (
      isReviewApproved("2º Review") ||
      (!isReadOnly() && props.project.finalProject.secondReview.lastUpdate)
    ) {
      setValueTab(2);
    } else if (
      isReviewApproved("1º Review") ||
      (!isReadOnly() && props.project.finalProject.firstReview.lastUpdate)
    ) {
      setValueTab(1);
    } else if (
      isReviewApproved("Preview") ||
      (!isReadOnly() && props.project.finalProject.preview.lastUpdate)
    ) {
      setValueTab(0);
    }

    /* eslint-disable  */

    setProjectId(props.project._id);
    setProject(props.project);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const update = () => {
    project.finalProject.preview.files = files;
    project.finalProject.firstReview.files = filesFirstReview;
    project.finalProject.secondReview.files = filesSecondReview;
    setProject(project);
    updateProject(projectId, project).then(() => {
      toast.success("Comentário enviado com sucesso!", { autoClose: 5000 });
    });
  };

  const requestRevision = (ev) => {
    if (suggestionsEnabled) {
      let el = document
        .querySelector(".MuiTab-textColorPrimary.Mui-selected")
        .getAttribute("data-tab-detail");
      if (el !== undefined) {
        switch (el) {
          case "preview":
            props.project.finalProject.preview.details = requestRevisionDetails;
            break;
          case "first":
            props.project.finalProject.firstReview.details = requestRevisionDetails;
            break;
          case "second":
            props.project.finalProject.secondReview.details = requestRevisionDetails;
            break;
          default:
            break;
        }

        if (props.project.status === "second_review") {
          props.project.status = "waiting_render_reproved";
        } else {
          props.project.status = "waiting_confirm_review";
        }
        updateLogDates(props.project._id, props.project.status);

        props.project.reprovedMsg = requestRevisionDetails;

        props.project.finalProject.preview.files = files;
        props.project.finalProject.firstReview.files = filesFirstReview;
        props.project.finalProject.secondReview.files = filesSecondReview;

        setProject({ ...props.project });
        setSuggestionsEnabled(false);
        updateProject(projectId, {
          finalProject: { ...props.project.finalProject },
          reprovedMsg: props.project.reprovedMsg,
          status: props.project.status,
        }).then(() => {
          toast.success("Solicitação enviada com sucesso!", {
            autoClose: 5000,
          });
        });
      }
    } else {
      toggleRequestRevisionModal();
    }
  };

  const approveProject = () => {
    toggleModalChooseProject();
  };

  const nextStep = (projectSelected = "Preview") => {
    updateLogDates(props.project._id, "waiting_render_approved");
    props.project.finalProject.preview.files = files;
    props.project.finalProject.firstReview.files = filesFirstReview;
    props.project.finalProject.secondReview.files = filesSecondReview;
    props.project.status = "waiting_render_approved";

    props.project.finalProject.approvedProject = projectSelected;

    props.updateStepCompleted({
      finalProject: { ...props.project.finalProject },
      status: "waiting_render_approved",
      _id: props.project._id,
    });
    props.callbackReturnView();
  };

  const handleChange = (img, comment) => {
    setFiles(
      files.map((file) => {
        return file._id === img._id
          ? {
              ...file,
              likeComments: comment.likeComments,
              dislikeComments: comment.dislikeComments,
            }
          : file;
      })
    );
  };

  const handleChangeFirstR = (img, comment) => {
    setFilesFirstReview(
      filesFirstReview.map((file) => {
        return file._id === img._id
          ? {
              ...file,
              likeComments: comment.likeComments,
              dislikeComments: comment.dislikeComments,
            }
          : file;
      })
    );
  };

  const handleChangeSecondR = (img, comment) => {
    setFilesSecondReview(
      filesSecondReview.map((file) => {
        return file._id === img._id
          ? {
              ...file,
              likeComments: comment.likeComments,
              dislikeComments: comment.dislikeComments,
            }
          : file;
      })
    );
  };

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const sendLightbox = (img) => {
    setLightbox({ img: img, open: true });
  };

  const toggleRequestRevisionModal = () => {
    setOpenModalRequestRevision(!openModalRequestRevision);
    setSuggestionsEnabled(true);
  };

  const toggleModalChooseProject = () => {
    setModalChooseProject(!modalChooseProject);
  };

  const haveQuestion = () => {
    setSuggestionsEnabled(false);
    setOpenModalRequestRevision(false);
    setChatOpened(true);
    setShowChat(true);
  };

  const disabledButton = () => {
    let btnDisabled = false;
    if (
      props.project.status === "waiting_render_approved" ||
      props.project.status === "waiting_render_reproved" ||
      props.project.status === "waiting_confirm_review" ||
      props.project.status === "customer-reproved" ||
      props.project.status === "waiting_quality" ||
      props.project.status === "reviewing_quality"
    ) {
      btnDisabled = true;
    }
    return btnDisabled;
  };

  const selectApprovedProject = (projectSelected) => {
    nextStep(projectSelected);
  };

  const onNewMessages = () => {
    setShowChat(true);
  };

  function onChatToggle(value) {
    if (value !== chatOpened) {
      setChatOpened(value);
    }
  }

  function isReviewApproved(name) {
    return (
      props.project &&
      props.project.finalProject.approvedProject === name &&
      ![
        "preview",
        "first_review",
        "second_review",
        "waiting_confirm_review",
        "waiting_user_feedback",
        "customer-reproved",
      ].includes(props.project.status)
    );
  }

  function isReadOnly() {
    return (
      props.project &&
      (props.project.status === "waiting_render_approved" ||
        props.project.status === "waiting_render_reproved" ||
        props.project.status === "waiting_confirm_review" ||
        props.project.status === "customer-reproved" ||
        props.project.status === "waiting_quality" ||
        props.project.status === "reviewing_quality")
    );
  }

  return (
    <React.Fragment>
      <ToastContainer autoClose={false} />
      {lightbox.open && (
        <Lightbox
          mainSrc={lightbox.img}
          reactModalStyle={{ overlay: { zIndex: 10000 } }}
          onCloseRequest={() => setLightbox({ ...lightbox, open: false })}
        />
      )}
      <Row noGutters className="page-header py-4">
        <PageTitle
          title={`Ambiente:  ${props.project.name}`}
          subtitle="Visão Geral"
          md="12"
          className="ml-sm-auto mr-sm-auto"
        />
      </Row>
      <Row className="mobile-review">
        <Col>
          <Card>
            <CardBody>
              <label
                theme="accent"
                className="mb-1"
                style={{ border: "none", cursor: "pointer" }}
                onClick={() => {
                  props.goBack(true);
                }}
              >
                &#8592; VOLTAR
              </label>
              <h5>Chegou a hora de avaliar à sua assessoria!</h5>
              <ul>
                <li>
                  Se a sua assessoria estiver de acordo clique em Aprovar
                  assessoria que iremos produzir o seu caderno. Caso tenha mais
                  de uma revisão, selecione a assessoria escolhida antes de
                  aprovar.
                </li>
                <li>
                  Caso você queira alguma modificação na assessoria clique em
                  Solicitar Revisão e comente as fotos do seu ambiente, após
                  comentar clique em confirmar revisão.
                </li>
                <li>
                  Se precisar tirar alguma dúvida sobre a assesoria, clique no
                  botão de Tirar dúvidas que em até 1 dia útil iremos responder.
                </li>
              </ul>
              <div className="mt-3">
                <div md="12" style={{ textAlign: "center" }}>
                  <div>
                    <ThemeProvider theme={theme}>
                      <AppBar position="static" color="default">
                        <Tabs
                          value={valueTab}
                          onChange={handleChangeTab}
                          indicatorColor="primary"
                          textColor="primary"
                          variant="fullWidth"
                          aria-label="full width tabs example"
                          centered
                        >
                          <Tab
                            label="Preview"
                            data-tab-detail="preview"
                            {...a11yProps(0)}
                          />
                          {filesFirstReview != "" ? (
                            <Tab
                              label="Revisão 1"
                              data-tab-detail="first"
                              {...a11yProps(1)}
                            />
                          ) : (
                            ""
                          )}
                          {filesSecondReview != "" ? (
                            <Tab
                              label="Revisão 2"
                              data-tab-detail="second"
                              {...a11yProps(2)}
                            />
                          ) : (
                            ""
                          )}
                        </Tabs>
                      </AppBar>
                      <TabPanel
                        value={valueTab}
                        index={0}
                        dir={theme.direction}
                      >
                        {isReviewApproved("Preview") ? (
                          <div>
                            <h3 style={{ color: "#00c853" }}>Aprovado</h3>
                          </div>
                        ) : null}
                        <Slider {...settings}>
                          {files.map((file, idx) => {
                            const imgLink =
                              BUCKET_URL +
                              "/projects/" +
                              projectId +
                              "/preview/" +
                              file.file;
                            return (
                              <ReviewImage
                                key={idx}
                                file={file}
                                disabled={!suggestionsEnabled}
                                projectId={projectId}
                                onChange={handleChange}
                                link={imgLink}
                                onImageClick={() => sendLightbox(imgLink)}
                              />
                            );
                          })}
                        </Slider>
                      </TabPanel>
                      <TabPanel
                        value={valueTab}
                        index={1}
                        dir={theme.direction}
                      >
                        {isReviewApproved("1º Review") ? (
                          <div>
                            <h3 style={{ color: "#00c853" }}>Aprovado</h3>
                          </div>
                        ) : null}
                        <Slider {...settings}>
                          {filesFirstReview.map((file, idx) => {
                            const imgLink =
                              BUCKET_URL +
                              "/projects/" +
                              projectId +
                              "/firstReview/" +
                              file.file;
                            return (
                              <ReviewImage
                                key={idx}
                                file={file}
                                disabled={!suggestionsEnabled}
                                projectId={projectId}
                                onChange={handleChangeFirstR}
                                link={imgLink}
                                onImageClick={() => sendLightbox(imgLink)}
                              />
                            );
                          })}
                        </Slider>
                      </TabPanel>
                      <TabPanel
                        value={valueTab}
                        index={2}
                        dir={theme.direction}
                      >
                        {isReviewApproved("2º Review") ? (
                          <div>
                            <h3 style={{ color: "#00c853" }}>Aprovado</h3>
                          </div>
                        ) : null}
                        <Slider {...settings}>
                          {filesSecondReview.map((file, idx) => {
                            const imgLink =
                              BUCKET_URL +
                              "/projects/" +
                              projectId +
                              "/secondReview/" +
                              file.file;
                            return (
                              <ReviewImage
                                key={idx}
                                file={file}
                                disabled={!suggestionsEnabled}
                                projectId={projectId}
                                onChange={handleChangeSecondR}
                                link={imgLink}
                                onImageClick={() => sendLightbox(imgLink)}
                              />
                            );
                          })}
                        </Slider>
                      </TabPanel>
                    </ThemeProvider>
                  </div>
                  {suggestionsEnabled ? (
                    <Button
                      theme="accent"
                      className="mt-2"
                      onClick={update}
                      style={{ float: "right" }}
                      disabled={disabledButton()}
                    >
                      Salvar e continuar depois
                    </Button>
                  ) : null}
                  <br />
                  <br />
                  <div
                    style={{ float: "center" }}
                    className="mt-2 mobile-bottom-buttons"
                  >
                    {!suggestionsEnabled ? (
                      <Button
                        size="lg"
                        theme="accent"
                        onClick={approveProject}
                        disabled={disabledButton()}
                      >
                        Aprovar assessoria
                      </Button>
                    ) : null}
                    {suggestionsEnabled ? (
                      <Button
                        size="lg"
                        theme="accent"
                        className="btn-outline-accent ml-2"
                        onClick={() => setSuggestionsEnabled(false)}
                      >
                        Voltar
                      </Button>
                    ) : null}

                    {props.project.status !== "second_review" ? (
                      <Button
                        theme="accent"
                        size="lg"
                        className={`${
                          !suggestionsEnabled ? "btn-outline-accent" : ""
                        } ml-2`}
                        onClick={requestRevision}
                        disabled={disabledButton()}
                      >
                        {suggestionsEnabled
                          ? "Confirmar Revisão"
                          : "Solicitar Revisão"}
                      </Button>
                    ) : null}
                    {!suggestionsEnabled ? (
                      <Button
                        theme="accent"
                        size="lg"
                        className="btn-outline-accent ml-2"
                        onClick={() => {
                          setChatOpened(true);
                          setShowChat(true);
                        }}
                        disabled={disabledButton()}
                      >
                        Tirar dúvidas
                      </Button>
                    ) : null}
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal
        size="lg"
        animation={true}
        open={openModalRequestRevision}
        toggle={toggleRequestRevisionModal}
      >
        <ModalHeader toggle={toggleRequestRevisionModal}>
          Por favor, forneça mais detalhes para a revisão.
        </ModalHeader>
        <ModalBody
          className="modalBodyRevisaò"
          style={{ maxHeight: "80vh", overflow: "auto" }}
        >
          <p style={{ textAlign: "center" }}>
            Caso deseje conversar com um especialista ou ainda tenha alguma
            dúvida sobre a assessoria, clique no botão "Tirar Dúvidas".
            <br />
            Caso não tenha nenhuma dúvida, você pode enviar suas observações
            sobre as imagens nos campos abaixo das mesmas.
          </p>
          <div className="center text-center mt-2 mobile-bottom-buttons">
            <Button
              size="lg"
              theme="accent"
              className="btn-outline-accent ml-2"
              onClick={() => haveQuestion()}
            >
              Tirar Dúvidas
            </Button>
            <Button
              theme="accent"
              size="lg"
              className="btn-accent ml-2"
              onClick={toggleRequestRevisionModal}
            >
              Não Tenho Dúvidas
            </Button>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        size="lg"
        animation={true}
        open={modalChooseProject}
        toggle={toggleModalChooseProject}
      >
        <ModalHeader toggle={toggleModalChooseProject}>
          Aprovar assessoria.
        </ModalHeader>
        <ModalBody
          className="modalBodyRevisaò"
          style={{ maxHeight: "80vh", overflow: "auto" }}
        >
          <Row>
            <p>
              Atenção! Ao aprovar a sua assessoria, ele vai direto para montagem
              de caderno e você não poderá solicitar mais nenhuma revisão. Você
              tem certeza de sua aprovação?
            </p>
          </Row>
          <Row>
            {files.map((file, idx) => {
              const classes = classNames(
                "mb-3",
                "file-manager__item",
                "file-manager__item--selected"
              );
              if (idx === 0) {
                return (
                  <Col key={idx}>
                    <Card small className={classes}>
                      <CardBody className="text-center file-manager__item-preview px-0 pb-0 pt-4">
                        <span className="file-manager__item-size ml-auto">
                          Preview
                        </span>
                        <br />
                        <img
                          src={
                            BUCKET_URL +
                            "/projects/" +
                            projectId +
                            "/preview/" +
                            file.file
                          }
                          alt={file.comments}
                          height="150"
                          width="150"
                          className={"mb-2"}
                          onClick={() =>
                            sendLightbox(
                              BUCKET_URL +
                                "/projects/" +
                                projectId +
                                "/preview/" +
                                file.file
                            )
                          }
                        />
                      </CardBody>
                      <CardFooter>
                        <div className="right text-right">
                          <Button
                            theme="accent"
                            size="sm"
                            onClick={() => {
                              selectApprovedProject("Preview");
                            }}
                          >
                            Escolher Preview
                          </Button>
                        </div>
                      </CardFooter>
                    </Card>
                  </Col>
                );
              }
            })}
            {filesFirstReview.map((file, idx) => {
              const classes = classNames(
                "mb-3",
                "file-manager__item",
                "file-manager__item--selected"
              );
              if (idx === 0) {
                return (
                  <Col key={idx}>
                    <Card small className={classes}>
                      <CardBody className="text-center file-manager__item-preview px-0 pb-0 pt-4">
                        <span className="file-manager__item-size ml-auto">
                          Revisão 1
                        </span>
                        <br />
                        <img
                          src={
                            BUCKET_URL +
                            "/projects/" +
                            projectId +
                            "/firstReview/" +
                            file.file
                          }
                          alt={file.comments}
                          height="150"
                          width="150"
                          className={"mb-2"}
                          onClick={() =>
                            sendLightbox(
                              BUCKET_URL +
                                "/projects/" +
                                projectId +
                                "/firstReview/" +
                                file.file
                            )
                          }
                        />
                      </CardBody>
                      <CardFooter>
                        <div className="right text-right">
                          <Button
                            theme="accent"
                            size="sm"
                            onClick={() => {
                              selectApprovedProject("1º Review");
                            }}
                          >
                            Escolher Revisão 1
                          </Button>
                        </div>
                      </CardFooter>
                    </Card>
                  </Col>
                );
              }
            })}
            {filesSecondReview.map((file, idx) => {
              const classes = classNames(
                "mb-3",
                "file-manager__item",
                "file-manager__item--selected"
              );
              if (idx === 0) {
                return (
                  <Col key={idx}>
                    <Card small className={classes} onClick="">
                      <CardBody className="text-center file-manager__item-preview px-0 pb-0 pt-4">
                        <span className="file-manager__item-size ml-auto">
                          Revisão 2
                        </span>
                        <br />
                        <img
                          src={
                            BUCKET_URL +
                            "/projects/" +
                            projectId +
                            "/secondReview/" +
                            file.file
                          }
                          alt={file.comments}
                          height="150"
                          width="150"
                          className={"mb-2"}
                          onClick={() =>
                            sendLightbox(
                              BUCKET_URL +
                                "/projects/" +
                                projectId +
                                "/secondReview/" +
                                file.file
                            )
                          }
                        />
                      </CardBody>
                      <CardFooter>
                        <div className="right text-right">
                          <Button
                            theme="accent"
                            size="sm"
                            onClick={() => {
                              selectApprovedProject("2º Review");
                            }}
                          >
                            Escolher Revisão 2
                          </Button>
                        </div>
                      </CardFooter>
                    </Card>
                  </Col>
                );
              }
            })}
          </Row>
        </ModalBody>
      </Modal>
      {props.project.status === "waiting_render_approved" ||
      props.project.status === "waiting_render_reproved" ? (
        ""
      ) : (
        <ProjectChat
          onNewMessages={onNewMessages}
          senderId={props.project._id}
          show={showChat}
          chatOpened={chatOpened}
          onChatToggle={onChatToggle}
        />
      )}
    </React.Fragment>
  );
};
export default Review;
