export const getNextForcedStep = (project) => {
  let step = null;

  if (project.forcedStep && project.forcedStep.length) {
    const pendingCorrectionSteps = project.forcedStep.filter(
      (step) => step.isPending
    );
    if (pendingCorrectionSteps.length) {
      return pendingCorrectionSteps[0];
    }
  }
  return step;
};

export const getNextForcedStepKey = (project) => {
  const nextForcedStep = getNextForcedStep(project);
  if (nextForcedStep) {
    return parseInt(nextForcedStep.key);
  } else {
    return null;
  }
};

export const modifyDate = (date, status) => {
  if (status === "Em progresso" || status === "Aguardando resposta") {
    return "";
  } else if (status === "Confirmar dados") {
    return addingDayWork(date, 1);
  } else if (status === "Em produção") {
    return addingDayWork(date, 5);
  } else if (status === "Em Revisão") {
    return addingDayWork(date, 3);
  } else if (status === "Aguardando Projeto Final") {
    return addingDayWork(date, 3);
  } else if (status === "Projeto Entregue") {
    return "";
  } else if (status === "Preview") {
    return "";
  } else if (status === "1º Review") {
    return "";
  } else if (status === "2º Review") {
    return "";
  } else if (status === "Confirmar dados revisão") {
    return addingDayWork(date, 1);
  } else if (status === "Aguardando resposta cliente") {
    return "";
  } else if (status === "Aguardando qualidade") {
    return "";
  } else if (status === "Revisando qualidade") {
    return "";
  }
};

export function addingDayWork(date, days) {
  let startDate = new Date(date);
  if (days === 0) {
    return new Date(date);
  } else {
    let endDate = "",
      noOfDaysToAdd = days,
      count = 0;

    while (count < noOfDaysToAdd) {
      endDate = new Date(startDate.setDate(startDate.getDate() + 1));
      if (endDate.getDay() !== 0 && endDate.getDay() !== 6) {
        //Date.getDay() gives weekday starting from 0(Sunday) to 6(Saturday)
        count++;
      }
    }
    return endDate;
  }
}

export function translateStatus(status, forcedStep) {
  if (status === "enabled") {
    return "Em progresso";
  } else if (status === "submitted") {
    if (forcedStep != null) {
      let force = false;
      forcedStep.forEach((row) => {
        if (row.isPending) {
          force = true;
        }
      });
      if (force) {
        return "Aguardando resposta";
      } else {
        return "Confirmar dados";
      }
    } else {
      return "Confirmar dados";
    }
  } else if (status === "approved") {
    return "Em produção";
  } else if (status === "waiting_render_approved") {
    return "Aguardando Projeto Final";
  } else if (status === "waiting_render_reproved") {
    return "Aguardando Projeto Final";
  } else if (status === "waiting_confirm_review") {
    return "Confirmar dados revisão";
  } else if (status === "waiting_user_feedback") {
    return "Aguardando resposta cliente";
  } else if (status === "customer-reproved") {
    return "Em Revisão";
  } else if (status === "preview") {
    return "Preview";
  } else if (status === "first_review") {
    return "1º Review";
  } else if (status === "waiting_quality") {
    return "Aguardando qualidade";
  } else if (status === "reviewing_quality") {
    return "Revisando qualidade";
  } else if (status === "second_review") {
    return "2º Review";
  } else if (status === "final_project") {
    return "Projeto Entregue";
  } else {
    return status;
  }
}

export function limitDateFunction(logDates, status, forcedStep) {
  try {
    if (status === "enabled") {
      return "";
    } else if (status === "submitted") {
      if (forcedStep != null) {
        let force = false;
        forcedStep.forEach((row) => {
          if (row.isPending) {
            force = true;
          }
        });
        if (force) {
          return "";
        } else {
          return addingDayWork(logDates.submitted.date, 1);
        }
      } else {
        return addingDayWork(logDates.submitted.date, 1);
      }
    } else if (status === "approved") {
      return addingDayWork(logDates.approved.date, 5);
    } else if (status === "waiting_quality") {
      return "";
    } else if (status === "reviewing_quality") {
      return "";
    } else if (status === "preview") {
      return "";
    } else if (status === "first_review") {
      return "";
    } else if (status === "second_review") {
      return "";
    } else if (status === "waiting_confirm_review") {
      return addingDayWork(logDates.waiting_confirm_review.date, 1);
    } else if (status === "waiting_user_feedback") {
      return "";
    } else if (status === "customer-reproved") {
      return addingDayWork(logDates.customer_reproved.date, 3);
    } else if (status === "waiting_render_approved") {
      return addingDayWork(logDates.waiting_render_approved.date, 3);
    } else if (status === "final_project") {
      return "";
    } else {
      return "";
    }
  } catch (error) {
    return "";
  }
}
