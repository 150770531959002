import React, { useContext } from "react";
import { Route } from "react-router-dom";

import { AuthContext } from "./AuthContext";
import ExternalRedirect from "./ExternalRedirect";

const PrivateRoute = ({ route, index, component, redirectPath }) => {
  const authContext = useContext(AuthContext);

  return authContext.authenticated ? (
    <Route
      key={index}
      path={route.path}
      exact={route.exact}
      component={component}
    />
  ) : (
    <ExternalRedirect path={redirectPath} />
  );
};

export default PrivateRoute;
