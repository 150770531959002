import React from "react";
import { Collapse } from "shards-react";

import NotificationBackdrop from "./NotificationBackdrop";

function NotificationContainer({ children, onDismiss, show }) {
  return (
    <>
      {show && <NotificationBackdrop onClick={onDismiss} />}
      <Collapse
        open={show}
        onClick={() => {}}
        style={{
          zIndex: 1200,
        }}
        className="dropdown-menu dropdown-menu-small unclickable"
      >
        <div>
          <div
            style={{
              fontSize: "13px",
              color: "black",
              padding: "8px",
              backgroundColor: "#dfe6e9",

              // borderBottom: "1px solid black",
            }}
          >
            Notificações
          </div>
        </div>
        <div style={{ overflowY: "auto", maxHeight: "300px" }}>{children}</div>
      </Collapse>
    </>
  );
}

NotificationContainer.propTypes = {};

export default NotificationContainer;
