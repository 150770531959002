import React from "react";

function NotificationDescription({ html }) {
  return (
    <p
      dangerouslySetInnerHTML={{ __html: html }}
      style={{
        color: "black",
        margin: "0px",
        padding: "10px",
      }}
    ></p>
  );
}

NotificationDescription.propTypes = {};

export default NotificationDescription;
