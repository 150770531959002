import React from "react";
import PropTypes from "prop-types";
import { Container, Row } from "shards-react";

const MainFooter = ({ contained, menuItems, copyright }) => (
  <footer className="main-footer d-flex p-2 px-3 bg-white border-top mt-2">
    <Container fluid={contained}>
      <Row style={{ marginTop: "1%" }}>
        <span className="copyright ml-auto my-auto mr-2 mt-3">{copyright}</span>
      </Row>
    </Container>
  </footer>
);

MainFooter.propTypes = {
  /**
   * Whether the content is contained, or not.
   */
  contained: PropTypes.bool,
  /**
   * The menu items array.
   */
  menuItems: PropTypes.array,
  /**
   * The copyright info.
   */
  copyright: PropTypes.string,
};

let currentYear = new Date().getFullYear();
MainFooter.defaultProps = {
  contained: false,
  copyright: "Copyright © " + currentYear + " La Decora",
  menuItems: [],
};

export default MainFooter;
