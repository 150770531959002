import React from "react";

import { ModalBody, ModalHeader, Modal } from "shards-react";
import NotificationItem from "./NotificationItem";

function NotificationBanner({ notifications, onBannerClose }) {
  //TODO Remove duplication
  function strToDate(stringDate) {
    const date = new Date(stringDate);
    const formattedCreatedAt = `${getFormattedNumber(
      date.getDate()
    )}/${getFormattedNumber(
      date.getMonth()
    )}/${date.getFullYear()} ${getFormattedNumber(
      date.getHours()
    )}:${getFormattedNumber(date.getMinutes())}`;
    return formattedCreatedAt;
  }

  function getFormattedNumber(number) {
    return number < 10 ? `0${number}` : number;
  }
  return (
    <Modal open={true} size="md" toggle={onBannerClose}>
      <ModalHeader toggle={onBannerClose} style={{ justifyContent: "center" }}>
        Avisos importantes
      </ModalHeader>
      <ModalBody
        className="p-0"
        style={{ overflow: "auto", maxHeight: "400px" }}
      >
        {notifications
          .sort((a, b) => {
            if (a.time < b.time) {
              return 1;
            }
            if (a.time > b.time) {
              return -1;
            }
            return 0;
          })
          .map((notification, index) => (
            <NotificationItem
              title={notification.title}
              dismissable={false}
              className="mb-3"
              description={notification.description}
              key={`${index}-${notification._id}`}
              createdAt={strToDate(notification.time)}
              id={notification._id}
              theme={notification.theme}
            />
          ))}
      </ModalBody>
    </Modal>
  );
}

NotificationBanner.propTypes = {};

export default NotificationBanner;
