import React from "react";
import PropTypes from "prop-types";
import { Row, Col, FormGroup, FormTextarea } from "shards-react";

function ReviewImage(props) {
  const { file, key, onImageClick, disabled, onChange, link } = props;
  return (
    <div key={key}>
      <div className="d-flex justify-content-center align-items-center carousel-container">
        <img
          alt=""
          className="rounded  ml-auto mr-auto carousel-image"
          src={link}
          onClick={onImageClick}
        />
      </div>

      {!disabled ? (
        <Row>
          <Col md="6" className="mt-2">
            <FormGroup>
              <label>O que você gostou?</label>
              <FormTextarea
                value={file.likeComments}
                onChange={(e) => {
                  onChange(file, {
                    dislikeComments: file.dislikeComments,
                    likeComments: e.target.value,
                  });
                }}
                rows="4"
                className="form-control mt-2"
                placeholder="Comente aqui o que você gostou referente a esta imagem."
              />
            </FormGroup>
          </Col>
          <Col md="6" className="mt-2">
            <FormGroup>
              <label>O que você não gostou?</label>
              <FormTextarea
                value={file.dislikeComments}
                onChange={(e) => {
                  onChange(file, {
                    likeComments: file.likeComments,
                    dislikeComments: e.target.value,
                  });
                }}
                rows="4"
                className="form-control mt-2"
                placeholder="Comente aqui suas críticas referentes a esta imagem."
              />
            </FormGroup>
          </Col>
        </Row>
      ) : (
        <>
          <Row className="review-image-comments">
            <Col md="6" xs="12" className="like-comment">
              {file.likeComments ? (
                <>
                  {" "}
                  <h6>O que gostou.</h6>
                  {file.likeComments}
                </>
              ) : (
                ""
              )}
            </Col>
            <Col md="6" xs="12" className="dislike-comment">
              {file.dislikeComments ? (
                <>
                  {" "}
                  <h6>O que não gostou.</h6>
                  {file.dislikeComments}
                </>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

ReviewImage.propTypes = {};

export default ReviewImage;
