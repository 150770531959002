import React from "react";
import PropTypes from "prop-types";

import NotificationItem from "./NotificationItem";

function NotificationList({
  notifications,
  dismissable,
  onDismissNotification,
  onOpenBanner,
}) {
  function strToDate(stringDate) {
    const date = new Date(stringDate);
    const formattedCreatedAt = `${getFormattedNumber(
      date.getDate()
    )}/${getFormattedNumber(
      date.getMonth()
    )}/${date.getFullYear()} ${getFormattedNumber(
      date.getHours()
    )}:${getFormattedNumber(date.getMinutes())}`;
    return formattedCreatedAt;
  }

  function getFormattedNumber(number) {
    return number < 10 ? `0${number}` : number;
  }

  return (
    <>
      {notifications
        .sort((a, b) => {
          if (new Date(a.time) < new Date(b.time)) {
            return 1;
          }
          if (new Date(a.time) > new Date(b.time)) {
            return -1;
          }
          return 0;
        })
        .map((dismissableNotification, index) => (
          <NotificationItem
            onDismissNotification={onDismissNotification}
            onOpenBanner={() => {
              onOpenBanner(dismissableNotification);
            }}
            bannerContent={dismissableNotification.bannerContent}
            key={`${index}-${dismissableNotification._id}`}
            createdAt={strToDate(dismissableNotification.time)}
            id={dismissableNotification._id}
            title={dismissableNotification.title}
            theme={dismissableNotification.theme}
            dismissable={dismissable}
            description={dismissableNotification.description}
          ></NotificationItem>
        ))}
    </>
  );
}

NotificationList.propTypes = {
  notifications: PropTypes.array.isRequired,
};

export default NotificationList;
