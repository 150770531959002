import React from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "shards-react";

function TermsModal({ isTermsModalOpen, toggleTermsOfServiceModal }) {
  return (
    <Modal
      size="lg"
      open={isTermsModalOpen}
      animation={true}
      toggle={toggleTermsOfServiceModal}
    >
      <ModalHeader toggle={toggleTermsOfServiceModal}>
        <span role="img" aria-label="">
          👋
        </span>{" "}
        Considerações para início da sua assessoria
      </ModalHeader>
      <ModalBody style={{ maxHeight: "60vh", overflow: "auto" }}>
        <p>Considerações para início da sua assessoria</p>
        <p>
          Para que à sua assessoria fique do jeito que sonhou precisamos que nos
          ajude com os itens abaixo!
        </p>
        <div>
          Resumo:
          <ul>
            <li>Os produtos sugeridos são exclusivos da Tok&Stok </li>
            <li>
              Meu Ambiente Tok&Stok não realiza projetos de reforma e emissão de
              laudo RRT e ART.{" "}
            </li>
            <li>O canal de comunicação é a própria plataforma.</li>
            <li>
              É necessário o envio das informações solicitadas dentro dos prazos
              para a realização e conclusão da assessoria.
            </li>
          </ul>
        </div>
        <p>Termo de Aceite para início da assessoria</p>
        <p>
          1. Informações: Todas as informações extras, pedidos específicos e/ou
          considerações - de cada ambiente - devem ser reunidas e enviadas no
          sistema antes do início da assessoria. Cada informação é importante
          para desenvolvermos a melhor assessoria para seu ambiente.
          <br />
          1.1 Meu Ambiente Tok&Stok é um serviço para atendimento a clientes com
          sugestões de decorações considerando exclusivamente a variedade de
          móveis e itens de decoração da Tok&Stok. Ao final do atendimento será
          reservado ao cliente um desconto especial para a compra dos produtos
          sugeridos.
          <br />
          1.2 A Tok&Stok e Parceiros não fazem alterações de paredes e/ou
          esquadrias. Caso o cliente queira fazer estas mudanças, a planta a ser
          enviada para início da assessoria decorativa deve já estar atualizada,
          contemplando as alterações esperadas.
          <br />
          1.3 Como a assessoria é uma sugestão de decoração para o ambiente e
          realizado através de envio de planta e informações pelo cliente, a
          Tok&Stok e Parceiros não se responsabilizam por alterações estruturais
          e não realizam emissão de RRT ou ART de projetos. É extremamente
          importante que para estas modificações se tenha o acompanhamento
          presencial de um profissional cadastrado no CREA ou CAU.
          <br />
          1.4 A Tok&Stok e Parceiros não sugerem modificações de hidráulica e
          elétrica com aumento de carga para os ambientes. Caso o cliente queira
          modificar o layout dos equipamentos, deverá avaliar e validar as
          alterações diretamente com um profissional local cadastrado no CREA ou
          CAU.
        </p>
        <p>
          2. Prazos: Somente após o envio de todas as informações, inicia-se a
          contagem do prazo de entrega, aplicados tanto para inícios da
          assessoria quanto para a revisão. Para envio da assessoria contamos 5
          (cinco) dias úteis e para revisão, 3 (três) dias úteis. Para cada
          ambiente integrado adicional contamos mais 1 (um) dia útil.
          <br />
          2.1 O prazo para pedido de revisão se encerra no final de 20 dias
          corridos, a contar do dia de primeira entrega da assessoria.
        </p>
        <p>
          3. Revisões: Alterações de tipologia de ambientes (ex: mudar de quarto
          de visitas para home office) ou mudanças de plantas baixas (ex: envio
          de plantas erradas, falta de informações essenciais como portas,
          janelas) não são consideradas revisões. Para este tipo de modificação
          será cobrado o valor de 50% da assessoria.
          <br />
          3.1 Consideramos que as definições sobre estilo e preferências de
          decoração serão respondidas nos questionários e poderão ser
          complementadas por descrição antes do início da assesoria. Pedidos de
          modificações que divirjam muito dos iniciais após a entrega não serão
          considerados revisão, e será cobrada a taxa de 50% do valor da
          assessoria. Exemplo: revisões testes para escolha – que incluam a
          entrega de várias versões de um mesmo ambiente.
          <br />
          3.2 Após o pedido de revisão o cliente receberá as novas imagens em 3
          dias úteis a contar do envio completo de informações.
          <br />
          3.3 Após o envio completo de informações a Tok&Stok vai enviar um
          e-mail com prazo de entrega de revisão. Caso o cliente solicite mais
          alguma alteração após este e-mail, será dado novo prazo de entrega de
          3 dias úteis, sendo que o cliente tem direito a 2 revisões durante o
          prazo de 20 dias corridos, a contar do dia de primeira entrega da
          assessoria.
          <br />
          3.4 As revisões serão entregues sem renderização, ou seja, somente
          após aprovação será gerada imagem realista.
          <br />
          3.5 Após aprovação final os detalhamentos técnicos e imagens
          renderizadas em 3D serão entregues em 2 dias úteis.
          <br />
          3.6 Após aprovação final e entrega do material técnico, a Tok&Stok
          considera a assessoria finalizada. De forma que se o cliente solicitar
          alguma revisão, o custo será de R$150,00 por ambiente.
        </p>
        <p>
          4. Ambientes Integrados: Os valores são separados por ambientes e
          função, de forma que para ambientes integrados o valor é calculado de
          acordo com a quantidade de ambientes. Exemplo: Cozinha com sala de
          jantar e sala de estar = 3 ambientes, Quarto com closet = 2 ambientes,
          Cozinha com área de serviço = 2 ambientes, Sala de jantar com sala de
          estar = 2 ambientes.
        </p>
        <p>
          5. Atendimento: o atendimento é online e feito pela plataforma.
          <br />
          5.1 Envios de mensagens e imagens através do sistema serão recebidas e
          respondidas de acordo com o cronograma de cada cliente.
          <br />
          5.2 As respostas se darão apenas dentro de horário comercial – 9h-12h
          13:30-18h.
        </p>
        <p>
          Obrigada e ficamos no aguardo de suas informações para início da
          assessoria!
          <br />
          <b>Equipe Tok&Stok</b>
        </p>
      </ModalBody>
      <ModalFooter>
        <Button
          theme="accent"
          onClick={toggleTermsOfServiceModal}
          data-gtm-event-category="tokstok:decora:home"
          data-gtm-event-action="clique:botao:ligthbox-termos"
          data-gtm-event-label="li-e-aceito-os-termos"
        >
          Fechar
        </Button>
      </ModalFooter>
    </Modal>
  );
}

TermsModal.propTypes = {};

export default TermsModal;
