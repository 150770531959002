import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import Faq from "react-faq-component-yfx";
import { fireEvent } from "../utils/tag-manager";

const FaqPlataforma = () => {
  const data = {
    rows: [
      {
        name: "para_quem",
        title: "Para quem o serviço Meu Ambiente Tok&Stok é indicado?",
        content: `O Meu Ambiente Tok&Stok é indicado para todos que desejam ter uma assessoria de decoração profissional prática e acessível. Você contrata o serviço por ambiente, com a liberdade de realizar as mudanças no seu tempo, auxiliado por nossa simulação 3D para o seu espaço e um material técnico explicativo, incluindo sugestões e lista de produtos para seguir passo a passo.`,
      },
      {
        name: "como_funciona",
        title: "Como funciona o Meu Ambiente Tok&Stok?",
        content: `Concluída a compra da assessoria, você será convidado a acessar a plataforma do Meu Ambiente Tok&Stok. Nela, fique à vontade para contar um pouco mais a respeito do seu estilo, gosto e características do seu ambiente usando os questionários desenvolvidos pela equipe Tok&Stok. </br>Confirmado o recebimento de todas as informações necessárias, a equipe Tok&Stok inicia o desenvolvimento da assessoria personalizada. O progresso de cada uma das etapas da entrega também estará disponível na plataforma do parceiro.
`,
      },
      {
        name: "incluso_projeto_final",
        title: "O que está incluso na entrega final?",
        content: `
        A entrega prevê duas etapas: uma preliminar e outra final. A partir das informações enviadas, os profissionais em decoração da Tok&Stok irão desenvolver uma primeira proposta de transformação para seu ambiente. Com essa etapa pronta, imagens 3D serão disponibilizadas na plataforma do parceiro para sua aprovação. <br/>
        Se estiver tudo certo com a proposta, nossa equipe prosseguirá na produção do caderno final da assessoria, que contém:
        <br />
        - Posicionamento  da decoração; <br />
        - Posicionamento  de luminárias; <br />
        - Sugestão de  acabamentos de paredes; <br />
        - Assessoria de decoração em 3D; <br />
        - Tabelas técnicas de móveis, decoração, luminárias, papel de parede, cortinas, persianas, tapetes e toda a decoração; <br />
        - Considerações para executar a decoração; <br />
        - Dicas úteis e personalizadas de decoração; <br />
        `,
      },
      {
        name: "qual_custo",
        title: "Qual é o custo do serviço Meu Ambiente Tok&Stok?",
        content: `
        O serviço possui um valor padrão de R$249,00 por ambiente.
        `,
      },
      {
        name: "tamanho",
        title: "O tamanho do ambiente influencia no preço do serviço?",
        content: `
        O preço do Meu Ambiente Tok&Stok não é influenciado pelo tamanho do ambiente, exceto para o ambiente Escritório, com limite de 16m² por assessoria.
        `,
      },
      {
        name: "tipos_ambientes",
        title: "Quais os tipos de ambientes o serviço abrange?",
        content: `
        O Meu Ambiente Tok&Stok oferece uma assessoria de decoração para ambientes internos e externos. Nossos profissionais estão à disposição para entregar assessorias para salas de estar, salas de jantar, quartos, cozinhas, banheiros, varandas, entre muitos outros. Você tem a oportunidade de explicar tudo o que precisa no início da assessoria para que nós possamos entregar algo do jeito que você deseja.
        `,
      },
      {
        name: "envio_planta",
        title:
          "O questionário solicita o envio de uma planta com medidas do ambiente. Qual o nível de detalhes necessários?",
        content: `
        Para que seja possível desenvolver uma assessoria adequada ao seu ambiente, precisamos de algumas informações básicas, como medidas gerais de largura, comprimento e altura do espaço a ser transformado e de suas aberturas (portas, janelas e corredores).
        `,
      },
      {
        name: "qual_prazo",
        title: "Qual é o prazo de entrega da assessoria completa?",
        content: `
        Concluído o envio e confirmadas as informações enviadas, nossos profissionais têm um prazo de 5 (cinco) dias úteis para a primeira entrega das imagens 3D. Caso não haja nenhuma revisão e o você aprovar a entrega, há um prazo de 2 (dois) dias úteis para envio do material técnico da assessoria.
        `,
      },
      {
        name: "revisao",
        title: "É possível solicitar uma revisão da assessoria?",
        content: `
        Sim, você pode solicitar até 2 (duas) revisões em até 20 (vinte) dias corridos após envio da primeira versão. Caso você não solicite nenhuma revisão dentro do prazo estipulado, a assessoria final será considerado aprovado pelo sistema e o material técnico será enviado em 2 (dois) dias úteis.
        `,
      },
      {
        name: "aprivei_3d",
        title:
          "Eu aprovei a simulação 3D, mas gostaria de solicitar uma revisão. É possível voltar atrás?",
        content: `
        Não. Depois que você aprova a simulação 3D, os profissionais da Tok&Stok iniciam a elaboração do material técnico, o que impede qualquer nova alteração. Caso esta seja fundamental, ela poderá ser feita a partir de solicitação via nossos canais de atendimento, com valor de R$150,00.
        `,
      },
      {
        name: "mensagem_erro",
        title:
          "Estou recebendo uma mensagem de erro ao tentar abrir as imagens 3D e/ou o material técnico. O que eu faço?",
        content: `
        Poderá fechar o sistema e abrir novamente para atualização. Caso o problema persista favor entrar em contato via SAC e informar o erro.
        `,
      },
      {
        name: "foto_errada",
        title:
          "Eu enviei as fotos erradas nos questionários iniciais da assessoria. É possível enviar novas?",
        content: `
        Sim, você pode revisar seu questionário antes do início da assessoria. Se tiver dificuldade, solicite nossa ajuda pelos nossos canais de atendimento.
        `,
      },
      {
        name: "escolher_decorador",
        title:
          "Já utilizei o Meu Ambiente Tok&Stok e gostaria de ter o mesmo profissional em decoração para o meu próximo ambiente. É possível escolher o profissional que vai atender meu pedido?",
        content: `
        Não é possível escolher o profissional, porém nosso time é treinado com uma metodologia padronizada que garante a qualidade da assessoria.
        `,
      },
      {
        name: "custo_integrado",
        title:
          "Como é calculado o custo da assessoria para ambientes integrados?",
        content: `
        No caso de ambientes integrados, os valores serão cobrados separadamente de acordo com o ambiente e a função do mesmo. Por exemplo:<br />
        - Cozinha com sala de jantar e sala de estar = 3 ambientes;<br />
        - Sala de jantar com sala de estar = 2 ambientes;<br />
        - Quarto com closet = 2 ambientes;<br />
        - Cozinha com área de serviço = 2 ambientes;<br />

        `,
      },
      {
        name: "aproveitar_moveis",
        title:
          "Eu gostaria de aproveitar alguns móveis que já tenho. Como posso incluí-los na assessoria?",
        content: `
        Para aproveitar seus móveis atuais, você só precisa nos enviar as fotos e medidas de todos os objetos que deseja manter e vamos considerá-los na elaboração da nova acessora.
        `,
      },
      {
        name: "outras_marcas",
        title:
          "Posso solicitar que a assessoria inclua produtos de outras marcas?",
        content: `
        Com a exceção do mobiliário pré-existente que você queira manter na assessoria, os nossos parceiros irão sugerir produtos exclusivamente da Tok&Stok. Caso você tenha alguma peça específica em mente, faremos o melhor para encontrar o produto Tok&Stok mais adequado para substituição.
        `,
      },
      {
        name: "reformar",
        title: "Eu quero reformar, este serviço me atende?",
        content: `
        O Meu Ambiente Tok&Stok é um serviço online exclusivamente de decoração. Ele não abrange modificações estruturais, hidráulicas ou elétricas. Portanto, caso você busque uma reforma mais profunda, deverá contratar um arquiteto ou engenheiro local para a execução da assessoria e nos enviar a planta aprovada para desenvolvermos a decoração.
        `,
      },
      {
        name: "nota_fiscal",
        title: "A Tok&Stok emite uma nota fiscal para este serviço?",
        content: `
        Sim. Após a compra do serviço, você recebe uma nota fiscal por e-mail.
        `,
      },
      {
        name: "cancelar",
        title: "Posso cancelar o serviço?",
        content: `
        Seu pedido pode ser cancelado em até 7 dias corridos após a compra, desde que você ainda não tenha recebido a primeira versão da assessoria.
        `,
      },
    ],
  };

  const styles = {
    rowContentColor: "#686868",
    rowTitleColor: "#3C3C3E",
    rowContentPaddingLeft: "10px",
    rowContentPaddingRight: "10px",
    rowContentPaddingTop: "10px",
    rowTitleTextSize: "16px",
    rowContentTextSize: "15px",
  };

  function onExpand(isOpen, data) {
    fireEvent(
      "event",
      "tokstok:decora:faq",
      "interacao:duvidas",
      `${data.name}:${isOpen ? "abriu" : "fechou"}`
    );
  }

  return (
    <Container fluid className="main-content-container px-4">
      <Row className="page-header py-4">
        <Col>
          <PageTitle title="FAQ" className="text-sm-left mb-3" />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom"></CardHeader>
            <CardBody className="p-0 pb-3">
              <Col></Col>
              <Col>
                <Faq data={data} styles={styles} onExpand={onExpand} />
              </Col>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default FaqPlataforma;
