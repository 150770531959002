import { API, APP_ID } from "../config";
import { getCookie, authMiddleware } from "./auth";

export const getAllQuestions = (hash) => {
  const token = getCookie("token");

  return fetch(`${API}/questions?company=${APP_ID}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
  })
    .then(authMiddleware)
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getDefaultQuestion = () => {
  const token = getCookie("token");

  return fetch(`${API}/question/default`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};
