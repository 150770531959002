import React from "react";
import { Nav } from "shards-react";

import SidebarNavItem from "./SidebarNavItem";
import { Store } from "../../../flux";
import { signout } from "../../../actions/auth";

import { AuthContext } from "../../Auth/AuthContext";

class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navItems: Store.getSidebarItems(),
    };

    this.onChange = this.onChange.bind(this);
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      ...this.state,
      navItems: Store.getSidebarItems(),
    });
  }

  render() {
    const user = this.context.user ? this.context.user.profile.firstName : "";

    const { navItems: items } = this.state;
    return (
      <div className="nav-wrapper">
        <h6 className="ml-4 mt-4" style={{ color: "#0a775a" }} theme="accent">
          Olá, {user}{" "}
        </h6>
        {items.map((nav, idx) =>
          nav.title ? (
            <div key={idx}>
              <h6 className="main-sidebar__nav-title">{nav.title}</h6>

              {typeof nav.items !== "undefined" && nav.items.length && (
                <Nav className="nav--no-borders flex-column">
                  {nav.items.map((item, idx) => (
                    <SidebarNavItem
                      key={idx}
                      item={item}
                      category={nav.category}
                    />
                  ))}
                </Nav>
              )}
            </div>
          ) : (
            <div
              onClick={() => {
                signout();
                window.open(`/login`, "_self");
              }}
              style={{ bottom: "0", position: "absolute", width: "100%" }}
              key={idx}
            >
              {typeof nav.items !== "undefined" && nav.items.length && (
                <Nav className="nav--no-borders flex-column">
                  {nav.items.map((item, idx) => (
                    <SidebarNavItem
                      key={idx}
                      item={item}
                      category={nav.category}
                    />
                  ))}
                </Nav>
              )}
            </div>
          )
        )}
      </div>
    );
  }
}

SidebarNavItems.contextType = AuthContext;
export default SidebarNavItems;
