// PROD
module.exports = {
  APP_NAME: "Tok&Stok",
  APP_ID: "tok_stok",
  TAG_MANAGER: "GTM-PW9V5VD",
  API: "https://api-prod.ladecora.com.br",
  BUCKET_URL: "https://s3.amazonaws.com/ladecora.platform",
  PRODUCTION: true,
  LANDING_PAGE_TOKSTOK: "https://www.tokstok.com.br/meuambiente",
  PAGARME_TOKEN: "ek_live_uqPydxBlIz8lVvG3tIGhgJ43vXW1bh",
};

// DEV
// module.exports = {
//   APP_NAME: "Tok&Stok",
//   TAG_MANAGER: "GTM-NH4QS5M",
//   APP_ID: "tok_stok",
//   API: "https://api-dev.ladecora.com.br",
//   BUCKET_URL: "https://s3.amazonaws.com/ladecora.platform",
//   PRODUCTION: false,
//   LANDING_PAGE_TOKSTOK: "https://www.tokstok.com.br/decora",
//   PAGARME_TOKEN: "ek_test_H3QV3IyqF1Lr8IYJ5LQA2uY5AX3psK",
// };
