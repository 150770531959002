import React, { useState, useEffect, useContext } from "react";
import {
  Row,
  Card,
  CardBody,
  Col,
  Button,
  CardHeader,
  Container,
} from "shards-react";

import FuzzySearch from "fuzzy-search";
import { debounce } from "../../../utils/throttle";

import PageTitle from "../../common/PageTitle";
import Lightbox from "react-image-lightbox";
import { BUCKET_URL } from "../../../config";
import { ToastContainer } from "react-toastify";
import ImagesDownloadProject from "../sub-component/ImagesDownloadProject";
import Slider from "react-slick";
import Rating from "../Rating";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../Auth/AuthContext";
import FilteredTable from "../../FilteredTable/index";
import RowSelect from "../../FilteredTable/RowSelect";

const DownloadProject = (props) => {
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const { updateStepCompleted } = props;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  function download(fileURL, fileName) {
    // for non-IE
    if (!window.ActiveXObject) {
      var save = document.createElement("a");
      save.href = fileURL;
      save.target = "_self";
      var filename = fileURL.substring(fileURL.lastIndexOf("/") + 1);
      save.download = fileName || filename;
      if (
        navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
        navigator.userAgent.search("Chrome") < 0
      ) {
        document.location = save.href;
        // window event not working here
      } else {
        var evt = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: false,
        });
        save.dispatchEvent(evt);
        (window.URL || window.webkitURL).revokeObjectURL(save.href);
      }
    }

    // for IE < 11
    else if (!!window.ActiveXObject && document.execCommand) {
      var _window = window.open(fileURL, "_blank");
      _window.document.close();
      _window.document.execCommand("SaveAs", true, fileName || fileURL);
      _window.close();
    }
  }

  const sendLightbox = (img) => {
    setLightbox({ img: img, open: true });
  };

  const [project, setProject] = useState();
  const [projectId, setProjectId] = useState("");

  const [lightbox, setLightbox] = useState({ open: false, img: "" });

  const [files, setFiles] = useState([]);
  const [workPlanLayout, setWorkPlanLayout] = useState([]);
  const [finishingLayout, setFinishingLayout] = useState([]);
  const [lights, setLights] = useState([]);
  const [decorationPanel, setDecorationPanel] = useState([]);
  const [coverageFloorPlans, setCoverageFloorPlans] = useState([]);
  const [details, setDetails] = useState([]);
  const productLocation = {
    68: "3",
    82: "3",
    96: "3",
    92: "3",
    97: "3",
    71: "1",
    73: "1",
    74: "1",
    75: "1",
    77: "1",
    85: "3",
    88: "3",
    61: "2",
    27: "1",
    28: "1",
    62: "2",
    64: "2",
    98: "3",
    99: "3",
    65: "2",
    66: "2",
    67: "2",
    31: "1",
    32: "1",
    33: "1",
    34: "1",
    35: "1",
    37: "1",
    38: "1",
    41: "2",
    42: "2",
    43: "2",
    44: "2",
    45: "2",
    46: "2",
    83: "3",
    91: "3",
    93: "3",
    94: "3",
    81: "3",
    87: "3",
    86: "3",
    89: "3",
    21: "1",
    22: "1",
    24: "1",
    84: "3",
    51: "2",
    53: "2",
    54: "2",
    55: "2",
    69: "3",
    95: "3",
    47: "2",
    48: "2",
    49: "2",
    79: "3",
    11: "1",
    12: "1",
    13: "1",
    14: "1",
    15: "1",
    16: "1",
    17: "1",
    18: "1",
    19: "1",
    63: "3",
  };

  useEffect(() => {
    setFiles(props.project.finalProject.lastVersion.files);
    setProjectId(props.project._id);

    setProject(props.project);
    if (typeof props.project.render.workPlanLayout !== "undefined") {
      setWorkPlanLayout(props.project.render.workPlanLayout);
    }
    if (typeof props.project.render.finishingLayout !== "undefined") {
      setFinishingLayout(props.project.render.finishingLayout);
    }
    if (typeof props.project.render.lights !== "undefined") {
      setLights(props.project.render.lights);
    }
    if (typeof props.project.render.decorationPanel !== "undefined") {
      setDecorationPanel(props.project.render.decorationPanel);
    }
    if (typeof props.project.render.coverageFloorPlans !== "undefined") {
      setCoverageFloorPlans(props.project.render.coverageFloorPlans);
    }
    if (typeof props.project.render.details !== "undefined") {
      setDetails(props.project.render.details);
    }
  }, []);

  const sendToCart = () => {
    let ddd = "";
    if (props.project.user.profile.phone) {
      let phone = props.project.user.profile.phone;
      ddd = phone.replace("+55", "").substring(0, 2);
    }
    let userLocation = productLocation[ddd];

    if (!userLocation) {
      userLocation = "3";
    }
    let urlCart = `https://www.tokstok.com.br/checkout/cart/add?sc=${userLocation}&utm_campaign=496M548D&`;
    let sellerRegion = "1";
    state.table.data
      .filter((furniture) => furniture.checked)
      .forEach((product, idx) => {
        if (product.supplier === "Tok&Stok" && product.sku) {
          urlCart += `sku=${product.sku.trim()}&`;
          urlCart += `qty=${product.count}&`;
          urlCart += `seller=${sellerRegion}&`;
        }
      });

    window.location.href = urlCart;
  };

  const tableColumns = [
    {
      Header: "",
      accessor: "selected",
      className: "text-center",
      Cell: (row) => {
        return <RowSelect onChange={onSave} item={row.original} />;
      },
      maxWidth: 30,
    },
    {
      maxWidth: 50,
      Header: "QTD",
      accessor: "count",
      className: "text-center",
    },
    {
      Header: "Imagens",
      accessor: "refimage",
      className: "text-center",
      Cell: (row) => {
        return (
          <div>
            <img alt="" height={100} src={row.value} />
          </div>
        );
      },
      maxWidth: 100,
    },
    {
      Header: "Nome",
      accessor: "name",
      className: "text-center",
      minWidth: 300,
    },
  ];

  const [state, setState] = useState({
    table: {
      pageSizeOptions: [5, 10, 15, 20, 25, 30],
      pageSize: 5,
      data: props.project.furnitures.map((furniture) => {
        furniture.checked =
          furniture.checked === undefined ? true : furniture.checked;
        return furniture;
      }),
      searcher: new FuzzySearch(
        props.project.furnitures,
        ["props.project.furnitures.sku", "props.project.furnitures.name"],
        {
          caseSensitive: false,
          sort: true,
        }
      ),
      searchText: debounce((value) => {
        state.table.data = state.table.searcher.search(value);
        setState({ ...state });
      }, 800),
    },
  });

  function onSave(item, value) {
    item.checked = value;
    setState({
      ...state,
    });
  }

  return (
    <React.Fragment>
      <ToastContainer autoClose={false} />
      {lightbox.open && (
        <Lightbox
          mainSrc={lightbox.img}
          reactModalStyle={{ overlay: { zIndex: 10000 } }}
          onCloseRequest={() => setLightbox({ ...lightbox, open: false })}
        />
      )}
      <Row noGutters className="page-header py-4">
        <PageTitle
          title={`Ambiente:  ${props.project.name}`}
          subtitle="Visão Geral"
          md="12"
          className="ml-sm-auto mr-sm-auto"
        />
      </Row>
      <Row className="mobile-review">
        <Col>
          <Card>
            <CardBody>
              <label
                theme="accent"
                className="mb-1"
                style={{ border: "none", cursor: "pointer" }}
                onClick={() => {
                  props.goBack();
                }}
              >
                &#8592; VOLTAR
              </label>
              <h5>Sua assessoria está pronta!</h5>
              <span>
                Este é o resultado final da assessoria de decoração,
                desenvolvido a partir de suas escolhas e com muito carinho pela
                equipe de profissionais Meu Ambiente Tok&Stok.
                <br />
                Você pode visualizar os itens sugeridos na lista de compras e
                compor seu ambiente com um desconto exclusivo de 10% em produtos
                Tok&Stok. Pensamos uma solução que utilize apenas produtos em
                linha. Entretanto, trabalhamos com estoque flutuante e, no
                momento da compra, alguns dos itens sugeridos podem estar
                indisponíveis. Neste caso, entre em contato com um de nossos
                vendedores especializados! Eles poderão informar a previsão para
                disponibilidade em estoque ou ajudá-lo na escolha de produtos
                semelhantes.
                <br />
                Agradecemos a confiança e desejamos bom proveito de seu novo
                ambiente decorado!
              </span>
              <div className="mt-3">
                <div>
                  <Col md="12" className="mb-2" style={{ textAlign: "center" }}>
                    <div style={{ marginTop: "50px" }}>
                      <Slider {...settings}>
                        {files.map((file, idx) => {
                          return (
                            <div
                              className="d-flex justify-content-center align-items-center carousel-container"
                              key={idx}
                            >
                              <img
                                alt=""
                                className="rounded img-fluid carousel-image"
                                src={
                                  BUCKET_URL +
                                  "/projects/" +
                                  projectId +
                                  "/lastVersion/" +
                                  file.file
                                }
                                onClick={() =>
                                  sendLightbox(
                                    BUCKET_URL +
                                      "/projects/" +
                                      projectId +
                                      "/lastVersion/" +
                                      file.file
                                  )
                                }
                              />
                            </div>
                          );
                        })}
                      </Slider>
                    </div>
                    {decorationPanel.length > 0 && (
                      <ImagesDownloadProject
                        title="Painel de decoração"
                        data={decorationPanel}
                        settings={settings}
                        sendLightbox={sendLightbox}
                      />
                    )}
                    {workPlanLayout.length > 0 && (
                      <ImagesDownloadProject
                        title="Planta de layout"
                        data={workPlanLayout}
                        settings={settings}
                        sendLightbox={sendLightbox}
                      />
                    )}
                    {finishingLayout.length > 0 && (
                      <ImagesDownloadProject
                        title="Planta de acabamentos"
                        data={finishingLayout}
                        settings={settings}
                        sendLightbox={sendLightbox}
                      />
                    )}
                    {lights.length > 0 && (
                      <ImagesDownloadProject
                        title="Distribuição de luminárias"
                        data={lights}
                        settings={settings}
                        sendLightbox={sendLightbox}
                      />
                    )}
                    {coverageFloorPlans.length > 0 && (
                      <ImagesDownloadProject
                        title="Planta baixa de cobertura"
                        data={coverageFloorPlans}
                        settings={settings}
                        sendLightbox={sendLightbox}
                      />
                    )}
                    {details.length > 0 && (
                      <ImagesDownloadProject
                        title="Detalhamentos"
                        data={details}
                        settings={settings}
                        sendLightbox={sendLightbox}
                      />
                    )}
                  </Col>
                  <br />
                  <br />
                  <br />
                  <div className="d-flex justify-content-center align-items-center flex-column">
                    {!props.project.rating.value ? (
                      <Rating
                        stars={props.project.rating.value}
                        text={props.project.rating.text}
                        size={30}
                        onFinish={(review) => {
                          props.project.rating = {
                            value: review.stars,
                            text: review.text,
                          };
                          updateStepCompleted(props.project).then(() => {
                            history.replace(
                              `/environments-list?show=${props.project._id}`
                            );
                          });
                        }}
                        onSave={(review) => {
                          props.project.rating = {
                            value: review.stars,
                            text: review.text,
                          };
                          updateStepCompleted(props.project);
                        }}
                        title={`Olá ${user.profile.firstName}, em uma escala de satisfação,
                      como você avalia nosso atendimento automatizado e a
                      assessoria recebida?`}
                      />
                    ) : null}

                    <br />
                  </div>
                  <div style={{ textAlign: "center" }} className="mt-2 col-12">
                    <Button
                      theme="accent"
                      size="lg"
                      className="mt-1 mr-1"
                      onClick={() => {
                        download(
                          BUCKET_URL +
                            "/projects/" +
                            projectId +
                            "/lastVersion/" +
                            project.finalProject.lastVersion.pdf,
                          project.finalProject.lastVersion.pdf
                        );
                      }}
                      style={{ float: "center" }}
                    >
                      Baixe o seu material técnico
                    </Button>
                    <Button
                      theme="accent"
                      size="lg"
                      className="mt-1 mr-1"
                      onClick={sendToCart}
                      style={{ float: "center" }}
                    >
                      Adicione os produtos no seu carrinho
                    </Button>
                  </div>
                  <br />
                  <br />
                  <div md="12" className="mb-2" style={{ textAlign: "center" }}>
                    <Card>
                      <CardHeader className="p-0"></CardHeader>
                      <CardBody className="p-0">
                        <Row>
                          <Col>
                            <h6 className="text-center m-0 mt-2">
                              Produtos da assessoria
                            </h6>
                          </Col>
                        </Row>
                        <Container
                          fluid
                          className="file-manager__filters border-bottom"
                        >
                          <Row
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          ></Row>
                        </Container>
                        <Container>
                          <Row>
                            <Col
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            ></Col>
                          </Row>
                          <FilteredTable
                            list={state.table.data}
                            columns={tableColumns}
                            config={{
                              pageSizeOptions: [5, 10, 15, 20, 30],
                              searchableColumns: ["name", "sku", "supplier"],
                              pageSize: 10,
                            }}
                          ></FilteredTable>

                          <span style={{ fontSize: "10px", textAlign: "left" }}>
                            * Os itens sugeridos foram verificados em estoque
                            antes do início da assessoria, porém entre o prazo
                            de execução e entrega pode ser que algum produto
                            acabe. Sugerimos que no momento da compra seja
                            solicitada a ajuda de um vendedor, que estará apto a
                            indicar uma opção similar para substituição que
                            melhor componha a decoração.
                          </span>
                        </Container>
                      </CardBody>
                    </Card>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default DownloadProject;

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, filter: "invert(1)" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, filter: "invert(1)" }}
      onClick={onClick}
    />
  );
}
