import React, { useState, useEffect } from "react";
import StepWizard from "react-step-wizard";
import { Row, Card, CardBody, Col, Button } from "shards-react";
import PageTitle from "../../common/PageTitle";
import { getProject } from "../../../actions/project";
import MultipleImage from "../types/MultipleImage";
import MultipleText from "../types/MultipleText";
import Text from "../types/Text";
import Upload from "../types/Upload";
import InternalText from "../types/InternalText";
import { walk, getFlatDataFromTree } from "react-sortable-tree";
import { useHistory } from "react-router-dom";
import "react-quill/dist/quill.snow.css";

const Wizard = (props) => {
  const [allForm, setAllForm] = useState([]);
  const projectId = props.project._id;
  const answeredQuestions = props.project.answers;

  let flatData = null;
  useEffect(() => {
    getProject(projectId).then((project) => {
      let treeData = project[0].questionTemplate.template;
      flatData = getFlatData(treeData);
      walkAtTree(treeData);
    });
  }, []);

  const getFlatData = (treeData) => {
    return getFlatDataFromTree({
      treeData,
      getNodeKey,
      ignoreCollapsed: true,
    });
  };

  let answers = [];

  const updateForm = (data) => {
    let form = answers.find((element) =>
      element.question === data.question ? true : false
    );
    if (!form) {
      answers.push(data);
    } else {
      answers.forEach((element) => {
        if (element.question === data.question) {
          element.answer = data.answer;
        }
      });
    }
  };

  const getNodeKey = ({ treeIndex }) => treeIndex;

  const walkAtTree = (treeData) => {
    walk({
      treeData,
      getNodeKey,
      callback: (row) => {
        if (row.node.children && row.node.children.length) {
          let rootNode = flatData.find(
            (element) => element.treeIndex === row.path[0]
          );
          if (!rootNode.childCount) {
            rootNode.childCount = row.node.children.length;
          } else {
            rootNode.childCount += row.node.children.length;
          }
        }

        if (row.node.type === "text") {
          allForm.push(
            <Text
              key={allForm.length}
              index={row.treeIndex + 1}
              stats={Stats}
              node={row}
              flatData={flatData}
              update={updateForm}
              projectId={projectId}
              answers={answers}
              propsParent={props}
              answeredQuestions={answeredQuestions}
            />
          );
        } else if (row.node.type === "multiple") {
          if (!row.node.input_list[0].image_path) {
            allForm.push(
              <MultipleText
                key={allForm.length}
                node={row}
                index={row.treeIndex + 1}
                stats={Stats}
                flatData={flatData}
                update={updateForm}
                projectId={projectId}
                answers={answers}
                propsParent={props}
                answeredQuestions={answeredQuestions}
              />
            );
          } else {
            allForm.push(
              <MultipleImage
                key={allForm.length}
                node={row}
                index={row.treeIndex + 1}
                stats={Stats}
                flatData={flatData}
                update={updateForm}
                projectId={projectId}
                answers={answers}
                propsParent={props}
                answeredQuestions={answeredQuestions}
              />
            );
          }
        } else if (row.node.type === "upload") {
          allForm.push(
            <Upload
              index={row.treeIndex + 1}
              stats={Stats}
              node={row}
              flatData={flatData}
              update={updateForm}
              projectId={projectId}
              answers={answers}
              propsParent={props}
            />
          );
        } else if (row.node.type === "internalText") {
          allForm.push(
            <InternalText
              key={allForm.length}
              index={row.treeIndex + 1}
              stats={Stats}
              node={row}
              flatData={flatData}
              update={updateForm}
              projectId={projectId}
              answers={answers}
              propsParent={props}
            />
          );
        }

        if (row.treeIndex === flatData[flatData.length - 1].treeIndex) {
          setAllForm([...allForm]);
        }
      },
    });
  };

  return (
    <React.Fragment>
      <Row noGutters className="page-header py-4">
        <PageTitle
          title={`Ambiente:  ${props.project.name}`}
          subtitle="Visão Geral"
          md="12"
          className="ml-sm-auto mr-sm-auto"
        />
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <label
                theme="accent"
                className="mb-1"
                style={{ border: "none", cursor: "pointer" }}
                onClick={() => {
                  props.goBack();
                }}
              >
                &#8592; VOLTAR
              </label>
              {!!allForm.length && (
                <StepWizard isHashEnabled transitions>
                  {allForm}
                </StepWizard>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Wizard;

const Stats = ({
  goToStep,
  totalSteps,
  step,
  node,
  flatData,
  answers,
  projectId,
  propsParent,
}) => {
  const STEP = 3;
  const history = useHistory();
  const nextQuestion = () => {
    window.scrollTo(0, 0);
    if (node.node.input) {
      if (node.node.input.action) {
        goToStep(parseInt(node.node.input.action) + 1);
      } else {
        let parentNode = flatData.find(
          (element) => element.treeIndex === node.path[0]
        );
        let nextStep = !parentNode.childCount
          ? node.path[0] + 2
          : parentNode.childCount + node.path[0] + 2;
        goToStep(nextStep);
      }
    } else {
      let parentNode = flatData.find(
        (element) => element.treeIndex === node.path[0]
      );
      //MUDADO APENAS O 2 para 1
      // let nextStep = !parentNode.childCount
      //   ? node.path[0] + 2
      //   : parentNode.childCount + node.path[0] + 2;
      let nextStep = !parentNode.childCount
        ? node.path[0] + 2
        : parentNode.childCount + node.path[0] + 2;
      goToStep(nextStep);
    }
  };

  const previousStepHistory = () => {
    window.scrollTo(0, 0);
    history.goBack();
  };

  const saveAnswers = () => {
    window.scrollTo(0, 0);
    propsParent.project.answers = answers;
    propsParent.project.completedSteps.push(STEP);
    propsParent.updateStepCompleted({
      completedSteps: propsParent.project.completedSteps,
      answers: answers,
      _id: propsParent.project._id,
    });
    propsParent.callbackReturnView();
  };

  return (
    <React.Fragment>
      <div style={{ cssFloat: "right" }} className="mt-2">
        {step > 1 && (
          <Button
            outline
            theme="accent"
            className="btn mr-1"
            onClick={previousStepHistory}
          >
            Voltar
          </Button>
        )}
        {step < totalSteps ? (
          <Button theme="accent" className="btn" onClick={nextQuestion}>
            Próximo
          </Button>
        ) : (
          <Button theme="accent" className="btn" onClick={saveAnswers}>
            Finalizar
          </Button>
        )}
      </div>
    </React.Fragment>
  );
};
