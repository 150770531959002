import { API } from "../config";
import { getCookie, authMiddleware } from "../actions/auth";

export const confirmTrasaction = (transactionToken, amount, projects) => {
  const token = getCookie("token");

  return fetch(`${API}/transaction/capture`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
    body: JSON.stringify({
      transactionToken: transactionToken,
      amount: amount,
      projects: projects,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const listPayments = (hash) => {
  const token = getCookie("token");

  return fetch(`${API}/transaction/list`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
  })
    .then(authMiddleware)
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const charge = (value, creditCard, projectTemplates, voucher) => {
  const token = getCookie("token");

  return fetch(`${API}/transaction/charge`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
    body: JSON.stringify({
      voucher: voucher,
      value: value,
      creditCard: creditCard,
      projectTemplates: projectTemplates,
    }),
  })
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(
          "Ocorreu um erro desconhecido, por favor, tente novamente ou entre em contato conosco."
        );
      }
      return response;
    })
    .then((response) => {
      return response.json();
    });

  // .catch((err) => console.log(err));
};

export const sendEmailContact = (message, emailSend, phoneSend, nameSend) => {
  const token = getCookie("token");

  return fetch(`${API}/transaction/contact`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
    body: JSON.stringify({
      email: emailSend,
      phone: phoneSend,
      name: nameSend,
      message: message,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
