import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { PRODUCTION } from "./config";
import App from "./App";

import * as serviceWorker from "./serviceWorker";

if (PRODUCTION) {
  Sentry.init({
    dsn:
      "https://ae0bb95336fb4db2a7b581f6627e7170@o529902.ingest.sentry.io/5648884",
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}
ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
