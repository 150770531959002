import React, { useState } from "react";
import Slider from "react-slick";

const ImagesDownloadProject = ({ title, data, settings, sendLightbox }) => {
  const [open, setOpen] = useState(false);
  return (
    <div style={{ marginTop: "50px" }}>
      <div
        style={{
          borderRadius: "16px",
          border: "1px solid rgb(10 119 90)",
          display: "flex",
          justifyContent: "space-between",
        }}
        onClick={() => setOpen(!open)}
      >
        <h4 style={{ margin: "auto" }}>{title}</h4>
        <h3>
          <span className="file-manager__item-icon">
            {open ? (
              <i className="material-icons" style={{ verticalAlign: "middle" }}>
                &#xe5ce;
              </i>
            ) : (
              <i className="material-icons" style={{ verticalAlign: "middle" }}>
                &#xe5cf;
              </i>
            )}
          </span>
        </h3>
      </div>
      {open && (
        <div style={{ marginTop: "12px" }}>
          <Slider {...settings}>
            {data.map((file, idx) => {
              return (
                <div
                  className="d-flex justify-content-center align-items-center carousel-container"
                  key={idx}
                >
                  <img
                    alt=""
                    className="rounded img-fluid carousel-image"
                    src={file.source}
                    onClick={() => sendLightbox(file.source)}
                  />
                </div>
              );
            })}
          </Slider>
        </div>
      )}
    </div>
  );
};

export default ImagesDownloadProject;
