import { API } from "../config";
import { getCookie, authMiddleware } from "./auth";

import { requestWrapper } from "../utils/api";

export const getAllProjects = (hash) => {
  const token = getCookie("token");

  return fetch(`${API}/projects`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
  })
    .then(authMiddleware)
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getProject = (hash) => {
  const token = getCookie("token");

  return fetch(`${API}/project/${hash}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const sendData = (data, projectId, folder) => {
  const token = getCookie("token");

  const formData = new FormData();
  if (data) {
    data.forEach((value) => {
      formData.append("file", value);
    });
  }

  return fetch(`${API}/project/upload/${folder}/${projectId}`, {
    method: "POST",
    body: formData,
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(
          "Ocorreu um erro desconhecido, por favor, tente novamente ou entre em contato conosco."
        );
      }
      return response;
    })
    .then((response) => {
      return response.json();
    });
};

export const updateAnswers = (projectId, answers) => {
  const token = getCookie("token");

  return fetch(`${API}/project-answers/${projectId}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
    body: JSON.stringify(answers),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const updateProject = (projectId, project) => {
  const token = getCookie("token");

  return fetch(`${API}/project/${projectId}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
    body: JSON.stringify(project),
  })
    .then(authMiddleware)
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const updateLogDates = (projectId, status) => {
  const token = getCookie("token");

  return fetch(`${API}/project/log-dates/${projectId}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
    body: JSON.stringify({ nameValuePair: status }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const createProjectByHashAsGift = (label, hash) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project/${label}/${hash}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};
