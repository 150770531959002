import React, { useEffect } from "react";
import {
  validateTokenTokStok,
  getQueryStringValue,
  authenticate,
  isAuth,
} from "../actions/auth";
import { brokenLink } from "../actions/logs";
import { Row, Col } from "shards-react";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import { LANDING_PAGE_TOKSTOK } from "../config";

function Validator() {
  useEffect(() => {
    let token = getQueryStringValue("u");
    let page = getQueryStringValue("page");
    validateTokenTokStok(token).then((validate) => {
      if (!validate) {
        brokenLink(window.location.href).then(() => {
          window.open(`${LANDING_PAGE_TOKSTOK}`, "_self");
        });
      } else if (validate.error) {
        brokenLink(window.location.href).then(() => {
          window.open(`${LANDING_PAGE_TOKSTOK}`, "_self");
        });
      } else {
        authenticate(validate, () => {
          if (isAuth()) {
            if (page === "checkout") {
              let urlOpen = `/checkout-tokstok`;
              if (window.location.search.length > 0) {
                urlOpen = `/checkout-tokstok${window.location.search}`;
              }
              window.open(urlOpen, "_self");
            } else if (page === "myorders") {
              window.open(`/environments-list`, "_self");
            } else if (!page) {
              brokenLink(window.location.href).then(() => {
                let urlOpen = `/environments`;
                window.open(urlOpen, "_self");
              });
            } else {
              brokenLink(window.location.href).then(() => {
                let urlOpen = `/environments`;
                window.open(urlOpen, "_self");
              });
            }
          }
        });
      }
    });
  }, []);
  return (
    <div style={{ marginTop: "2%" }}>
      <Row className="mt-5 mb-5">
        <Col
          sm={{ size: 6, order: 2, offset: 5 }}
          xs={{ size: 4, order: 4, offset: 2 }}
          className="mt-5"
        >
          <img
            id="mmain-logo"
            className="align-center"
            src={require("../images/tokstok.svg")}
            alt="Tok&Stok"
            style={{ maxHeight: "5vh" }}
          />
        </Col>
      </Row>
      <Row className="mt-5">
        <Col xs="12">
          <LoadingOverlay
            active={true}
            spinner={<BounceLoader />}
          ></LoadingOverlay>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col xs="12" className="text-align-center mt-5">
          <p>Olá!</p>
          <p>
            Aguarde um instante e iremos redirecionar você para dar continuidade
            ao processo.
          </p>
          <p>Obrigado!</p>
        </Col>
      </Row>
    </div>
  );
}

export default Validator;
