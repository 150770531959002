import { API } from "../config";
import { requestWrapper } from "../utils/api";
import { getCookie, authMiddleware } from "../actions/auth";

export const sendMessage = (senderId, message, receiver, category) => {
  const token = getCookie("token");

  return fetch(`${API}/message/send`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
    body: JSON.stringify({
      user: senderId,
      text: message,
      project: receiver,
      category,
      timestamp: new Date(),
    }),
  })
    .then(authMiddleware)
    .then((response) => {
      if (response.status !== 200) {
        throw new Error("Server error");
      }
      return response;
    })
    .then((response) => {
      return response.json();
    });
};

export const getChatMessages = (projectId, category) => {
  const token = getCookie("token");

  return fetch(`${API}/message/${projectId}/all?category=${category}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
  })
    .then(authMiddleware)
    .then((response) => {
      if (response.status !== 200) {
        throw new Error("Server error");
      }
      return response;
    })
    .then((response) => {
      return response.json();
    });
};

export const readMessages = (projectId, type) => {
  const token = getCookie("token");

  return fetch(`${API}/message/${projectId}/${type}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
  })
    .then(authMiddleware)
    .then((response) => {
      if (response.status !== 200) {
        throw new Error("Server error");
      }
      return response;
    })
    .then((response) => {
      return response.json();
    });
};

export const uploadFile = (file, projectId, kind, source) => {
  const token = getCookie("token");
  const formData = new FormData();
  if (file) {
    formData.append("file", file);
    formData.append("kind", kind);
    formData.append("source", source);
    formData.append("timestamp", new Date());
    formData.append("folder", "chat");
  } else {
    throw new Error("No file");
  }

  return requestWrapper(
    fetch(`${API}/message/upload/${projectId}`, {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};
