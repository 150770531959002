import React from "react";

function NoNotifications(props) {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center"
      style={{ height: "250px" }}
    >
      <span
        style={{ fontSize: "60px", opacity: "0.4" }}
        className="material-icons"
      >
        notifications_off
      </span>
      <p style={{ fontSize: "16px" }}>Sem notificações</p>
    </div>
  );
}

NoNotifications.propTypes = {};

export default NoNotifications;
