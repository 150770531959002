import React, { useState } from "react";
import ReactStars from "react-rating-stars-component";
import PropTypes from "prop-types";
import { Button } from "shards-react";

function Rating(props) {
  const { title, onFinish, size, onSave, stars, text } = props;
  const [review, setReview] = useState({
    stars: stars,
    text: text,
  });
  return (
    <>
      <p className="m-0" style={{ fontSize: "16px" }}>
        {title}
      </p>

      <>
        <ReactStars
          size={size}
          value={review.stars}
          edit={true}
          onChange={(value) => {
            const r = { ...review, stars: value };
            setReview(r);
            onSave(r);
          }}
        />
        <p className="m-0 d-flex flex-column" style={{ color: "#9ea8b9" }}>
          Escolha de 1 a 5 estrelas para classificar
        </p>
      </>
      {review.stars ? (
        <>
          <textarea
            value={review.text}
            onChange={(e) => {
              setReview({ ...review, text: e.target.value });
            }}
            rows="4"
            className="form-control mt-2 fade-in"
            placeholder="Se quiser, deixe-nos uma mensagem..."
          />
          <Button
            className="mt-2 mb-5"
            onClick={() => {
              onFinish(review);
            }}
          >
            Enviar
          </Button>
        </>
      ) : null}
    </>
  );
}

Rating.propTypes = {
  text: PropTypes.string,
  onChange: PropTypes.func,
  stars: PropTypes.number,
  reviewText: PropTypes.string,
  size: PropTypes.number,
};

export default Rating;
