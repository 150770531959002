import { API } from "../config";
import { getCookie } from "./auth";

export const dismissNotification = (notificationId, isByLinkId) => {
  const token = getCookie("token");

  return fetch(
    `${API}/notification/${notificationId}/dismiss${
      isByLinkId ? "?linkId=true" : ""
    }`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const readNotifications = (notificationIds) => {
  const token = getCookie("token");

  return fetch(`${API}/notification/read`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(notificationIds),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const readBannerNotifications = (notificationIds) => {
  const token = getCookie("token");

  return fetch(`${API}/notification/read_banner`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(notificationIds),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};
