import React from "react";

import { FormSelect, Row, Col } from "shards-react";

const CreditCardExpiry = ({ month, year, onChange }) => {
  let availableMonths = [];
  let availableYears = [];
  for (let i = 1; i <= 12; i++) {
    availableMonths.push(formatSelectLabel(i));
  }
  let currentYearLast2Digits = getCurrentYearLast2Digits();
  for (let i = 0; i <= 15; i++) {
    availableYears.push(formatSelectLabel(i + currentYearLast2Digits));
  }

  function getCurrentYearLast2Digits() {
    return parseInt(
      new Date()
        .getFullYear()
        .toString()
        .substring(2, 4)
    );
  }

  function onLocalChange(ev) {
    onChange({
      month,
      year,
      [ev.target.name]: ev.target.value,
    });
  }

  function formatSelectLabel(value) {
    return value < 10 ? `0${value}` : value;
  }

  return (
    // <div className="Expiry">
    <Row form>
      <Col xs="6" className="form-group">
        <FormSelect
          value={month || ""}
          name="month"
          onChange={onLocalChange}
          required
        >
          <option disabled value="">
            Mês
          </option>
          {availableMonths.map((month, idx) => (
            <option value={month} key={idx}>
              {month}
            </option>
          ))}
        </FormSelect>
      </Col>
      <Col xs="6" className="form-group">
        <FormSelect
          id="checkout-card-expiry-year"
          value={year || ""}
          name="year"
          onChange={onLocalChange}
          required
        >
          <option disabled value="">
            Ano
          </option>
          {availableYears.map((year, idx) => (
            <option key={idx} value={year}>
              {year}
            </option>
          ))}
        </FormSelect>
      </Col>
    </Row>
    // </div>
  );
};

CreditCardExpiry.propTypes = {};

export default CreditCardExpiry;
