import React, { useState, useEffect } from "react";
import { Row, Card, CardBody, Col, Button, Fade } from "shards-react";
import PageTitle from "../../common/PageTitle";
import DropzoneArea from "../../file-manager-cards/DropzoneArea";
import ImgTuto1 from "../../../images/learning-env-tutorial/01.jpeg";
import ImgTuto2 from "../../../images/learning-env-tutorial/02.jpeg";
import ImgTuto3 from "../../../images/learning-env-tutorial/03.jpeg";
import ImgTuto4 from "../../../images/learning-env-tutorial/04.jpeg";
import ImgTuto5 from "../../../images/learning-env-tutorial/05.jpeg";
import { updateProject } from "../../../actions/project";
import { BUCKET_URL } from "./../../../config";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LearningEnvironment = (props) => {
  const STEP = 4;
  const [countFiles, setCountFiles] = useState();
  const [files, setFiles] = useState([]);
  const [filesUploaded, setFilesUploaded] = useState([]);
  const [text, setText] = useState();
  const [showVideo, setShowVideo] = useState(false);
  const [lightbox, setLightbox] = useState({ open: false, img: "" });

  useEffect(() => {
    if (props.project.learningEnvironment.files.length > 0) {
      setCountFiles(props.project.learningEnvironment.files.length);
      setFiles(props.project.learningEnvironment.files);
      setFilesUploaded(props.project.learningEnvironment.files);
    }
    if (props.project.learningEnvironment.details) {
      let details = props.project.learningEnvironment.details;
      setText(details);
    }
  }, []);

  const sendLightbox = (img) => {
    setLightbox({ img: img, open: true });
  };

  const update = (data) => {
    data = data.map((file) => {
      return file.name.replace(".HEIC", ".jpeg").replace(".heic", ".jpeg");
    });
    setCountFiles([...files, ...data].length);

    props.project.learningEnvironment.files = [...files, ...data];
    setFiles([...files, ...data]);

    setFilesUploaded(props.project.learningEnvironment.files);

    updateProject(props.project._id, props.project);
  };

  const removeImg = (idx) => {
    const data = props.project.learningEnvironment.files;
    data.splice(idx, 1);
    setCountFiles(data.length);
    props.project.learningEnvironment.files = data;
    setFiles(data);
    setFilesUploaded(props.project.learningEnvironment.files);
    updateProject(props.project._id, props.project);
  };

  const nextStep = () => {
    if (files.length > 0) {
      props.project.completedSteps.push(STEP);
      props.project.learningEnvironment.details = text;
      props.updateStepCompleted({
        learningEnvironment: {
          ...props.project.learningEnvironment,
          details: text,
        },
        completedSteps: props.project.completedSteps,
        _id: props.project._id,
      });
      props.callbackReturnView();
    } else {
      toast.error(
        "Para dar continuidade a sua assessoria precisamos que envie o desenho com medidas do ambiente!",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Row noGutters className="page-header py-4">
        <PageTitle
          title={`Ambiente:  ${props.project.name}`}
          subtitle="Visão Geral"
          md="12"
          className="ml-sm-auto mr-sm-auto"
        />
      </Row>
      {lightbox.open && (
        <Lightbox
          mainSrc={lightbox.img}
          reactModalStyle={{ overlay: { zIndex: 10000 } }}
          onCloseRequest={() => setLightbox({ ...lightbox, open: false })}
        />
      )}
      <Row>
        <Col>
          <Card>
            <CardBody>
              <label
                theme="accent"
                className="mb-1"
                style={{ border: "none", cursor: "pointer" }}
                onClick={() => {
                  props.goBack();
                }}
              >
                &#8592; VOLTAR
              </label>
              <h5>
                Nesta etapa você nos envia fotos do local que deseja decorar,
                envie tambem a planta baixa do seu espaço
              </h5>
              <h6>
                Caso você não tenha a planta, temos este vídeo que te ensina a
                medir! {/* eslint-disable  */}
                <a
                  onClick={() => {
                    setShowVideo(!showVideo);
                  }}
                >
                  <u>Ver Video</u>
                </a>
                {/* eslint-disable  */}
              </h6>
              {showVideo && (
                <Row>
                  <Col md="4"></Col>
                  <Col md="4" className="mb-2" style={{ textAlign: "center" }}>
                    <video
                      width="100%"
                      height="240"
                      controls
                      style={{
                        display: "block",
                        margin: "0 auto",
                        outline: "none",
                      }}
                    >
                      <source
                        src={
                          "https://s3.amazonaws.com/ladecora.platform/medicao.mp4"
                        }
                        type="video/mp4"
                      />
                    </video>
                  </Col>
                  <Col md="4"></Col>
                </Row>
              )}
              <Row>
                <Col md="12" className={"text-center"}>
                  <img
                    alt=""
                    className="border rounded img-fluid"
                    style={{ width: "180px", height: "180px" }}
                    src={ImgTuto1}
                    onClick={() => sendLightbox(ImgTuto1)}
                  />
                  <img
                    alt=""
                    className="border rounded img-fluid"
                    style={{ width: "180px", height: "180px" }}
                    src={ImgTuto2}
                    onClick={() => sendLightbox(ImgTuto2)}
                  />
                  <img
                    alt=""
                    className="border rounded img-fluid"
                    style={{ width: "180px", height: "180px" }}
                    src={ImgTuto3}
                    onClick={() => sendLightbox(ImgTuto3)}
                  />
                  <img
                    alt=""
                    className="border rounded img-fluid"
                    style={{ width: "180px", height: "180px" }}
                    src={ImgTuto4}
                    onClick={() => sendLightbox(ImgTuto4)}
                  />
                  <img
                    alt=""
                    className="border rounded img-fluid"
                    style={{ width: "180px", height: "180px" }}
                    src={ImgTuto5}
                    onClick={() => sendLightbox(ImgTuto5)}
                  />
                </Col>
              </Row>
              <br />
              <h6>
                No campo abaixo faça o upload das fotos do seu ambiente.
                Lembramos que para dar continuidade à assessoria precisamos que
                envie o desenho com as medidas do ambiente!
                <br />
                Enviar arquivos nas extensões: png, jpg, jpeg, pdf, dwg, skp ou
                heic
              </h6>
              <DropzoneArea
                projectId={props.project._id}
                callbackHandleDrop={update}
              />
              {countFiles && (
                <Fade in={!!countFiles} theme="accent">
                  Arquivos enviados: <b>{countFiles}</b>
                </Fade>
              )}
              <br />
              {filesUploaded != undefined && filesUploaded.length > 0 && (
                <div className="user-details__user-data border-top border-bottom p-4">
                  <Row>
                    <Col>
                      <h6 className="text-center m-0 mt-2">Arquivos</h6>
                    </Col>
                  </Row>
                  <Row>
                    {filesUploaded.map((img, idx) => {
                      return (
                        <Col
                          key={idx}
                          sm="12"
                          md="6"
                          lg="4"
                          className="pt-2 text-center"
                        >
                          <Card
                            small
                            className="mb-3"
                            style={{ width: "200px" }}
                          >
                            <CardBody className="p-0">
                              <img
                                alt=""
                                className="border rounded img-fluid"
                                style={{ width: "200px", height: "200px" }}
                                src={
                                  BUCKET_URL +
                                  "/projects/" +
                                  props.project._id +
                                  "/customer/" +
                                  img
                                }
                                onClick={() =>
                                  sendLightbox(
                                    BUCKET_URL +
                                      "/projects/" +
                                      props.project._id +
                                      "/customer/" +
                                      img
                                  )
                                }
                              />
                              <Button
                                outline
                                style={{ width: "200px" }}
                                theme="danger"
                                onClick={() => {
                                  removeImg(idx);
                                }}
                              >
                                Excluir
                              </Button>
                            </CardBody>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              )}
              {/* <textarea
                value={text}
                onChange={(e) => {
                  setText(e.target.value);
                }}
                rows="10"
                className="form-control mt-2"
                placeholder="Observações sobre o ambiente"
              /> */}
              <br />
              <div style={{ cssFloat: "right" }}>
                <Button theme="accent" onClick={nextStep}>
                  Continuar
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default LearningEnvironment;
