import React, { useState, useEffect } from "react";

import {
  getAuthenticatedUserV2,
  getCookie,
  localSignout,
} from "../../actions/auth";
import { AuthProvider } from "./AuthContext";

const Authorization = ({ children }) => {
  const token = getCookie("token");
  const [state, setState] = useState({
    authenticated: !!token,
    doubleChecked: false,
    user: {},
    accessToken: token,
  });

  const authProviderValue = {
    ...state,
  };

  useEffect(() => {
    getAuthenticatedUserV2()
      .then((user) => {
        if (user) {
          setState({
            user: user,
            token: getCookie("token"),
            authenticated: true,
            doubleChecked: true,
          });
        } else {
          localSignout();
          window.reload();
        }
      })
      .catch((err) => {
        setState({
          authenticated: false,
          doubleChecked: true,
        });
      });
  }, []);

  return (
    <AuthProvider value={authProviderValue}>
      {state.doubleChecked && children}
    </AuthProvider>
  );
};

export default Authorization;
