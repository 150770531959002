import React, { useContext, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { getAllProjects } from "../actions/project";
import Steps from "../components/environments/steps/Steps";
import DefaultQuestions from "../components/environments/steps/DefaultQuestions";
import Wizard from "../components/environments/steps/Wizard";
import LearningEnvironment from "../components/environments/steps/LearningEnvironment";
import Inspiration from "../components/environments/steps/Inspiration";
import FurnitureMeasures from "../components/environments/steps/FurnitureMeasures";
import SetNameProject from "../components/environments/steps/SetNameProject";
import Review from "../components/environments/steps/Review";
import DownloadProject from "../components/environments/steps/DownloadProject";
import { updateProject, updateLogDates } from "../actions/project";
import { updateUser } from "../actions/user";
import { getLocalStorage } from "../actions/auth";
import { BUCKET_URL } from "../config";
import { useHistory } from "react-router-dom";
import { getNextForcedStep } from "../services/project";
import { dismissNotification } from "../actions/notification";
import { toast, ToastContainer } from "react-toastify";
import { AuthContext } from "../components/Auth/AuthContext";
import { getQueryStringValue } from "../actions/auth";

const EnvironmentsList = () => {
  const [projects, setProjects] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [currentProject, setCurrentProject] = useState();
  const [state, setState] = useState({
    showDefaultQuestions: false,
    showWizard: false,
    showNewEnvironment: false,
    showSetNameProject: false,
    showProjectsList: false,
    showSteps: false,
    showLearningEnvironment: false,
    showInspiration: false,
    showFurnitureMeasures: false,
    showReview: false,
    showDownloadProject: false,
  });
  const { user } = useContext(AuthContext);
  const selectedProjectId = getQueryStringValue("show");

  useEffect(() => {
    getAllProjects().then((data) => {
      data = data.filter(
        (element) => element.questionTemplate.status !== "perfil"
      );
      if (!user.answerDefaultQuestion.length && data.length > 0) {
        setState({ ...state, showDefaultQuestions: true });
      } else {
        setProjects(data);
        if (data.length) {
          setState({ ...state, showProjectsList: true, showSteps: true });
        } else {
          setState({ ...state, showNewEnvironment: true });
        }
      }
    });
  }, []);

  const {
    showDefaultQuestions,
    showWizard,
    showNewEnvironment,
    showSetNameProject,
    showProjectsList,
    showSteps,
    showLearningEnvironment,
    showInspiration,
    showFurnitureMeasures,
    showReview,
    showDownloadProject,
  } = state;

  const stepCardCallback = (step, project) => {
    setCurrentProject(project);
    cardDetailshow(step, project);
  };

  const cardDetailshow = (step, project) => {
    switch (step) {
      case 0:
        break;
      case 1:
        break;
      case 2:
        setState({ ...state, showSetNameProject: true, showSteps: false });
        break;
      case 3:
        setState({ ...state, showWizard: true, showProjectsList: false });
        history.push("/environments-list#step1");
        break;
      case 4:
        setState({
          ...state,
          showLearningEnvironment: true,
          showProjectsList: false,
        });
        break;
      case 5:
        setState({
          ...state,
          showInspiration: true,
          showProjectsList: false,
        });
        break;
      case 6:
        setState({
          ...state,
          showFurnitureMeasures: true,
          showProjectsList: false,
        });
        break;
      case 7:
        setOpenModal(true);
        break;
      case 8:
        break;
      case 9:
        setState({
          ...state,
          showReview: true,
          showProjectsList: false,
        });

        break;
      default:
        setState({
          ...state,
          showDownloadProject: true,
          showProjectsList: false,
        });
        break;
    }
  };

  const newEnvironment = () => {
    return (
      <div className="error">
        <div className="error__content">
          <h2>&#8251;</h2>
          <h3>Você não possui nenhum ambiente</h3>
          <p>Clique no botão abaixo para comprar um novo ambiente.</p>
          <Button
            pill
            theme="accent"
            onClick={() => window.open("/environments", "_self")}
          >
            &#43; Novo ambiente
          </Button>
        </div>
      </div>
    );
  };

  const titleMain = () => {
    return (
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="Ambientes"
          subtitle="Visão Geral"
          md="12"
          className="ml-sm-auto mr-sm-auto"
        />
      </Row>
    );
  };

  const updateStepCompleted = (data) => {
    let project = projects.find((p) => p._id === data._id);
    project = updateForcedStep(project);
    data.forcedStep = project.forcedStep;
    return updateProject(data._id, data);
  };

  const updateForcedStep = (project) => {
    let fixedStep = getNextForcedStep(project);
    if (fixedStep) {
      dismissNotification(fixedStep.key, true)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });

      fixedStep.isPending = false;
    }
    return project;
  };

  const updateDefaultQuestionsCompleted = (answers) => {
    updateUser(user._id, { answerDefaultQuestion: answers }).then((data) => {
      history.push("/environments-list");
      refreshPage();
    });
  };

  const history = useHistory();
  const callbackReturnView = (returnPage = false, clearUrl = false) => {
    setCurrentProject();
    if (
      (currentProject.status === "customer-approved" ||
        currentProject.status === "waiting_render_approved") &&
      !returnPage
    ) {
      toast.success("Assessoria aprovada com sucesso!", { autoClose: 5000 });
    }
    if (clearUrl) {
      window.history.replaceState({}, document.title, "/environments-list");
    }
    displayComponentsOff();
  };

  function goBack(returnPage) {
    callbackReturnView(returnPage, true);
  }

  const displayComponentsOff = () => {
    setState({
      ...state,
      showProjectsList: true,
      showSteps: true,
      showDefaultQuestions: false,
      showSetNameProject: false,
      showNewEnvironment: false,
      showWizard: false,
      showLearningEnvironment: false,
      showInspiration: false,
      showFurnitureMeasures: false,
      showReview: false,
      showDownloadProject: false,
    });
  };
  const toggle = () => {
    setOpenModal(!openModal);
    setCurrentProject();
  };

  const sendProject = () => {
    let STEP = 7;
    currentProject.completedSteps.push(STEP);
    currentProject.status = "submitted";
    updateStepCompleted({
      status: "submitted",
      completedSteps: currentProject.completedSteps,
      _id: currentProject._id,
    });
    updateLogDates(currentProject._id, "submitted");
    toggle();
  };
  function refreshPage() {
    window.location.reload(false);
  }

  return (
    <Container
      fluid
      className="main-content-container px-4"
      style={{ resize: "both" }}
    >
      <ToastContainer autoClose={false} />
      {showProjectsList && titleMain()}
      {showProjectsList && (
        <Row>
          {!!projects &&
            projects
              .sort((a, b) => {
                if (selectedProjectId) {
                  if (a._id === selectedProjectId) return -1;
                  if (b._id === selectedProjectId) return 1;
                }
                return 0;
              })
              .map((project, idx) => {
                return (
                  <Col key={idx} lg="12" md="12" sm="12" className="mb-4">
                    {selectedProjectId ? (
                      <>
                        {selectedProjectId && idx === 0 ? (
                          <h4 className="m-0 mb-2">Assessoria selecionada</h4>
                        ) : null}
                        {selectedProjectId && idx === 1 && selectedProjectId ? (
                          <>
                            <hr />
                            <h4 className="m-0 mb-2 mt-4">
                              Outras assessorias
                            </h4>
                          </>
                        ) : null}
                      </>
                    ) : null}
                    <Card
                      small
                      className={
                        project._id === selectedProjectId
                          ? "project-highlight"
                          : ""
                      }
                    >
                      <CardHeader className="border-bottom">
                        <h6 className="m-0">{project.name}</h6>
                      </CardHeader>

                      <CardBody>
                        <Row>
                          <Col lg="3" md="12" className="m-.5">
                            <img
                              className="rounded"
                              style={{ width: "100%" }}
                              src={
                                project.questionTemplate.thumbnail
                                  ? BUCKET_URL +
                                    "/" +
                                    project.questionTemplate.thumbnail
                                  : "https://i.pinimg.com/originals/a3/a2/53/a3a253b2f7828ffb273f4b1fde9ddd2f.jpg"
                              }
                              alt=""
                            />
                          </Col>
                          <Col lg="9" md="12">
                            {!currentProject
                              ? showSteps && (
                                  <Steps
                                    callbackStepCard={stepCardCallback}
                                    project={project}
                                    projectCompletedSteps={
                                      project.completedSteps
                                    }
                                  />
                                )
                              : project._id !== currentProject._id && (
                                  <Steps
                                    callbackStepCard={stepCardCallback}
                                    project={project}
                                    projectCompletedSteps={
                                      project.completedSteps
                                    }
                                  />
                                )}
                            {showSetNameProject &&
                              project._id === currentProject._id && (
                                <SetNameProject
                                  project={currentProject}
                                  callbackReturnView={callbackReturnView}
                                  updateStepCompleted={updateStepCompleted}
                                />
                              )}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
        </Row>
      )}
      {showDefaultQuestions && (
        <DefaultQuestions
          callbackReturnView={callbackReturnView}
          updateDefaultQuestionsCompleted={updateDefaultQuestionsCompleted}
        />
      )}

      {showWizard && (
        <Wizard
          project={currentProject}
          goBack={goBack}
          callbackReturnView={callbackReturnView}
          updateStepCompleted={updateStepCompleted}
        />
      )}

      {showLearningEnvironment && (
        <LearningEnvironment
          project={currentProject}
          goBack={goBack}
          callbackReturnView={callbackReturnView}
          updateStepCompleted={updateStepCompleted}
        />
      )}
      {showInspiration && (
        <Inspiration
          goBack={goBack}
          project={currentProject}
          callbackReturnView={callbackReturnView}
          updateStepCompleted={updateStepCompleted}
        />
      )}
      {showFurnitureMeasures && (
        <FurnitureMeasures
          project={currentProject}
          goBack={goBack}
          callbackReturnView={callbackReturnView}
          updateStepCompleted={updateStepCompleted}
        />
      )}

      {showReview && (
        <Review
          goBack={goBack}
          project={currentProject}
          callbackReturnView={callbackReturnView}
          updateStepCompleted={updateStepCompleted}
        />
      )}

      {showDownloadProject && (
        <DownloadProject
          project={currentProject}
          goBack={goBack}
          callbackReturnView={callbackReturnView}
          updateStepCompleted={updateStepCompleted}
        />
      )}
      {showNewEnvironment && newEnvironment()}

      <Modal open={openModal} animation toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <span role="img" aria-label="">
            👋
          </span>{" "}
          Atenção
        </ModalHeader>
        <ModalBody>
          Caso queira incluir ou revisar alguma informação este é o momento,
          pois a partir da sua confirmação vamos analisar as suas informações e
          a partir dai, enviaremos a assessoria em 5 dias úteis! Fique tranquilo
          que após a primeira entrega poderá solicitar até 2 revisões, para
          deixar seu ambiente como sempre sonhou!
        </ModalBody>
        <ModalFooter>
          <Button outline theme="accent" onClick={toggle}>
            Quero revisar
          </Button>
          <Button theme="accent" onClick={sendProject}>
            Confirmar
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};
export default EnvironmentsList;
