import React, { useEffect, useState } from "react";
import {
  validateTokenTokStok,
  getQueryStringValue,
  authenticate,
  isAuth,
} from "../actions/auth";
import { getUser, updateUser } from "../actions/user";
import { createProjectByHashAsGift } from "../actions/project";
import { validateGift } from "../actions/gift";
import { brokenLink } from "../actions/logs";
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  Container,
  Card,
  CardBody,
  CardHeader,
  FormInput,
} from "shards-react";
import CpfCnpj from "../components/common/CpfCnpj";
import PhoneBR from "../components/common/PhoneBR";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import { LANDING_PAGE_TOKSTOK } from "../config";
import {
  valida_cpf_cnpj,
  phoneValidation,
  emailValidation,
  getFormattedPhone,
} from "../utils/form_validation";
import { getDescendantProp } from "../utils/sanitize";
import { toast, ToastContainer } from "react-toastify";

function Gift() {
  const history = useHistory();
  const label = "tok_stok";
  const [user, setUser] = useState({
    profile: {
      firstName: "",
      lastName: "",
      phone: "",
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [formValidations, setFormValidations] = useState({
    firstName: {
      validators: [required],
      isValid: true,
      accessor: "profile.firstName",
    },
    lastName: {
      validators: [required],
      isValid: true,
      accessor: "profile.lastName",
    },
    email: {
      validators: [required, emailValidation],
      isValid: true,
    },
    document: {
      validators: [required, valida_cpf_cnpj],
      isValid: true,
      accessor: "profile.document",
    },
    phone: {
      validators: [required, phoneValidation],
      isValid: true,
      accessor: "profile.phone",
    },
  });
  const projectTemplatesHash = getQueryStringValue("environment");

  useEffect(() => {
    const token = getQueryStringValue("u");

    validateTokenTokStok(token).then((validate) => {
      if (!validate) {
        brokenLink(window.location.href).then(() => {
          window.open(`${LANDING_PAGE_TOKSTOK}`, "_self");
        });
      } else if (validate.error) {
        brokenLink(window.location.href).then(() => {
          window.open(`${LANDING_PAGE_TOKSTOK}`, "_self");
        });
      } else {
        authenticate(validate, () => {
          if (isAuth()) {
            validateGift("tok_stok", label)
              .then((res) => {
                getUser()
                  .then((user) => {
                    setUser({
                      ...user,
                      profile: {
                        ...user.profile,
                        phone: getFormattedPhone(user.profile.phone),
                      },
                    });
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
              .catch((err) => {
                history.replace("/404");
              });
          }
        });
      }
    });
  }, []);

  function isInputValid(field, value) {
    let fieldValidations = formValidations[field].validators;
    let result = fieldValidations.reduce((isValid, nextValidation) => {
      return isValid && nextValidation(value, user);
    }, true);
    if (formValidations[field].postValidate) {
      formValidations[field].postValidate(result);
    }
    return result;
  }

  function onInputChange(name, value) {
    if (isInputValid(name, value)) {
      formValidations[name].error = false;
    } else {
      formValidations[name].error = true;
    }
    setFormValidations({
      ...formValidations,
    });
  }

  function required(value) {
    return !!value;
  }

  function isFieldValid(validatorName, accessor) {
    let value = getDescendantProp(user, accessor ? accessor : validatorName);
    let fieldValidations = formValidations[validatorName].validators;
    let result = fieldValidations.reduce((isValid, nextValidation) => {
      return isValid && nextValidation(value, user);
    }, true);
    return result;
  }

  function isFormValid() {
    let keys = Object.keys(formValidations);
    return keys.reduce((isValid, key) => {
      return isValid && formValidations[key].isValid;
    }, true);
  }

  function validate() {
    let fieldNames = Object.keys(formValidations);
    let validations = fieldNames.reduce((acc, current) => {
      const accessor = formValidations[current].accessor;
      acc[current] = isFieldValid(current, accessor);
      return acc;
    }, {});
    applyValidationsToForm(validations);
  }

  function applyValidationsToForm(validations) {
    let keys = Object.keys(formValidations);
    keys.forEach((key) => {
      formValidations[key].isValid = validations[key];
      if (!validations[key]) {
        formValidations[key].error = true;
      }
    });
    setUser({
      ...user,
    });
  }

  function getHtmlValidations(key) {
    return {
      [typeof formValidations[key].error === "undefined"
        ? "neutral"
        : formValidations[key].error
        ? "invalid"
        : "valid"]: "true",
    };
  }

  function confirmProfileValidation(e) {
    e.preventDefault();
    validate();

    if (isFormValid()) {
      setIsLoading(true);
      updateUser(user._id, {
        profile: {
          ...user.profile,
          phone: `+55${user.profile.phone.replace(/\D/g, "")}`,
          document: user.profile.document.replace(/[^0-9]/g, ""),
        },
      })
        .then(() => {
          createProjectByHashAsGift(label, projectTemplatesHash)
            .then((res) => {
              setIsLoading(false);
              window.open(`/environments-list?show=${res._id}`, "_self");
            })
            .catch((err) => {
              toast.error(err.message);
              setIsLoading(false);
            });
        })
        .catch((err) => {
          toast.error(err.message);
          setIsLoading(false);
        });
    }
    return false;
  }
  return user._id ? (
    <Container
      fluid
      className="main-content-container p-0 mx-auto m-0"
      style={{ maxWidth: "800px" }}
    >
      <ToastContainer />
      <Row>
        <Col lg="12">
          <Card className="mt-5">
            <CardHeader className="border-bottom">
              <div className="d-flex justify-content-center align-items-center">
                <span
                  class="material-icons"
                  style={{
                    fontSize: "70px",
                    color: "var(--newbackground-color)",
                  }}
                >
                  redeem
                </span>
              </div>
              <h3 className="text-center">
                Você recebeu uma assessoria de presente!
              </h3>

              <p className="text-center" style={{ fontSize: "16px" }}>
                Confirme suas informações para iniciar a sua assessoria
              </p>
            </CardHeader>
            <CardBody>
              <Form onSubmit={confirmProfileValidation}>
                <div className="form-group">
                  <label>Email</label>
                  <FormInput
                    type="text"
                    className="form-control"
                    disabled={true}
                    required
                    onChange={(ev) =>
                      setUser({ ...user, email: ev.target.value })
                    }
                    name="email"
                    value={user.email}
                    placeholder="Digite seu email"
                    {...getHtmlValidations("email")}
                  />
                </div>
                <div className="form-group">
                  <label>Primeiro nome</label>
                  <FormInput
                    type="text"
                    className="form-control"
                    required
                    disabled={isLoading}
                    onChange={(ev) => {
                      setUser({
                        ...user,
                        profile: {
                          ...user.profile,
                          firstName: ev.target.value,
                        },
                      });
                      onInputChange("firstName", ev.target.value);
                    }}
                    name="firstName"
                    value={user.profile.firstName}
                    {...getHtmlValidations("firstName")}
                  />
                </div>
                <div className="form-group">
                  <label>Último nome</label>
                  <FormInput
                    type="text"
                    className="form-control"
                    required
                    disabled={isLoading}
                    onChange={(ev) => {
                      setUser({
                        ...user,
                        profile: {
                          ...user.profile,
                          lastName: ev.target.value,
                        },
                      });
                      onInputChange("lastName", ev.target.value);
                    }}
                    name="lastName"
                    value={user.profile.lastName}
                    {...getHtmlValidations("lastName")}
                  />
                </div>
                <div className="form-group">
                  <label>CPF</label>
                  <CpfCnpj
                    type="text"
                    className="form-control"
                    disabled={isLoading}
                    required
                    onChange={(document) => {
                      setUser({
                        ...user,
                        profile: {
                          ...user.profile,
                          documentType: document.type,
                          document: document.value,
                        },
                      });
                      onInputChange("document", document.value);
                    }}
                    trackname="documento"
                    name="document"
                    value={user.profile.document}
                    placeholder="999.999.999-99"
                    {...getHtmlValidations("document")}
                  />
                </div>
                <div className="form-group">
                  <label>Telefone</label>
                  <PhoneBR
                    required
                    disabled={isLoading}
                    value={user.profile.phone}
                    onChange={(phone) => {
                      setUser({
                        ...user,
                        profile: {
                          ...user.profile,
                          phone: phone,
                        },
                      });
                      onInputChange("phone", phone);
                    }}
                    placeholder="Informe seu telefone"
                    autoComplete="phone"
                    name="phone"
                    className="form-control"
                    {...getHtmlValidations("phone")}
                  />
                </div>

                <div style={{ display: "grid" }}>
                  <button
                    type="submit"
                    className="btn btn-custom-primary mt-3"
                    data-gtm-event-category="tokstok:decora:home"
                    data-gtm-event-action="clique:botao:confirmacao-dados"
                    data-gtm-event-label="li-e-aceito-os-termos"
                    disabled={isLoading}
                    style={{
                      backgroundColor: "rgb(10, 119, 90)",
                      borderColor: "rgb(10, 119, 90)",
                      color: "white",
                      fontSize: "16px",
                    }}
                  >
                    {isLoading ? "Salvando..." : "Salvar"}
                  </button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  ) : (
    <div style={{ marginTop: "2%" }}>
      <Row className="mt-5 mb-5">
        <Col
          sm={{ size: 6, order: 2, offset: 5 }}
          xs={{ size: 4, order: 4, offset: 2 }}
          className="mt-5"
        >
          <img
            id="mmain-logo"
            className="align-center"
            src={require("../images/tokstok.svg")}
            alt="Tok&Stok"
            style={{ maxHeight: "5vh" }}
          />
        </Col>
      </Row>
      <Row className="mt-5">
        <Col xs="12">
          <LoadingOverlay
            active={true}
            spinner={<BounceLoader />}
          ></LoadingOverlay>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col xs="12" className="text-align-center mt-5">
          <p>Olá!</p>
          <p>
            Aguarde um instante e iremos redirecionar você para dar continuidade
            ao processo.
          </p>
          <p>Obrigado!</p>
        </Col>
      </Row>
    </div>
  );
}

export default Gift;
