import React, { useState, useEffect } from "react";
import { Row, Card, CardBody, Col, CardFooter } from "shards-react";
import classNames from "classnames";
import { BUCKET_URL } from "../../../config";

const MultipleImage = (props) => {
  const [inputList, setInputList] = useState([]);
  useEffect(() => {
    const questionResponse = props.answeredQuestions.filter(
      (question) => question.question === props.node.node.title
    );
    props.node.node.input_list.map((input) => {
      input["selected"] = false;
      if (questionResponse.length > 0) {
        if (questionResponse[0].answer === input.image_path) {
          update(input.image_path, input.label);
          props.node.node.input = input;
          input["selected"] = true;
        }
      }
      return input;
    });
    setInputList(props.node.node.input_list);
  }, []);

  const update = (data, label) => {
    props.update({
      question: props.node.node.title,
      answer: data,
      label: label,
    });
  };

  const handleInputClick = (input) => {
    update(input.image_path, input.label);
    props.node.node.input = input;
    setInputList(
      inputList.map((item) => {
        item["selected"] = false;
        return item.image_path === input.image_path
          ? { ...item, selected: !item.selected }
          : item;
      })
    );
  };

  return (
    <div>
      <label>{props.node.node.title}</label>
      <div className="file-manager file-manager-cards">
        <Row className="justify-content-md-center">
          {inputList.map((file, idx) => {
            const classes = classNames(
              "mb-3",
              "file-manager__item",
              file.selected && "file-manager__item--selected"
            );

            return (
              <Col lg="3" sm="6" key={idx}>
                <Card
                  small
                  className={classes}
                  onClick={() => handleInputClick(file)}
                >
                  <CardBody className="file-manager__item-preview px-0 pb-0 pt-4">
                    <div
                      style={{
                        backgroundImage: `url("${BUCKET_URL}/${file.image_path}")`,
                      }}
                      className="multipleimage-image"
                    ></div>
                  </CardBody>
                  <CardFooter className="border-top">
                    <h6 className="file-manager__item-title">{file.label}</h6>
                  </CardFooter>
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>
      <props.stats step={props.index} {...props} />
    </div>
  );
};

export default MultipleImage;
