import React, { useContext } from "react";

import ReactTable from "react-table";

import { TableContext } from "./TableContext";

function Table({ columns }) {
  const { data, filter, onFilterUpdate } = useContext(TableContext);

  function onPageChange(value) {
    onFilterUpdate("page", value);
  }

  return (
    <ReactTable
      selectType="checkbox"
      className="furnitureGroupTable"
      showPaginationTop={true}
      onPageChange={onPageChange}
      noDataText={"Nenhum registro encontrado"}
      page={filter.page.value}
      columns={columns}
      data={data}
      pageSize={filter.pageSize.value}
      showPageSizeOptions={false}
      resizable={false}
      previousText="Anterior"
      nextText="Próxima"
      pageText="Página"
      ofText="de"
    />
  );
}

Table.propTypes = {};

export default Table;
