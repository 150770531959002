import { API } from "../config";
import { getCookie } from "../actions/auth";

export const getUser = () => {
  const token = getCookie("token");

  return fetch(`${API}/user`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const updateUser = (userId, user) => {
  const token = getCookie("token");

  return fetch(`${API}/user/${userId}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      if (response.status !== 200) {
        throw new Error("Server error");
      }
      return response;
    })
    .then((response) => {
      return response.json();
    });
};
