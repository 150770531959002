import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormSelect,
  Form,
  FormInput,
  InputGroup,
  InputGroupAddon,
  Button,
} from "shards-react";
import Cards from "react-credit-cards";
import Payment from "payment";
import CreditCardExpiry from "../components/common/CreditCardExpiry";
import { getUser } from "../actions/user";
import { getVoucherByCode } from "../actions/voucher";
import { PhoneNumberUtil } from "google-libphonenumber";
import CpfCnpj from "../components/common/CpfCnpj";
import PhoneBR from "../components/common/PhoneBR";
import { valida_cpf_cnpj } from "../utils/form_validation";
import TermsModal from "../components/CheckoutTokstok/TermsModal";
import { updateUser } from "../actions/user";
import { getQueryStringValue } from "../actions/auth";
import { getAllQuestions } from "../actions/question";
import { charge } from "../actions/payment";
import { BUCKET_URL } from "../config";
import { fireEvent } from "../utils/tag-manager";
import { emailValidation } from "../utils/form_validation";
import { checkCPFDECORA20 } from "../utils/checkCpf";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-credit-cards/es/styles-compiled.css";

import { calculateInterest } from "../utils/payment";
import { sanitizeString } from "../utils/tag-manager";
import { useHistory } from "react-router-dom";

const CheckoutTokStok = () => {
  const history = useHistory();
  const MAX_INSTALLMENTS = 3;
  const phoneUtil = PhoneNumberUtil.getInstance();
  const [loading, setLoading] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [creditCardInfo, setCreditCardInfo] = useState({
    cvc: "",
    expiry: "",
    expiryMonth: "",
    expiryYear: "",
    focus: "",
    name: "",
    number: "",
  });
  const [projectTemplatesToBuy, setProjectTemplatesToBuy] = useState([]);

  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    document: "",
    phone: "",
    terms: true,
  });

  const [storedUser, setStoredUser] = useState(null);
  const [step1, setStep1] = useState(true);
  const [step2, setStep2] = useState(false);

  const formValidations = {
    firstName: { validators: [required], isValid: true },
    lastName: { validators: [required], isValid: true },
    email: { validators: [required, emailValidation], isValid: true },
    document: { validators: [required, valida_cpf_cnpj], isValid: true },
    phone: { validators: [required, phoneValidation], isValid: true },
  };

  const [voucher, setVoucher] = useState({
    amount: 0,
    typeValue: "",
    code: "",
  });
  const [installments, setInstallments] = useState(1);
  const installmentsPrice = [];
  for (let i = 1; i <= MAX_INSTALLMENTS; i++) {
    let priceWithInterest = calculateInterest(getTotal(), i);
    let installmentWithInterest = priceWithInterest / i;
    installmentsPrice.push(i === 1 ? getTotal() : installmentWithInterest);
  }
  const totalWithInterest = calculateInterest(getTotal(), installments);

  useEffect(() => {
    validateUrl();
    Promise.all([getUser(), getAllQuestions()]).then((data) => {
      handleUserRequest(data[0]);
      handleProjectTemplatesRequest(data[1]);
    });
  }, []);

  function handleUserRequest(data) {
    setStoredUser(data);
    let newUser = {};
    if (data.profile) {
      if (data.profile.firstName) {
        newUser.firstName = data.profile.firstName;
      }
      if (data.profile.lastName) {
        newUser.lastName = data.profile.lastName;
      }
      if (data.profile.document) {
        newUser.document = data.profile.document;
      }
      if (data.profile.phone) {
        newUser.phone = formatPhone(data.profile.phone);
      }
    }
    if (data.email) {
      newUser.email = data.email;
    }

    setUser(newUser);
  }

  function validateUrl() {
    const projectTemplatesQtd = getQueryStringValue("qty");
    let projectTemplatesHashes = getQueryStringValue("environment");

    if (typeof projectTemplatesHashes == "string") {
      projectTemplatesHashes = [projectTemplatesHashes];
    }

    if (
      projectTemplatesHashes &&
      projectTemplatesHashes.length > 0 &&
      projectTemplatesQtd &&
      projectTemplatesHashes.length === projectTemplatesQtd.length
    ) {
    } else {
      history.push("/environments");
    }
  }

  function handleProjectTemplatesRequest(data) {
    const projectTemplatesQtd = getQueryStringValue("qty");
    const envsUrlArr = [];
    let projectTemplatesHashes = getQueryStringValue("environment");
    let projectTemplates = [];

    data.forEach((question) => {
      if (question.status === "enabled") {
        projectTemplates.push({
          id: question._id,
          title: question.name,
          hash: question.hash,
          image: question.thumbnail
            ? BUCKET_URL + "/" + question.thumbnail
            : require("../images/environments/home_office.jpg"),
          price: question.price,
          quantity: 0,
        });
      }
    });

    if (typeof projectTemplatesHashes == "string") {
      projectTemplatesHashes = [projectTemplatesHashes];
    }

    if (
      projectTemplatesHashes &&
      projectTemplatesHashes.length > 0 &&
      projectTemplatesQtd &&
      projectTemplatesHashes.length === projectTemplatesQtd.length
    ) {
      projectTemplatesHashes.forEach((projectTemplateHash, idx) => {
        let projectTemplate = projectTemplates.find(
          (projectTemplate) => projectTemplate.hash === projectTemplateHash
        );
        if (projectTemplate) {
          projectTemplate.quantity = parseInt(projectTemplatesQtd[idx]);
          envsUrlArr.push({
            price: projectTemplate.price,
            image: projectTemplate.image,
            hash: projectTemplate.hash,
            title: projectTemplate.title,
            quantity: projectTemplate.quantity,
          });
        }
      });
      envsUrlArr.forEach((env, idx) => {
        let provQuestion = projectTemplates.find(
          (project) => project.hash === env.hash
        );
        provQuestion.quantity += env.quantity;
      });

      fireEvent("checkout", "tokstok:decora:enhanced-ecommerce", "checkout", {
        noInteraction: "1",
        ecommerce: {
          checkout: {
            actionField: {
              step: "1",
            },
            products: envsUrlArr.map((projectTemplate) => {
              return {
                name: sanitizeString(projectTemplate.title),
                id: projectTemplate.hash,
                price: projectTemplate.price.toFixed(2),
                quantity: projectTemplate.quantity,
                brand: "tok&stok",
                variant: returnVariant(projectTemplate.quantity),
              };
            }),
          },
        },
      });
      setProjectTemplatesToBuy(envsUrlArr);
    }
  }

  const notifyPending = () =>
    toast.success("Recebemos seu pedido com sucesso.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notify = () =>
    toast.success("Compra realizada com sucesso!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const notifyError = () =>
    toast.error("Compra não autorizada", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  function required(value) {
    return !!value;
  }

  function validate() {
    let fieldNames = Object.keys(formValidations);
    let validations = fieldNames.reduce((acc, current) => {
      acc[current] = isFieldValid(current);
      return acc;
    }, {});
    applyValidationsToForm(validations);
  }

  function isFieldValid(field) {
    let value = user[field];
    let fieldValidations = formValidations[field].validators;
    let result = fieldValidations.reduce((isValid, nextValidation) => {
      return isValid && nextValidation(value);
    }, true);
    return result;
  }

  function confirmProfileValidation(e) {
    e.preventDefault();
    validate();

    if (isProfileValidForCheckout()) {
      storedUser.email = user.email;
      storedUser.profile.phone = `+55${user.phone.replace(/\D/g, "")}`;
      storedUser.profile.document = user.document.replace(/[^0-9]/g, "");

      if (
        `${storedUser.profile.firstName} ${storedUser.profile.lastName}` !==
        user.name
      ) {
        storedUser.profile.firstName = user.firstName;
        storedUser.profile.lastName = user.lastName;
      }

      fireEvent("checkout", "tokstok:decora:enhanced-ecommerce", "checkout", {
        noInteraction: "1",
        ecommerce: {
          checkout: {
            actionField: {
              step: "2",
            },
            products: projectTemplatesToBuy.map((projectTemplate) => {
              return {
                name: sanitizeString(projectTemplate.title),
                id: projectTemplate.hash,
                price: projectTemplate.price.toFixed(2),
                quantity: projectTemplate.quantity,
                brand: "tok&stok",
                variant: returnVariant(projectTemplate.quantity),
              };
            }),
          },
        },
      });
      updateUser(storedUser._id, storedUser)
        .then(() => {
          setStep1(false);
          setStep2(true);
          // setIsLoading(false);
        })
        .catch((err) => {
          // setIsLoading(false);
          toast.error("Ocorreu um erro, por favor, tente novamente", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          console.log(err);
        });
    }
    return false;
  }

  function applyValidationsToForm(validations) {
    let keys = Object.keys(formValidations);
    keys.forEach((key) => {
      formValidations[key].isValid = validations[key];
      if (!validations[key]) {
        user[key] = "";
      }
    });
    setUser({
      ...user,
    });
  }

  function phoneValidation(value) {
    try {
      value = phoneUtil.parse(value, "BR");
      return phoneUtil.isValidNumberForRegion(value, "BR");
    } catch (err) {
      return false;
    }
  }

  function isProfileValidForCheckout() {
    let keys = Object.keys(formValidations);
    return keys.reduce((isValid, key) => {
      return isValid && formValidations[key].isValid;
    }, true);
  }

  function formatPhone(value) {
    try {
      value = phoneUtil.parse(value, "BR");
      if (!phoneUtil.isValidNumberForRegion(value, "BR")) return null;
      return phoneUtil.formatInOriginalFormat(value, "BR");
    } catch (err) {
      return null;
    }
  }

  function handleInputChange(key, value) {
    setCreditCardInfo({
      ...creditCardInfo,
      [key]: value,
    });
  }

  function handleCreditCardNumberChange(ev) {
    if (ev.target.value.match(/^([0-9]| )*$/)) {
      handleInputChange(
        ev.target.name,
        Payment.fns.formatCardNumber(ev.target.value)
      );
    }
  }

  function handleInputChangeEvent(ev) {
    handleInputChange(ev.target.name, ev.target.value);
  }

  function handleInputFocus(ev) {
    setCreditCardInfo({
      ...creditCardInfo,
      focus: ev.target.name,
    });
  }

  function handleCvcChanged(ev) {
    if (ev.target.value.match(/^\d{0,4}$/))
      handleInputChange(ev.target.name, ev.target.value);
  }

  function onExpiryChange(value) {
    setCreditCardInfo({
      ...creditCardInfo,
      focus: "expiry",
      expiry: `${value.month}/${value.year}`,
      expiryMonth: value.month,
      expiryYear: value.year,
    });
  }

  function onInputChange(ev) {
    setUser({
      ...user,
      [ev.target.name]: ev.target.value,
    });
  }

  function getDiscount() {
    let quantityAmbientes = projectTemplatesToBuy.reduce(
      (acc, current) => current.quantity + acc,
      0
    );
    if (quantityAmbientes === 1) {
      return "";
    } else if (quantityAmbientes === 2) {
      return <b>8% de desconto</b>;
    } else {
      return <b>12% de desconto</b>;
    }
  }

  function getFullTotal() {
    return projectTemplatesToBuy.reduce(
      (acc, current) => current.price * current.quantity + acc,
      0
    );
  }

  function getTotal() {
    const fullTotal = getFullTotal();
    let total = 0;
    let projectTemplatesCount = projectTemplatesToBuy.reduce(
      (acc, current) => current.quantity + acc,
      0
    );

    if (projectTemplatesCount === 1) {
      total = fullTotal;
    } else if (projectTemplatesCount === 2) {
      total = parseInt(fullTotal * 0.923);
    } else {
      total = parseInt(fullTotal * 0.883);
    }

    if (voucher && voucher.amount) {
      let discountedTotal = 0;
      let voucherValue = voucher.amount;
      let discount = 0;
      if (voucher.typeValue === "fixed") {
        discount = total - voucherValue;
        if (discount > 0) {
          discountedTotal = discount;
        } else {
          discountedTotal = 0;
        }
      } else {
        discount = total * (voucherValue / 100);
        if (discount > 0) {
          discountedTotal = total - discount;
        }
      }
      return discountedTotal;
    }
    return total;
  }

  function returnVariant(quantity) {
    if (quantity === 1) {
      return "um-ambiente";
    } else if (quantity === 2) {
      return "dois-ambientes";
    } else if (quantity === 3) {
      return "tres-ambientes";
    } else if (quantity === 4) {
      return "quatro-ambientes";
    } else if (quantity === 5) {
      return "cinco-ambientes";
    } else if (quantity === 6) {
      return "seis-ambientes";
    } else {
      return quantity;
    }
  }

  function onChargeClick() {
    if (
      creditCardInfo.number &&
      creditCardInfo.cvc &&
      creditCardInfo.name &&
      creditCardInfo.expiryMonth &&
      creditCardInfo.expiryYear
    ) {
      let creditCard = {
        number: creditCardInfo.number.replace(/ /g, ""),
        ccv: creditCardInfo.cvc,
        holderName: creditCardInfo.name,
        expiryMonth: creditCardInfo.expiryMonth,
        expiryYear: creditCardInfo.expiryYear,
      };

      setLoading(true);

      const total =
        installments > 1
          ? {
              installments,
              price: totalWithInterest,
            }
          : getTotal();

      setLoading(true);

      charge(
        total,
        creditCard,
        projectTemplatesToBuy.map((pt) => {
          return { ...pt, minPrice: pt.price };
        }),
        voucher ? voucher._id : null
      )
        .then((res) => {
          if (res.status === "PENDING") {
            notifyPending();

            setTimeout(() => {
              history.push("/environments");
            }, 1000);
          } else {
            fireEvent(
              "purchase",
              "tokstok:decora:enhanced-ecommerce",
              "purchase",
              {
                noInteraction: "1",
                ecommerce: {
                  purchase: {
                    actionField: {
                      id: res.transactionId,
                      revenue: `${
                        installments > 1
                          ? {
                              installments,
                              price: totalWithInterest.toFixed(2),
                            }
                          : getTotal().toFixed(2)
                      }`,
                    },
                    products: projectTemplatesToBuy.map((projectTemplate) => {
                      return {
                        name: sanitizeString(projectTemplate.title),
                        id: projectTemplate.hash,
                        price: projectTemplate.price.toFixed(2),
                        quantity: projectTemplate.quantity,
                        brand: "tok&stok",
                        variant: returnVariant(projectTemplate.quantity),
                      };
                    }),
                  },
                },
              }
            );
            notify();
            setTimeout(() => {
              history.push("/environments-list");
            }, 2000);
          }
        })
        .catch((err) => {
          notifyError();
          setLoading(false);
          console.log(err);
        });
    } else {
      setLoading(false);
      toast.error("Por favor, preencha todos os campos corretamente.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return false;
  }

  function onTermsClick(e) {
    e.preventDefault();
    toggleTermsOfServiceModal();

    return false;
  }

  function toggleTermsOfServiceModal() {
    setIsTermsModalOpen(!isTermsModalOpen);
  }

  function trackInputProfileValidation(ev) {
    fireEvent(
      "event",
      "tokstok:decora:checkout",
      "interacao:campo:confirmacao-dados",
      `preencheu:${ev.target.getAttribute("trackname")}`
    );
  }

  function trackInputCreditCard(ev) {
    fireEvent(
      "event",
      "tokstok:decora:checkout",
      "interacao:campo:pagamento",
      `preencheu:${ev.target.getAttribute("trackname")}`
    );
  }

  function addVoucher() {
    const toastConfig = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    };
    if (!voucher._id) {
      setLoading(true);
      getVoucherByCode(voucher.code).then((result) => {
        if (result != null) {
          if (voucher.code === "DECORA20") {
            if (checkCPFDECORA20(user.document)) {
              setVoucher(result);
              toast.success(
                "Cupom de desconto aplicado com sucesso",
                toastConfig
              );
            } else {
              toast.error(
                "Cupom de desconto não encontrado ou fora de validade",
                toastConfig
              );
            }
          } else {
            setVoucher(result);
            toast.success(
              "Cupom de desconto aplicado com sucesso",
              toastConfig
            );
          }
        } else {
          toast.error(
            "Cupom de desconto não encontrado ou fora de validade",
            toastConfig
          );
        }
        setLoading(false);
      });
    } else {
      toast.error("Cupom de desconto já aplicado", toastConfig);
    }
  }

  return (
    <Container fluid className="main-content-container px-4">
      <ToastContainer />
      <Row className="page-header py-2"></Row>
      <Row>
        <Col lg="4">
          <Card
            small
            className={`mb-4${step1 ? " checkout-card-selected" : ""}`}
            style={{
              padding: "7%",
            }}
          >
            <CardBody className="p-0 pb-3">
              <h6
                className="m-0 mb-4"
                style={{ fontSize: "22px", color: "#0c775a" }}
              >
                1. Dados pessoais
              </h6>
              {step1 ? (
                <Form onSubmit={confirmProfileValidation}>
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled
                      onChange={onInputChange}
                      onBlur={trackInputProfileValidation}
                      trackname="email"
                      name="email"
                      value={user.email}
                      aria-describedby="emailHelp"
                      placeholder="Digite seu email"
                    />
                  </div>
                  <div className="form-group">
                    <label>Primeiro nome</label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      onChange={onInputChange}
                      onBlur={trackInputProfileValidation}
                      trackname="nome"
                      name="firstName"
                      value={user.firstName}
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div className="form-group">
                    <label>Último nome</label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      onChange={onInputChange}
                      onBlur={trackInputProfileValidation}
                      trackname="sobrenome"
                      name="lastName"
                      value={user.lastName}
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div className="form-group">
                    <label>CPF</label>
                    <CpfCnpj
                      type="text"
                      className="form-control"
                      required
                      onChange={(document) => {
                        setUser({
                          ...user,
                          document: document.value,
                        });
                      }}
                      onBlur={trackInputProfileValidation}
                      trackname="documento"
                      name="document"
                      value={user.document}
                      aria-describedby="emailHelp"
                      placeholder="999.999.999-99"
                    />
                  </div>
                  <div className="form-group">
                    <label>Telefone</label>
                    <PhoneBR
                      required
                      value={user.phone}
                      onChange={(phone) => setUser({ ...user, phone })}
                      placeholder="Informe seu telefone"
                      autoComplete="phone"
                      name="phone"
                      className="form-control"
                    />
                  </div>

                  <div style={{ display: "grid" }}>
                    <button
                      type="submit"
                      className="btn btn-custom-primary mt-3"
                      data-gtm-event-category="tokstok:decora:home"
                      data-gtm-event-action="clique:botao:confirmacao-dados"
                      data-gtm-event-label="li-e-aceito-os-termos"
                      style={{
                        backgroundColor: "rgb(10, 119, 90)",
                        borderColor: "rgb(10, 119, 90)",
                        color: "white",
                        fontSize: "16px",
                      }}
                    >
                      {"Ir para o pagamento"}
                    </button>
                  </div>
                  <div
                    className="form-group"
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    {/* eslint-disable  */} Ao fazer seu pedido, você concorda
                    com os{" "}
                    <a href="" onClick={onTermsClick}>
                      Termos de uso
                    </a>{" "}
                    da Meu Ambiente Tok&Stok
                    {/* eslint-disable  */}
                  </div>
                </Form>
              ) : (
                <>
                  <p style={{ marginBottom: "7px" }}>{user.email}</p>
                  <p style={{ marginTop: "11px", marginBottom: "7px" }}>
                    {user.firstName} {user.lastName}
                  </p>
                  <p
                    style={{
                      marginTop: "11px",
                      marginBottom: "7px",
                      color: "#0a775a",
                      textDecoration: "underline",
                      float: "right",
                    }}
                    onClick={() => {
                      setStep1(true);
                      setStep2(false);
                    }}
                  >
                    Alterar dados
                  </p>
                </>
              )}
            </CardBody>
          </Card>
        </Col>
        <Col lg="4">
          {step2 ? (
            <Card
              small
              className={`mb-4${step2 ? " checkout-card-selected" : ""}`}
              style={{
                padding: "7%",
              }}
            >
              <CardBody className="p-0 pb-3">
                <h6
                  className="m-0 mb-4"
                  style={{ fontSize: "22px", color: "#0c775a" }}
                >
                  2. Pagamento
                </h6>

                <>
                  <Cards
                    cvc={creditCardInfo.cvc}
                    expiry={creditCardInfo.expiry}
                    focused={creditCardInfo.focus}
                    name={creditCardInfo.name}
                    number={creditCardInfo.number}
                    locale={{ valid: "Validade" }}
                    placeholders={{
                      name: "Seu nome",
                      expiry: "Validade",
                    }}
                  />
                  <Form className="py-4">
                    {/* <LoadingOverlay active={loading} spinner={<BounceLoader />}> */}
                    <Row form className="mx-4">
                      <Col lg="12" className="form-group">
                        <label htmlFor="checkout-card-number">
                          Número do cartão
                        </label>
                        <FormInput
                          disabled={loading}
                          type="tel"
                          theme="accent"
                          name="number"
                          required
                          placeholder="1234 5678 1234 4321"
                          id="checkout-card-number"
                          className="unknown"
                          value={creditCardInfo.number}
                          onChange={handleCreditCardNumberChange}
                          onFocus={handleInputFocus}
                          trackname="cartao-numero"
                          onBlur={trackInputCreditCard}
                        />
                      </Col>
                      <Col lg="12" className="form-group">
                        <label htmlFor="checkout-card-name">
                          Nome no cartão
                        </label>
                        <FormInput
                          type="text"
                          disabled={loading}
                          id="checkout-card-name"
                          name="name"
                          placeholder="João da silva"
                          required
                          value={creditCardInfo.name}
                          onChange={handleInputChangeEvent}
                          onFocus={handleInputFocus}
                          trackname="cartao-nome"
                          onBlur={trackInputCreditCard}
                        />
                      </Col>
                      <Col lg="12">
                        <label>Validade</label>
                        <CreditCardExpiry
                          disabled={loading}
                          month={creditCardInfo.expiryMonth}
                          year={creditCardInfo.expiryYear}
                          trackname="cartao-vencimento"
                          onChange={onExpiryChange}
                          onBlur={trackInputCreditCard}
                        />
                      </Col>

                      <Col lg="12" className="form-group">
                        <label htmlFor="checkout-card-name">CVV</label>
                        <FormInput
                          disabled={loading}
                          type="tel"
                          name="cvc"
                          required
                          placeholder="123"
                          value={creditCardInfo.cvc}
                          onChange={handleCvcChanged}
                          onFocus={handleInputFocus}
                          trackname="cartao-cvv"
                          onBlur={trackInputCreditCard}
                        />
                      </Col>
                      <Col className="form-group">
                        <label htmlFor="voucher">Cupom de desconto</label>
                        <InputGroup>
                          <FormInput
                            disabled={voucher._id || loading}
                            type="text"
                            name="voucher"
                            value={voucher.code}
                            onChange={(ev) =>
                              setVoucher({ ...voucher, code: ev.target.value })
                            }
                          />
                          <InputGroupAddon type="append">
                            <Button
                              title="Adicionar"
                              theme="white"
                              onClick={() => addVoucher()}
                            >
                              <i className="material-icons">&#xe148;</i>{" "}
                              Adicionar
                            </Button>
                          </InputGroupAddon>
                        </InputGroup>
                      </Col>
                    </Row>
                    {/* </LoadingOverlay> */}
                    {/* </form> */}
                  </Form>
                  <div style={{ display: "grid" }}>
                    <FormSelect
                      className="ml-auto mr-3"
                      value={installments}
                      style={{ maxWidth: "130px" }}
                      onChange={(ev) =>
                        setInstallments(parseInt(ev.target.value))
                      }
                    >
                      {getTotal() > 0 &&
                        installmentsPrice.map((installment, index) => (
                          <option value={index + 1} key={index}>
                            {index + 1}x{" "}
                            {installment.toLocaleString("pt-br", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </option>
                        ))}
                    </FormSelect>
                    <button
                      type="submit"
                      className="btn btn-custom-primary mt-3"
                      onClick={onChargeClick}
                      data-gtm-event-category="tokstok:decora:home"
                      data-gtm-event-action="clique:botao:comprar"
                      data-gtm-event-label="comprar"
                      disabled={loading}
                      style={{
                        backgroundColor: "rgb(10, 119, 90)",
                        borderColor: "rgb(10, 119, 90)",
                        color: "white",
                      }}
                    >
                      {loading ? "Processando..." : "Realizar pagamento"}
                    </button>
                  </div>
                </>
              </CardBody>
            </Card>
          ) : (
            ""
          )}
        </Col>
        <Col lg="4">
          <Card
            small
            className="mb-4"
            style={{
              padding: "7%",
            }}
          >
            <CardBody className="p-0">
              <h6
                className="m-0 mb-4"
                style={{ fontSize: "22px", color: "#0c775a" }}
              >
                Resumo do pedido
              </h6>
              <div>
                {projectTemplatesToBuy.map((projectTemplate, idx) => (
                  <div
                    key={idx}
                    className="d-flex mb-1"
                    style={{
                      backgroundColor: "#8080801c",
                      padding: "8px",
                      borderRadius: "5px",
                    }}
                  >
                    <img
                      alt=""
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "20px",
                      }}
                      src={projectTemplate.image}
                    />
                    <div className="w-100 d-flex flex-column">
                      <span
                        className="mb-2"
                        style={{ textAlign: "right", color: "black" }}
                      >
                        {projectTemplate.title}
                      </span>
                      <span className="ml-auto">
                        Quantidade: {projectTemplate.quantity}
                      </span>
                      <span className="ml-auto mt-auto">
                        Valor:{" "}
                        <strong style={{ color: "green" }}>
                          {projectTemplate.price.toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </strong>
                      </span>
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-4 d-flex">
                <span className="ml-auto">
                  Total:{" "}
                  <h4 className="m-0">
                    {getTotal().toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </h4>
                  {getDiscount()}
                </span>
              </div>
              <div className="mt-4 d-flex">
                <span>
                  * 10% off nos produtos Tok&Stok <br />* Prazo de entrega: 5
                  (cinco) dias úteis após confirmação dos dados
                </span>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <TermsModal
        isTermsModalOpen={isTermsModalOpen}
        toggleTermsOfServiceModal={toggleTermsOfServiceModal}
      />
    </Container>
  );
};

export default CheckoutTokStok;
