import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import Routes from "./routes";

import Authorization from "./components/Auth/Authorization";
import { NotificationProvider } from "./components/Notification/NotificationContext";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/main.scss";
import * as timeago from "timeago.js";

export default () => {
  configLocale();

  function configLocale() {
    const locale = function(number, index, totalSec) {
      // number: the time ago / time in number;
      // index: the index of array below;
      // totalSec: total seconds between date to be formatted and today's date;
      return [
        ["agora mesmo", "agora"],
        ["há %s segundos", "em %s segundos"],
        ["há um minuto", "em um minuto"],
        ["há %s minutos", "em %s minutos"],
        ["há uma hora", "em uma hora"],
        ["há %s horas", "em %s horas"],
        ["há um dia", "em um dia"],
        ["há %s dias", "em %s dias"],
        ["há uma semana", "em uma semana"],
        ["há %s semanas", "em %s semanas"],
        ["há um mês", "em um mês"],
        ["há %s meses", "em %s meses"],
        ["há um ano", "em um ano"],
        ["há %s anos", "em %s anos"],
      ][index];
    };

    timeago.register("pt_BR", locale);
  }
  return (
    <Router basename={process.env.REACT_APP_BASENAME || ""}>
      <Authorization>
        <NotificationProvider>
          <Routes />
        </NotificationProvider>
      </Authorization>
    </Router>
  );
};
