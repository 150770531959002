import React from "react";
import PropTypes from "prop-types";

import Loader from "react-loader-spinner";
import Table from "./Table";
import Filters from "./Filters";

import { TableRowProvider } from "./TableRowContext";
import { TableProvider } from "./TableContext";

function FilteredTable({
  list,
  columns,
  config,
  children,
  customFilters,
  isLoading,
}) {
  function onPageChange() {
    console.log("OnPageChange");
  }

  return (
    <>
      <TableProvider
        value={{
          furnitures: list,
          searchableColumns: config.searchableColumns,
          pageSize: config.pageSize,
          customFilters,
          onFilterChange: (furnitures) => {
            console.log("Filter change");
          },
        }}
      >
        <TableRowProvider value={list}>
          {children}

          {isLoading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                height: "150px",
              }}
            >
              <Loader width="50px" height="50px" type="Rings" color="black" />
            </div>
          ) : (
            <Table columns={columns} onPageChange={onPageChange} />
          )}
        </TableRowProvider>
      </TableProvider>
    </>
  );
}

FilteredTable.propTypes = {
  searchableColumns: PropTypes.arrayOf(PropTypes.string),
};

FilteredTable.defaultProps = {
  list: [],
  customFilters: [],
  isLoading: false,
  config: {
    pageSizeOptions: [5, 10, 15, 20, 30],
    searchableColumns: null,
    pageSize: 10,
  },
};

export default FilteredTable;
