import React from "react";
import { Container, Button } from "shards-react";
import { useHistory } from "react-router-dom";

const Errors = () => {
  const history = useHistory();
  return (
    <Container fluid className="main-content-container px-4 pb-4">
      <div className="error">
        <div className="error__content">
          <h2>404</h2>
          <h3>Página não encontrada.</h3>
          <p>Clique no botão abaixo para voltar.</p>
          <Button onClick={() => history.replace("/")} pill>
            &larr; Voltar
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default Errors;
