export default function() {
  return [
    {
      title: "Ambientes",
      role: 1,
      category: "projetos",
      items: [
        {
          title: "Novo ambiente",
          label: "novo-projeto",
          to: "/environments",
          htmlBefore: '<i class="material-icons">&#xe03b;</i>',
          htmlAfter: "",
        },
        {
          title: "Meus ambientes",
          label: "meus-projetos",
          to: "/environments-list",
          htmlBefore: '<i class="material-icons">&#xe39e;</i>',
          htmlAfter: "",
          reload: true,
        },
      ],
    },
    {
      title: "Pagamento",
      role: 1,
      category: "pagamentos",
      items: [
        {
          title: "Histórico de pagamento",
          label: "historico-de-pagamento",
          to: "/transaction-history",
          htmlBefore: '<i class="material-icons">&#xE889;</i>',
          htmlAfter: "",
        },
      ],
    },
    {
      title: "Dúvidas",
      role: 1,
      category: "duvidas",
      items: [
        {
          title: "FAQ",
          label: "faq",
          to: "/faq",
          htmlBefore: '<i class="material-icons">&#xe8af;</i>',
          htmlAfter: "",
        },
      ],
    },
  ];
}
