import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Fade } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import EnvironmentCard from "../components/environments/EnvironmentCard";
import { fireEvent } from "../utils/tag-manager";
import { getAllQuestions } from "../actions/question";

import { getQueryStringValue } from "../actions/auth";
import { BUCKET_URL } from "../config";
import { ToastContainer } from "react-toastify";

import { calculateInterest } from "../utils/payment";
import { sanitizeString } from "../utils/tag-manager";

import { useHistory } from "react-router-dom";

const Environments = () => {
  const history = useHistory();
  const MAX_INSTALLMENTS = 3;
  let envsUrl = [];
  const envsUrlArr = [];
  const qtyUrl = getQueryStringValue("qty");
  useEffect(() => {
    getAllQuestions().then((data) => {
      let newQuestions = [];
      data.forEach((question) => {
        if (question.status === "enabled") {
          newQuestions.push({
            id: question._id,
            title: question.name,
            hash: question.hash,
            image: question.thumbnail
              ? BUCKET_URL + "/" + question.thumbnail
              : require("../images/environments/home_office.jpg"),
            price: question.price,
            quantity: 0,
          });
        }
      });
      let urlParams = getQueryStringValue("environment");
      if (typeof urlParams == "string") {
        envsUrl.push(urlParams);
      } else {
        envsUrl = urlParams;
      }
      if (
        envsUrl !== undefined &&
        envsUrl.length > 0 &&
        qtyUrl !== undefined &&
        envsUrl.length === qtyUrl.length
      ) {
        let hasEnv = false;
        envsUrl.forEach((env, idx) => {
          let envReturned = data.find((prod) => prod.hash === env);
          if (envReturned) {
            envReturned.quantity = parseInt(qtyUrl[idx]);
            envsUrlArr.push({
              price: envReturned.price,
              hash: envReturned.hash,
              title: envReturned.name,
              quantity: envReturned.quantity,
            });
            hasEnv = true;
          }
        });
        let valueTotal = total;
        envsUrlArr.forEach((env, idx) => {
          let provQuestion = newQuestions.find(
            (project) => project.hash === env.hash
          );
          provQuestion.quantity += env.quantity;
          valueTotal += env.price * env.quantity;
          handleEnvironmentClickPlus(provQuestion);
        });
        setTotal(valueTotal);
        if (hasEnv) {
          openCheckout();
        }
      }
      setQuestions(newQuestions);
    });
    const script = document.createElement("script");
    script.src = "https://assets.pagar.me/checkout/1.1.0/checkout.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  const [questions, setQuestions] = useState([]);
  const [total, setTotal] = useState(0);
  const installmentsPrice = [];

  for (let i = 1; i <= MAX_INSTALLMENTS; i++) {
    let priceWithInterest = calculateInterest(total, i);
    let installmentWithInterest = priceWithInterest / i;
    installmentsPrice.push(i === 1 ? total : installmentWithInterest);
  }

  const handleEnvironmentClickPlus = (environment) => {
    setQuestions(
      questions.map((item) =>
        item.hash === environment.hash
          ? { ...item, quantity: item.quantity + environment.quantity }
          : item
      )
    );
  };

  const openCheckout = () => {
    let items = [];
    let projectsHash = [];
    questions.forEach((value, idx) => {
      if (value.quantity > 0) {
        items.push({
          id: idx,
          title: value.title,
          unit_price: value.price * 100,
          quantity: value.quantity,
          tangible: false,
        });
        for (let index = 0; index < value.quantity; index++) {
          projectsHash.push(value.hash);
        }
      }
    });
  };

  const checkCheckout = () => {
    const selectedProjectTemplates = questions.filter(
      (projectTemplate) => projectTemplate.quantity > 0
    );
    let params = selectedProjectTemplates.reduce(
      (acc, current) =>
        `${acc}&environment=${current.hash}&qty=${current.quantity}`,
      ""
    );
    let eventParams = selectedProjectTemplates.reduce(
      (acc, current, index) =>
        `${acc}${index === 0 ? "" : ","}${sanitizeString(current.title)}`,
      ""
    );

    if (!params) {
      alert(
        "Selecione no mínimo um serviço para continuar realizando a compra!"
      );
    } else {
      fireEvent(
        "event",
        "tokstok:decora:home",
        "clique:botao",
        `comprar:${eventParams}`
      );
      history.push(`/checkout-tokstok?${params}`);
    }
  };

  window.onscroll = function() {
    scrollFunction();
  };

  function scrollFunction() {
    let el = document.getElementById("buy-button-area");
    if (el != null) {
      if (document.documentElement.scrollTop > 90) {
        el.classList.add("fixed-right-botton", "col-md-12");
        el.classList.remove("col-md-6");

        let scrollPosition =
          document.documentElement.scrollHeight -
          document.documentElement.scrollTop;
        if (document.documentElement.clientHeight === scrollPosition) {
          el.style.bottom = el.clientHeight + "px";
        } else {
          el.style.bottom = "0px";
        }
      } else {
        el.classList.add("col-md-6");
        el.classList.remove("fixed-right-botton", "col-md-12");
      }
    }
  }

  return (
    <Container fluid className="main-content-container px-4">
      <ToastContainer />

      <Row className="page-header py-4">
        <Col md="6">
          <PageTitle
            title="Escolha seu ambiente e comece agora"
            className="text-sm-left mb-3 col-sm-8"
          />
        </Col>
        <Col
          id="buy-button-area"
          md="6"
          className="d-flex flex-row-reverse align-items-center"
        >
          <Button onClick={checkCheckout} theme="accent">
            <i className="material-icons">&#xe8cc;</i> Comprar
          </Button>
          <Fade in={!!total} theme="accent" className="mr-2">
            Total:{" "}
            {total.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
          </Fade>
        </Col>
      </Row>
      <div className="file-manager file-manager-cards">
        <EnvironmentCard
          questions={questions}
          setQuestions={setQuestions}
          setTotal={setTotal}
          total={total}
          getQueryStringValue={getQueryStringValue}
          envsUrl={envsUrl}
        />
      </div>
    </Container>
  );
};

export default Environments;
