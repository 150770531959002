import React, { useContext, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Card,
  CardHeader,
  CardBody,
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import ReactTable from "react-table";
import dateFormat from "dateformat";
import { listPayments } from "../actions/payment";
import HistoryPaymentContact from "../components/common/HistoryPaymentContact";

import { ToastContainer } from "react-toastify";
import { AuthContext } from "../components/Auth/AuthContext";

const TransactionHistory = () => {
  const [payments, setPayments] = useState();
  const [openModalSendContact, setOpenModalSendContact] = useState(false);
  const [showNewEnvironment, setShowNewEnvironment] = useState(false);

  const { user } = useContext(AuthContext);

  const handleOpenModal = (e) => {
    e.preventDefault();
    setOpenModalSendContact(!openModalSendContact);
  };

  const toggleSendContactModal = () => {
    setOpenModalSendContact(!openModalSendContact);
  };

  useEffect(() => {
    listPayments().then((data) => {
      if (data.length) {
        let list = [];
        data.forEach((payment, idx) => {
          let classe = "text-warning";
          let statusPagamento = "Processando";
          let metodoPagamento =
            payment.paymentMethod === "credit_card"
              ? "Cartão de Crédito"
              : "boleto";
          if (payment.status === "processing") {
            statusPagamento = "Pendente";
            classe = "text-warning";
          } else if (payment.status === "authorized") {
            statusPagamento = "Autorizada";
            classe = "text-warning";
          } else if (payment.status === "paid") {
            statusPagamento = "Pago";
            classe = "text-success";
          } else if (payment.status === "waiting_payment") {
            statusPagamento = "Aguardando Pagamento";
            classe = "text-warning";
          } else if (payment.status === "refused") {
            statusPagamento = "Recusada";
            classe = "text-danger";
          }

          let res = {
            id: idx + 1,
            date: payment.createdAt,
            number:
              (payment.projectsHash && payment.projectsHash.length) ||
              payment.projectTemplates.length,
            status: statusPagamento,
            total: payment.amount.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            }),
            payment_method: metodoPagamento,
            classe: classe,
          };
          list.push(res);
        });
        if (list.length !== 0) {
          setPayments(list);
        }
      } else {
        setShowNewEnvironment(true);
      }
    });
  }, []);

  const newEnvironment = () => {
    return (
      <div className="error">
        <div className="error__content">
          <h2>&#8251;</h2>
          <h3>Você não possui nenhum ambiente</h3>
          <p>Clique no botão abaixo para comprar um novo ambiente.</p>
          <Button
            pill
            theme="accent"
            onClick={() => window.open("/environments", "_self")}
          >
            &#43; Novo ambiente
          </Button>
        </div>
      </div>
    );
  };

  const showListPayments = () => {
    return (
      <div>
        <Row className="page-header py-4">
          <Col>
            <PageTitle
              title="Histórico de pagamento"
              className="text-sm-left mb-3"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom"></CardHeader>
              <CardBody className="p-0 pb-3">
                <ReactTable
                  columns={tableColumns}
                  data={payments}
                  showPageSizeOptions={false}
                  resizable={false}
                  pageSize={5}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Modal
          size="lg"
          animation={true}
          open={openModalSendContact}
          toggle={toggleSendContactModal}
        >
          <ModalHeader toggle={toggleSendContactModal}>
            Entrar em contato
          </ModalHeader>
          <ModalBody
            className="modalBodyCheckout"
            style={{ maxHeight: "80vh", overflow: "auto" }}
          >
            <HistoryPaymentContact
              user={user}
              toggleSendContactModal={toggleSendContactModal}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  };

  const tableColumns = [
    {
      Header: "#",
      accessor: "id",
      maxWidth: 60,
      className: "text-center",
    },
    {
      Header: "Data",
      accessor: "date",
      className: "text-center",
      minWidth: 150,
      Cell: (row) =>
        dateFormat(new Date(row.original.date), "dd/mm/yyyy HH:MM:ss"),
    },
    {
      Header: "Numero de Ambientes",
      accessor: "number",
      className: "text-center",
      maxWidth: 140,
    },
    {
      Header: "Total",
      accessor: "total",
      maxWidth: 80,
      className: "text-center",
    },
    {
      Header: "Forma de pagamento",
      accessor: "payment_method",
      maxWidth: 150,
      className: "text-center",
    },
    {
      Header: "Status",
      accessor: "status",
      maxWidth: 100,
      className: "text-center",
      Cell: (row) => (
        <span className={row.original.classe}>{row.original.status}</span>
      ),
    },
    {
      Header: "Ações",
      accessor: "actions",
      className: "text-center",
      Cell: (row) => (
        <div className="d-table mx-auto btn-group-sm btn-group">
          <button
            onClick={handleOpenModal}
            type="button"
            className="btn btn-white"
            title="Entrar em contato"
          >
            <i className="material-icons">textsms</i>
          </button>
        </div>
      ),
    },
  ];

  return (
    <Container fluid className="main-content-container px-4">
      <ToastContainer />
      {showNewEnvironment && newEnvironment()}
      {payments && showListPayments()}
    </Container>
  );
};

export default TransactionHistory;
