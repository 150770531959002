import React, { useState } from "react";
import { Button, FormCheckbox } from "shards-react";

const RowSelect = ({ item, onChange }) => {
  function onLocalChange() {
    onChange(item, !item.checked);
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <FormCheckbox small checked={item.checked} onChange={onLocalChange} />
    </div>
  );
};

export default RowSelect;
