import React, { useState } from "react";
import { Card, Fade } from "shards-react";
import DropzoneArea from "../../file-manager-cards/DropzoneArea";
const Upload = (props) => {
  const [countFiles, setCountFiles] = useState();
  const update = (data) => {
    data = data.map((file) => {
      return file.name;
    });
    setCountFiles(data.length);
    props.update({
      question: props.node.node.title,
      answer: data,
    });
  };
  return (
    <div>
      <label>{props.node.node.title}</label>
      <Card small className="mb-3">
        <DropzoneArea projectId={props.projectId} callbackHandleDrop={update} />
      </Card>
      {countFiles && (
        <Fade in={countFiles} theme="accent">
          Arquivos enviados: <b>{countFiles}</b>
        </Fade>
      )}
      <props.stats step={props.index} {...props} />
    </div>
  );
};
export default Upload;
