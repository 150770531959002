import React, { useState, useEffect } from "react";
import { FormInput, Button, Col, Row } from "shards-react";

const SetNameProject = (props) => {
  const STEP = 2;
  const nextStep = () => {
    props.project.completedSteps.push(STEP);
    props.project.name = nameProject;
    props.updateStepCompleted({
      name: nameProject,
      completedSteps: props.project.completedSteps,
      _id: props.project._id,
    });
    props.callbackReturnView(2);
  };
  const [nameProject, setNameProject] = useState("");
  useEffect(() => {
    if (props.project.name) {
      let name = props.project.name;
      setNameProject(name);
    }
  }, []);
  return (
    <div className="projectNameRow">
      <label>Nome da assessoria</label>

      <Row>
        <Col md="10">
          <FormInput
            type="text"
            placeholder="Informe o nome da assessoria"
            value={nameProject}
            onChange={(e) => {
              setNameProject(e.target.value);
            }}
          />
        </Col>
        <Col md="2">
          <Button theme="accent" onClick={nextStep}>
            Salvar
          </Button>
        </Col>
      </Row>

      <div style={{ float: "right" }}></div>
    </div>
  );
};
export default SetNameProject;
