import React, { useState, useContext } from "react";

import { createContext } from "react";
import { AuthContext } from "../Auth/AuthContext";

export const NotificationContext = createContext({});

export const NotificationProvider = ({ children }) => {
  const { user } = useContext(AuthContext);
  const [notifications, setNotifications] = useState(
    user && user.notifications ? user.notifications : []
  );

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        setNotifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
