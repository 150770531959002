import React, { useState } from "react";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import { Row } from "shards-react";
import { sendData } from "../../actions/project";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";

const DropzoneArea = (props) => {
  const [values, setValues] = useState({
    error: false,
    message: "",
    loading: false,
  });

  const handleDrop = (files) => {
    setValues({ ...values, loading: true });
    sendData(files, props.projectId, "customer")
      .then((data) => {
        if (data.error) {
          setValues({ ...values, error: data.error, loading: false });
        } else {
          if (props["idx"] === undefined) {
            props.callbackHandleDrop(files);
          } else {
            props.callbackHandleDrop(files, props.idx);
          }
          setValues({
            ...values,
            message: `${data.msg}`,
            loading: true,
          });
          setTimeout(() => {
            setValues({
              ...values,
              loading: false,
              error: false,
              message: "",
            });
          }, 4000);
        }
      })
      .catch((err) => {
        let msg = err.message;
        if (msg === "Failed to fetch") {
          msg =
            "Ocorreu um problema na conexão com o servidor, por favor, tente novamente.";
        }

        toast.error(msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const showError = () =>
    error ? <div className="alert alert-danger">{error}</div> : "";
  const showMessage = () =>
    message ? <div className="alert alert-info">{message}</div> : "";
  const { error, message, loading } = values;

  return (
    <Row noGutters>
      <div className="file-manager-cards__dropzone w-100 p-2">
        {showError()}
        {showMessage()}
        <Dropzone
          onDrop={handleDrop}
          accept="image/png, image/jpg, image/jpeg, .pdf, .dwg, .skp, .heic"
        >
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                {...getRootProps()}
                className={classNames("dropzone", {
                  "dropzone--isActive": isDragActive,
                })}
              >
                <input {...getInputProps()} />
                <div className="m-0">
                  {!loading &&
                    "Tente soltar alguns arquivos aqui ou clique para selecionar os arquivos a serem enviados."}
                  {loading && <Loader type="Rings" color="#0a775a" />}
                </div>
              </div>
            );
          }}
        </Dropzone>
      </div>
    </Row>
  );
};

export default DropzoneArea;
