const ALLOWED_STATUS = [400, 200, 404];

exports.requestWrapper = function(requestPromise) {
  return requestPromise
    .then((response) => {
      if (ALLOWED_STATUS.includes(response.status)) {
        return Promise.all([Promise.resolve(response), response.json()]);
      } else {
        throw new Error("Ocorreu um erro desconhecido.");
      }
    })
    .then((res) => {
      if (res[0].status === 400) {
        throw new Error(res[1].error);
      }
      return res[1];
    });
};
