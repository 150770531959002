import React from "react";

import { createContext } from "react";

export const TableRowContext = createContext({
  furnitures: [],
  setFurnitures: () => {},
  getFurnitures: () => {},
});

export const TableRowProvider = ({ children, value }) => {
  const furnitures = value;

  return (
    <TableRowContext.Provider
      value={{
        furnitures: furnitures,
      }}
    >
      {children}
    </TableRowContext.Provider>
  );
};
