import React, { useState, useEffect } from "react";
import { Row, Col, FormInput, Button, FormSelect } from "shards-react";
import DropzoneArea from "../../file-manager-cards/DropzoneArea";
import { BUCKET_URL } from "./../../../config";
import { updateProject } from "../../../actions/project";

const FurnitureMeasureForm = (props, index) => {
  const [filesUploaded, setFilesUploaded] = useState([]);
  const [requiredField, setRequiredField] = useState(false);

  useEffect(() => {
    setFilesUploaded(props.project.furnitureMeasures.files);
  }, []);

  const removeImg = (idx, nImg) => {
    const list = [...props.measures];
    list[idx]["files"].splice(nImg, 1);
    props.project.furnitureMeasures.files = list;
    setFilesUploaded(props.project.furnitureMeasures.files);
    updateProject(props.project._id, props.project);
  };

  const update = (data, index) => {
    setRequiredField(true);
    data = data.map((file) => {
      return file.name;
    });

    const list = [...props.measures];

    if (!filesUploaded[index]) {
      list[index]["files"] = [...data];
    } else {
      list[index]["files"] = [...filesUploaded[index]["files"], ...data];
    }

    props.project.furnitureMeasures.files = list;

    setFilesUploaded(props.project.furnitureMeasures.files);

    updateProject(props.project._id, props.project);
    validateFields();
  };

  const handleInputChange = (e, index) => {
    setRequiredField(true);
    if (e.target.value === "") {
      props.setValidForm(false);
    }
    const { name, value } = e.target;

    const list = [...props.measures];
    list[index][name] = value;

    setFilesUploaded(list);
    props.setMeasures(list);

    setFilesUploaded(props.project.furnitureMeasures.files);
    validateFields();
  };

  const handleAddClick = () => {
    props.setMeasures([
      ...props.measures,
      {
        nameMeasure: "",
        height: "",
        width: "",
        length: "",
        quantity: "",
        reform: "yes",
        keep: "yes",
        files: [],
      },
    ]);
    setFilesUploaded([
      ...props.project.furnitureMeasures.files,
      {
        nameMeasure: "",
        height: "",
        width: "",
        length: "",
        quantity: "",
        reform: "yes",
        keep: "yes",
        files: [],
      },
    ]);
  };

  const handleRemoveClick = (index) => {
    const list = [...props.measures];
    list.splice(index, 1);
    props.setMeasures(list);

    setFilesUploaded(props.project.furnitureMeasures.files);
  };

  const sendLightbox = (img) => {
    props.setLightbox({ img: img, open: true });
  };

  const validateFields = () => {
    props.measures.forEach((measure, idx) => {
      if (
        measure.nameMeasure &&
        measure.height &&
        measure.width &&
        measure.length &&
        measure.quantity &&
        measure.reform &&
        measure.keep &&
        measure.files.length > 0
      ) {
        props.setValidForm(true);
      } else {
        props.setValidForm(false);
      }
    });
  };

  return props.measures.map((measure, idx) => {
    return (
      <div className="mt-3" key={idx}>
        <Row form>
          <Col md="4">
            <label>Nome do objeto</label>
            <FormInput
              key={idx}
              type="text"
              className={"requiredIf"}
              required={requiredField}
              name="nameMeasure"
              value={measure.nameMeasure}
              onChange={(e) => handleInputChange(e, idx)}
            />
          </Col>
          <Col md="2">
            <label>Altura (cm)</label>
            <FormInput
              type="text"
              className={"requiredIf"}
              required={requiredField}
              placeholder="Altura (cm)"
              key={idx}
              name="height"
              value={measure.height}
              onChange={(e) => handleInputChange(e, idx)}
            />
          </Col>
          <Col md="2">
            <label>Largura (cm)</label>
            <FormInput
              type="text"
              className={"requiredIf"}
              required={requiredField}
              placeholder="Largura (cm)"
              key={idx}
              name="width"
              value={measure.width}
              onChange={(e) => handleInputChange(e, idx)}
            />
          </Col>
          <Col md="2">
            <label>Comprimento (cm)</label>
            <FormInput
              type="text"
              className={"requiredIf"}
              required={requiredField}
              placeholder="Comprimento (cm)"
              key={idx}
              name="length"
              value={measure.length}
              onChange={(e) => handleInputChange(e, idx)}
            />
          </Col>
        </Row>
        <Row form className="mt-1">
          <Col md="2">
            <label>Quantidade</label>
            <FormInput
              type="text"
              className={"requiredIf"}
              required={requiredField}
              placeholder="Quantidade"
              key={idx}
              name="quantity"
              value={measure.quantity}
              onChange={(e) => handleInputChange(e, idx)}
            />
          </Col>
          <Col md="2">
            <label>Podemos reformar?</label>
            <FormSelect
              className={"requiredIf"}
              required={requiredField}
              key={idx}
              name="reform"
              value={measure.reform}
              onChange={(e) => handleInputChange(e, idx)}
            >
              <option value="yes">Sim</option>
              <option value="no">Não</option>
            </FormSelect>
          </Col>
          <Col md="3">
            <label>Deseja manter o móvel?</label>
            <FormSelect
              className={"requiredIf"}
              required={requiredField}
              key={idx}
              name="keep"
              value={measure.keep}
              onChange={(e) => handleInputChange(e, idx)}
            >
              <option value="yes">Sim</option>
              <option value="no">Não</option>
            </FormSelect>
          </Col>
        </Row>
        <Row form className="mt-1">
          <Col md="8">
            <DropzoneArea
              idx={idx}
              projectId={props.project._id}
              callbackHandleDrop={update}
            />
          </Col>
          <Col md="4" style={{ display: "flex" }}>
            {props.measures.length !== 1 && (
              <Button
                className="mr-2"
                theme="accent"
                size="sm"
                onClick={() => handleRemoveClick(idx)}
              >
                - Remover móvel
              </Button>
            )}
            {props.measures.length - 1 === idx && (
              <Button theme="accent" size="sm" onClick={handleAddClick}>
                + Adicionar novo móvel
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <br />

            {filesUploaded[idx] && filesUploaded[idx]["files"].length > 0 && (
              <div className="user-details__user-data border-top border-bottom p-4">
                <Row>
                  <Col>
                    <h6 className="text-center m-0 mt-2">Arquivos</h6>
                  </Col>
                </Row>
                <Row>
                  {filesUploaded[idx]["files"].map((img, nImg) => {
                    return (
                      <Col
                        sm="12"
                        md="6"
                        lg="4"
                        className="pt-2 text-center"
                        key={nImg}
                      >
                        <div className="d-flex flex-column">
                          <img
                            alt=""
                            className="border rounded img-fluid"
                            style={{ width: "200px", height: "200px" }}
                            src={
                              BUCKET_URL +
                              "/projects/" +
                              props.project._id +
                              "/customer/" +
                              img
                            }
                            onClick={() =>
                              sendLightbox(
                                BUCKET_URL +
                                  "/projects/" +
                                  props.project._id +
                                  "/customer/" +
                                  img
                              )
                            }
                          />
                          <Button
                            outline
                            style={{ width: "200px" }}
                            theme="danger"
                            onClick={() => {
                              removeImg(idx, nImg);
                            }}
                          >
                            Excluir
                          </Button>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            )}
          </Col>
        </Row>
        <hr />
      </div>
    );
  });
};
export default FurnitureMeasureForm;
