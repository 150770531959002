import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import { Button } from "shards-react";
import Moment from "react-moment";
import {
  getNextForcedStepKey,
  limitDateFunction,
} from "../../../services/project";
import { getQueryStringValue } from "../../../actions/auth";
import { updateLogDates } from "../../../actions/project";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    fontFamily: "margem",
    color: "#0a775a",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: "inline-block",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  iconApproved: {
    color: "#0a775a !important",
  },
  iconWaiting: {
    color: "#656f6c !important",
  },
  iconCurrent: {
    color: "#e1b12c !important",
    boxShadow: "0 0 6px grey, 0 0 1px grey",
    borderRadius: "20px",
  },
}));

function getSteps() {
  return [
    "Questionário do perfil",
    "Escolha da assessoria",
    "Nome da assessoria",
    "Questionário do ambiente",
    "Conhecendo seu espaço",
    "Inspiração",
    "Móveis e medidas",
    "Confirmação",
    "Aprovação da assessoria",
    "Download",
  ];
}

function getStepContent(step, project) {
  switch (step) {
    case 0:
      return "";
    case 1:
      return "";
    case 2:
      return "";
    case 3:
      return "";
    case 4:
      return "";
    case 5:
      return "";
    case 6:
      return "";
    case 7:
      return "Informações em análise, você receberá sua confirmação por e-mail em até 1 dia útil.";
    case 8:
      if (project.status === "approved") {
        return "Recebemos todos os dados para o início da sua assessoria! Ela já está em produção e no prazo de 5 dias úteis estará disponível! Obrigada pela confiança!";
      } else if (project.status === "preview") {
        return "Sua assessoria inicial ficou pronta e está disponível na plataforma para sua análise!";
      } else if (project.status === "first_review") {
        return "Sua assessoria revisada ficou pronta e está disponível na plataforma para sua análise!";
      } else if (project.status === "second_review") {
        return "Sua assessoria revisada ficou pronto e está disponível na plataforma para sua análise!";
      } else if (project.status === "waiting_render_approved") {
        return `Recebemos a aprovação do ${project.finalProject.approvedProject} e iniciaremos o detalhamento do material técnico, que estará disponível para verificação e download na plataforma em até 3 dias úteis.`;
      } else if (project.status === "waiting_render_reproved") {
        return "Recebemos sua aprovação da assessoria e iniciaremos o detalhamento do material técnico, que estará disponível para verificação e download na plataforma em até 3 dias úteis.";
      } else if (project.status === "waiting_user_feedback") {
        return "Recebemos sua análise da assessoria, mas antes precisamos tirar algumas dúvidas.";
      } else if (project.status === "waiting_quality") {
        return "Recebemos sua análise da assessoria e vamos considerar suas solicitações para revisão. Em até 3 (três) dias úteis vamos disponibilizar sua assessoria revisada na plataforma.";
      } else if (project.status === "reviewing_quality") {
        return "Recebemos sua análise da assessoria e vamos considerar suas solicitações para revisão. Em até 3 (três) dias úteis vamos disponibilizar sua assessoria revisada na plataforma.";
      } else if (project.status === "waiting_confirm_review") {
        return "Informações da revisão em análise, você receberá sua confirmação por e-mail em até 1 dia útil.";
      } else {
        return "Recebemos sua análise da assessoria e vamos considerar suas solicitações para revisão. Em até 3 (três) dias úteis vamos disponibilizar sua assessoria revisada na plataforma.";
      }
    case 9:
      return "";
    default:
      return "";
  }
}

const Steps = ({ callbackStepCard, project, projectCompletedSteps }) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState(new Set());
  const [skipped, setSkipped] = useState(new Set());
  const [limitDate, setLimitDate] = useState("");

  const steps = getSteps();

  useEffect(() => {
    let nextStep =
      getNextForcedStepKey(project) || Math.max(...projectCompletedSteps);

    setActiveStep(nextStep);
    setCompleted(new Set(projectCompletedSteps));
    let openProject = getQueryStringValue("show");
    if (
      project._id === openProject &&
      !isOnHighlightExceptions(
        project.completedSteps,
        project.forcedStep,
        project.status
      )
    ) {
      callbackStepCard(nextStep + 1, project);
    }
    try {
      setLimitDate(
        limitDateFunction(
          project.logDates,
          project.status,
          project.forcedStep
        ).toString()
      );
    } catch (error) {
      console.log(error);
      limitDateFunction("");
    }
  }, []);

  function isOnHighlightExceptions(completedSteps, forcedSteps, status) {
    const exceptionCompletedStepList = [6, 7];
    const exceptionStatusList = ["submitted", "approved", "waiting_quality"];
    const lastStep = completedSteps[completedSteps.length - 1];
    const lastForcedStep =
      forcedSteps && forcedSteps.length
        ? forcedSteps[forcedSteps.length - 1]
        : null;

    return (
      exceptionCompletedStepList.includes(lastStep) ||
      exceptionCompletedStepList.includes(lastForcedStep) ||
      exceptionStatusList.includes(status)
    );
  }

  const totalSteps = () => {
    return getSteps().length;
  };

  const isStepOptional = (step) => {
    return step === 5;
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const skippedSteps = () => {
    return skipped.size;
  };

  const completedSteps = () => {
    return completed.size;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps() - skippedSteps();
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    if (project.status === "submitted") {
      updateLogDates(project._id, "submitted");
    }
    window.scrollTo(0, 0);
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed
          // find the first step that has been completed
          steps.findIndex((step, i) => !completed.has(i))
        : activeStep + 1;
    callbackStepCard(newActiveStep, project);
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  function isStepComplete(step) {
    return completed.has(step);
  }

  function getStepStyle(step) {
    if (activeStep + 1 === step) {
      return classes.iconCurrent;
    }
    if (completed.has(step)) {
      return classes.iconApproved;
    }
    return classes.iconWaiting;
  }

  function getStepTextStyle(step) {
    if (activeStep + 1 === step) {
      return {
        color: "black",
        fontSize: "12px",
      };
    }

    return {
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: "12px",
    };
  }

  return (
    <div className={classes.root}>
      <div style={{ overflow: "auto" }}>
        <Stepper alternativeLabel activeStep={activeStep}>
          {steps.map((label, index) => {
            if (index === 0 || index === 1) {
              return false;
            }
            const stepProps = {};
            const buttonProps = {};
            if (isStepOptional(index)) {
              buttonProps.optional = (
                <Typography variant="caption">Opcional</Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step
                key={label}
                {...stepProps}
                completed={isStepComplete(index) && index !== activeStep + 1}
              >
                <StepLabel
                  StepIconProps={{
                    classes: {
                      root: getStepStyle(index),
                    },
                  }}
                >
                  <span style={getStepTextStyle(index)}>{label}</span>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
      <div>
        {allStepsCompleted() ? (
          <div style={{ cssFloat: "right" }}>
            <Button theme="accent" onClick={handleNext}>
              Verificar assessoria
            </Button>
          </div>
        ) : (
          <div className="instructions-step">
            {
              <Typography className={classes.instructions}>
                {getStepContent(activeStep, project)}
                {limitDate !== "" ? (
                  <>
                    <br />
                    {project.status === "submitted" ||
                    project.status === "waiting_confirm_review"
                      ? "Data de aprovação dos dados: "
                      : "Data de entrega: "}
                    <Moment format="DD/MM/YYYY">{limitDate}</Moment>
                  </>
                ) : (
                  ""
                )}
              </Typography>
            }
            <div>
              {(() => {
                if (activeStep < 7) {
                  return (
                    <Button
                      theme="accent"
                      disabled={
                        activeStep < 2 || !!getNextForcedStepKey(project)
                      }
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Voltar etapa
                    </Button>
                  );
                }
              })()}

              {(() => {
                if (activeStep === 6) {
                  return (
                    <Button
                      theme="accent"
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      Confirmar
                    </Button>
                  );
                } else if (activeStep === 7) {
                  return (
                    <Button
                      theme="accent"
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                      disabled
                      style={{ float: "right" }}
                    >
                      Aguarde
                    </Button>
                  );
                } else if (activeStep === 8) {
                  return (
                    <Button
                      theme="accent"
                      variant="contained"
                      color="primary"
                      disabled={
                        project.status === "approved" ||
                        project.status === "waiting_quality" ||
                        project.status === "reviewing_quality"
                      }
                      onClick={handleNext}
                      className={classes.button}
                      style={{ float: "right" }}
                    >
                      {project.status === "waiting_user_feedback"
                        ? "Responder chat"
                        : "Verificar review"}
                    </Button>
                  );
                } else {
                  return (
                    <Button
                      theme="accent"
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      Responder
                    </Button>
                  );
                }
              })()}
              {isStepOptional(activeStep) && !completed.has(activeStep) && (
                <Button
                  theme="accent"
                  variant="contained"
                  color="primary"
                  onClick={handleSkip}
                  className={classes.button}
                >
                  Pular
                </Button>
              )}

              {
                // activeStep !== steps.length &&
                // (completed.has(activeStep) ? (
                //   <Typography variant="caption" className={classes.completed}>
                //     Passo {activeStep + 1} já foi finalizado
                //   </Typography>
                // ) : (
                //   <Button
                //     theme="accent"
                //     variant="contained"
                //     color="primary"
                //     onClick={handleComplete}
                //   >
                //     {
                //       completedSteps() === totalSteps() - 1
                //       ? "Finalizar"
                //       : "Completar passo"
                //     }
                //   </Button>
                // ))
              }
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Steps;
