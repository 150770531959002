import React from "react";
import PropTypes from "prop-types";

function NotificationBackdrop({ onClick }) {
  return (
    <div
      className="unclickable"
      onClick={onClick}
      style={{
        position: "fixed",
        padding: "0",
        margin: "",
        zIndex: 1100,
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      }}
    ></div>
  );
}

NotificationBackdrop.propTypes = { onClick: PropTypes.func };

export default NotificationBackdrop;
