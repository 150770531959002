import React, { useState, useEffect } from "react";
import StepWizard from "react-step-wizard";
import { Row, Card, CardBody, Col, Button } from "shards-react";
import PageTitle from "../../common/PageTitle";
import { getDefaultQuestion } from "../../../actions/question";
import MultipleImage from "../types/MultipleImage";
import MultipleText from "../types/MultipleText";
import Text from "../types/Text";
import Upload from "../types/Upload";
import InternalText from "../types/InternalText";
import { walk, getFlatDataFromTree } from "react-sortable-tree";
import { useHistory } from "react-router-dom";

const DefaultQuestions = (props) => {
  const [allForm, setAllForm] = useState([]);
  const answeredQuestions = [];
  let flatData = null;
  const history = useHistory();

  useEffect(() => {
    history.push("/environments-list#step1");
    getDefaultQuestion().then((question) => {
      let treeData = question.template;
      flatData = getFlatData(treeData);
      walkAtTree(treeData);
    });
  }, []);

  const getFlatData = (treeData) => {
    return getFlatDataFromTree({
      treeData,
      getNodeKey,
      ignoreCollapsed: true,
    });
  };

  let answers = [];

  const updateForm = (data) => {
    let form = answers.find((element) =>
      element.question === data.question ? true : false
    );
    if (!form) {
      answers.push(data);
    } else {
      answers.forEach((element) => {
        if (element.question === data.question) {
          element.answer = data.answer;
        }
      });
    }
  };

  const getNodeKey = ({ treeIndex }) => treeIndex;

  const walkAtTree = (treeData) => {
    walk({
      treeData,
      getNodeKey,
      callback: (row) => {
        if (row.node.children && row.node.children.length) {
          let rootNode = flatData.find(
            (element) => element.treeIndex === row.path[0]
          );
          if (!rootNode.childCount) {
            rootNode.childCount = row.node.children.length;
          } else {
            rootNode.childCount += row.node.children.length;
          }
        }

        if (row.node.type === "text") {
          allForm.push(
            <Text
              index={row.treeIndex + 1}
              stats={Stats}
              node={row}
              flatData={flatData}
              update={updateForm}
              answers={answers}
              propsParent={props}
              answeredQuestions={answeredQuestions}
            />
          );
        } else if (row.node.type === "multiple") {
          if (!row.node.input_list[0].image_path) {
            allForm.push(
              <MultipleText
                node={row}
                index={row.treeIndex + 1}
                stats={Stats}
                flatData={flatData}
                update={updateForm}
                answers={answers}
                propsParent={props}
                answeredQuestions={answeredQuestions}
              />
            );
          } else {
            allForm.push(
              <MultipleImage
                node={row}
                index={row.treeIndex + 1}
                stats={Stats}
                flatData={flatData}
                update={updateForm}
                answers={answers}
                propsParent={props}
                answeredQuestions={answeredQuestions}
              />
            );
          }
        } else if (row.node.type === "upload") {
          allForm.push(
            <Upload
              index={row.treeIndex + 1}
              stats={Stats}
              node={row}
              flatData={flatData}
              update={updateForm}
              answers={answers}
              propsParent={props}
              answeredQuestions={answeredQuestions}
            />
          );
        } else if (row.node.type === "internalText") {
          allForm.push(
            <InternalText
              index={row.treeIndex + 1}
              stats={Stats}
              node={row}
              flatData={flatData}
              update={updateForm}
              answers={answers}
              propsParent={props}
              answeredQuestions={answeredQuestions}
            />
          );
        }

        if (row.treeIndex === flatData[flatData.length - 1].treeIndex) {
          setAllForm([...allForm]);
        }
      },
    });
  };

  return (
    <React.Fragment>
      <Row noGutters className="page-header py-4">
        <PageTitle
          title={`Ambiente: Perguntas do perfil `}
          subtitle="Visão Geral"
          md="12"
          className="ml-sm-auto mr-sm-auto"
        />
      </Row>
      <Row>
        <Col>
          <Card className="mb-4">
            <CardBody>
              {!!allForm.length && (
                <StepWizard isHashEnabled transitions>
                  {allForm}
                </StepWizard>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default DefaultQuestions;

const Stats = ({
  goToStep,
  totalSteps,
  step,
  node,
  flatData,
  answers,
  projectId,
  propsParent,
}) => {
  const history = useHistory();
  const nextQuestion = () => {
    window.scrollTo(0, 0);
    if (node.node.input) {
      if (node.node.input.action) {
        goToStep(parseInt(node.node.input.action) + 1);
      } else {
        let parentNode = flatData.find(
          (element) => element.treeIndex === node.path[0]
        );
        let nextStep = !parentNode.childCount
          ? node.path[0] + 2
          : parentNode.childCount + node.path[0] + 2;
        goToStep(nextStep);
      }
    } else {
      let parentNode = flatData.find(
        (element) => element.treeIndex === node.path[0]
      );
      //MUDADO APENAS O 2 para 1
      // let nextStep = !parentNode.childCount
      //   ? node.path[0] + 2
      //   : parentNode.childCount + node.path[0] + 2;
      let nextStep = !parentNode.childCount
        ? node.path[0] + 2
        : parentNode.childCount + node.path[0] + 2;
      goToStep(nextStep);
    }
  };

  const previousStepHistory = () => {
    window.scrollTo(0, 0);
    history.goBack();
  };

  const saveAnswers = () => {
    window.scrollTo(0, 0);
    propsParent.updateDefaultQuestionsCompleted(answers);
    // propsParent.callbackReturnView();
  };

  return (
    <React.Fragment>
      <div style={{ float: "right" }} className="mt-2">
        {step > 1 && (
          <Button
            outline
            theme="accent"
            className="btn mr-1"
            onClick={previousStepHistory}
          >
            Voltar
          </Button>
        )}
        {step < totalSteps ? (
          <Button theme="accent" className="btn" onClick={nextQuestion}>
            Próximo
          </Button>
        ) : (
          <Button theme="accent" className="btn" onClick={saveAnswers}>
            Finalizar
          </Button>
        )}
      </div>
    </React.Fragment>
  );
};
