import React, { useContext, useState } from "react";
import { Badge, NavItem, NavLink } from "shards-react";

import { NotificationContext } from "./NotificationContext";
import {
  dismissNotification,
  readBannerNotifications,
} from "../../actions/notification";

import NotificationContainer from "./NotificationContainer";
import NotificationList from "./NotificationList";
import NoNotifications from "./NoNotifications";
import NotificationBanner from "./NotificationBanner";
import ImportantNotificationsBanner from "./ImportantNotificationsBanner";
import { getLocalStorage } from "../../actions/auth";

function NotificationCenter(props) {
  const [showNotificationPanel, setShowNotificationPanel] = useState(false);
  const [openedNotification, setOpenedNotification] = useState();

  const { notifications, setNotifications } = useContext(NotificationContext);

  const importantNotifications = notifications.filter(
    getInitialBannerNotifications
  );
  const notificationsCount = notifications.filter(
    (notification) => !notification.isRead
  ).length;
  const isAdminAccess = getLocalStorage("isAdminAccess");

  function onNotificationBadgeClick() {
    setShowNotificationPanel(!showNotificationPanel);
  }

  function onDismissNotification(notificationId) {
    setNotifications(
      notifications.filter(
        (notification) => notification._id !== notificationId
      )
    );
    if (!isAdminAccess) {
      dismissNotification(notificationId)
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    }
  }

  function onOpenBanner(notification) {
    setShowNotificationPanel(false);
    setOpenedNotification(notification);
  }

  function onBannerClose() {
    setOpenedNotification(null);
  }

  function onImportantBannerClose() {
    if (!isAdminAccess) {
      const bannerNotifications = notifications
        .filter((notification) => notification.isImportant)
        .map((notification) => notification._id);
      readBannerNotifications(bannerNotifications)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    const clearedImportantNotifications = notifications.map((notification) => {
      notification.isImportant = false;
      return notification;
    });

    setNotifications(clearedImportantNotifications);
  }

  function getInitialBannerNotifications(notification) {
    return notification.isImportant && !notification.isRead;
  }

  return (
    <NavItem className=" dropdown notifications">
      <NavLink
        className="nav-link-icon text-center p-0"
        onClick={onNotificationBadgeClick}
      >
        <div
          className="nav-link-icon__wrapper clickable"
          style={{ lineHeight: "0px" }}
        >
          {!!notificationsCount && (
            <Badge theme="danger" pill>
              {notificationsCount}
            </Badge>
          )}
          <span className="material-icons">notifications</span>
        </div>
      </NavLink>

      <NotificationContainer
        show={!!showNotificationPanel}
        onDismiss={onNotificationBadgeClick}
      >
        {notifications.length ? (
          <>
            <NotificationList
              dismissable={false}
              onDismissNotification={onDismissNotification}
              onOpenBanner={onOpenBanner}
              notifications={notifications.filter(
                (notification) => !notification.isDismissable
              )}
            />
            <NotificationList
              dismissable={true}
              onDismissNotification={onDismissNotification}
              onOpenBanner={onOpenBanner}
              notifications={notifications.filter(
                (notification) => notification.isDismissable
              )}
            />
            {/* <DropdownItem className="notification__all text-center">
              Limpar notificações
            </DropdownItem> */}
          </>
        ) : (
          <NoNotifications />
        )}
      </NotificationContainer>

      {openedNotification && (
        <NotificationBanner
          onBannerClose={onBannerClose}
          notification={openedNotification}
        />
      )}
      {!!importantNotifications.length && (
        <ImportantNotificationsBanner
          onBannerClose={onImportantBannerClose}
          notifications={importantNotifications}
        />
      )}
    </NavItem>
  );
}

NotificationCenter.propTypes = {};

export default NotificationCenter;
