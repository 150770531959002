import React, { useState, useEffect } from "react";
import { Row, Card, CardBody, Col, Button } from "shards-react";
import PageTitle from "../../common/PageTitle";
import FurnitureMeasureForm from "../sub-component/FurnitureMeasureForm";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { toast } from "react-toastify";

const FurnitureMeasures = (props) => {
  const STEP = 6;
  const [measuresFiles, setMeasuresFiles] = useState([
    {
      nameMeasure: "",
      height: "",
      width: "",
      length: "",
      quantity: "",
      reform: "yes",
      keep: "yes",
      files: [],
    },
  ]);
  const [measuresDetails, setMeasuresDetails] = useState("");
  const [validForm, setValidForm] = useState(true);
  const [lightbox, setLightbox] = useState({ open: false, img: "" });
  const [hasFuriture, setHasFuriture] = useState(false);

  useEffect(() => {
    if (props.project.furnitureMeasures.files.length > 0) {
      setMeasuresFiles(props.project.furnitureMeasures.files);
      setHasFuriture(true);
    }

    if (props.project.furnitureMeasures.details) {
      setMeasuresDetails(props.project.furnitureMeasures.details);
    }
  }, []);

  const nextStep = () => {
    if (validForm) {
      props.project.completedSteps.push(STEP);
      props.project.furnitureMeasures.files = measuresFiles;
      props.project.furnitureMeasures.details = measuresDetails;
      props.updateStepCompleted({
        furnitureMeasures: {
          ...props.project.furnitureMeasures,
          files: measuresFiles,
          details: measuresDetails,
        },
        completedSteps: props.project.completedSteps,
        _id: props.project._id,
      });
      props.callbackReturnView();
    } else {
      toast.error("Todos os campos são obrigatórios para enviar um móvel!", {
        autoClose: 5000,
      });
    }
  };

  return (
    <React.Fragment>
      <Row noGutters className="page-header py-4">
        <PageTitle
          title={`Ambiente:  ${props.project.name}`}
          subtitle="Visão Geral"
          md="12"
          className="ml-sm-auto mr-sm-auto"
        />
      </Row>
      {lightbox.open && (
        <Lightbox
          mainSrc={lightbox.img}
          reactModalStyle={{ overlay: { zIndex: 10000 } }}
          onCloseRequest={() => setLightbox({ ...lightbox, open: false })}
        />
      )}
      <Row>
        <Col>
          <Card>
            <CardBody>
              <label
                theme="accent"
                className="mb-1"
                style={{ border: "none", cursor: "pointer" }}
                onClick={() => {
                  props.goBack();
                }}
              >
                &#8592; VOLTAR
              </label>
              <h5>Uma das etapas mais importantes!</h5>
              {!hasFuriture && (
                <>
                  <h4 style={{ textAlign: "center" }}>
                    Você possui algum móvel (Móveis soltos e em marcenaria) para
                    ser aproveitado na sua assessoria?
                  </h4>
                  <h6 style={{ textAlign: "center" }}>
                    * Somente utilizaremos os móveis ou objetos enviados por
                    este passo, portanto verifique se foi realizado o envio por
                    outro caminho e por gentileza envie novamente por aqui para
                    validarmos!
                  </h6>
                  <div style={{ textAlign: "center" }}>
                    <Button
                      size="lg"
                      theme="accent"
                      style={{ marginRight: "12px" }}
                      onClick={() => setHasFuriture(!hasFuriture)}
                    >
                      Sim
                    </Button>
                    <Button
                      size="lg"
                      theme="accent"
                      className="btn-outline-accent"
                      onClick={nextStep}
                    >
                      Não
                    </Button>
                  </div>
                </>
              )}
              {hasFuriture && (
                <>
                  <span>
                    Neste momento você ira nos informar as medidas e proporções
                    do seus móveis. Caso não tenha móveis que deseja manter,
                    você poderá pular esta etapa.
                  </span>
                  <FurnitureMeasureForm
                    measures={measuresFiles}
                    setMeasures={setMeasuresFiles}
                    project={props.project}
                    lightbox={lightbox}
                    setLightbox={setLightbox}
                    setValidForm={setValidForm}
                    validForm={validForm}
                  />
                  <br />
                  <span>Deseja adicionar mais alguma observação?</span>
                  <textarea
                    rows="4"
                    className="form-control"
                    placeholder="Observações gerais"
                    value={measuresDetails}
                    onChange={(e) => {
                      setMeasuresDetails(e.target.value);
                    }}
                  />
                  <br />
                  <div style={{ cssFloat: "right" }}>
                    <Button theme="accent" onClick={nextStep}>
                      Continuar
                    </Button>
                  </div>
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default FurnitureMeasures;
