import React from "react";
import { Row, Col, Card, CardFooter, CardBody } from "shards-react";
import { fireEvent, getEventLabelPlusMinus } from "../../utils/tag-manager";

const EnvironmentCard = (props) => {
  const { questions, setQuestions, total, setTotal } = props;

  const handleEnvironmentClickPlus = (environment) => {
    fireEvent(
      "event",
      "tokstok:decora:home",
      "clique:icone:card",
      getEventLabelPlusMinus(environment, "mais")
    );
    setTotal(total + environment.price);
    setQuestions(
      questions.map((item) =>
        item.hash === environment.hash
          ? { ...item, quantity: item.quantity + 1 }
          : item
      )
    );
  };

  const handleEnvironmentClickLess = (environment) => {
    if (environment.quantity > 0) {
      fireEvent(
        "event",
        "tokstok:decora:home",
        "clique:icone:card",
        getEventLabelPlusMinus(environment, "menos")
      );
      setTotal(total - environment.price);
      setQuestions(
        questions.map((item) =>
          item.hash === environment.hash
            ? { ...item, quantity: item.quantity - 1 }
            : item
        )
      );
    }
  };

  return (
    <Row>
      {questions.map((env, idx) => {
        return (
          <Col lg="3" sm="6" key={idx}>
            <Card small className="mb-3" style={{ borderRadius: "0.625rem" }}>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    paddingTop: "100%",
                    display: "flex",
                    width: "100%" /* position: absolute; */,
                  }}
                ></div>
                <img
                  style={{
                    width: "100%",
                  }}
                  src={env.image}
                  alt={env.title}
                />
              </div>
              <CardBody
                style={{
                  justifyContent: "center",
                  padding: "0.1%",
                  minHeight: "57px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div>
                  <h9>{env.title}</h9>
                </div>
                <div>
                  <span className="file-manager__item-size ml-auto">
                    {env.price.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </span>
                </div>
              </CardBody>
              <CardFooter
                className="border-top"
                style={{ padding: ".4rem 1rem" }}
              >
                {/* <div style={{ display: "flex" }}>
                  <span className="file-manager__item-icon">
                    <i className="material-icons">&#xe8cc;</i>
                  </span>
                </div> */}
                <div
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <i
                    className="fas fa-minus-circle fa-lg"
                    onClick={() => handleEnvironmentClickLess(env)}
                    style={{ color: "#0a775a", cursor: "pointer" }}
                  ></i>
                  <input
                    value={questions[idx].quantity}
                    style={{
                      borderRadius: "5px",
                      textAlign: "center",
                      fontSize: "16px",
                      border: "1px solid #acacac",
                      maxWidth: "48px",
                      maxHeight: "33px",
                      margin: "0 10px",
                    }}
                    disabled
                  ></input>

                  <i
                    className="fas fa-plus-circle fa-lg"
                    onClick={() => handleEnvironmentClickPlus(env)}
                    style={{ color: "#0a775a", cursor: "pointer" }}
                  ></i>
                </div>
              </CardFooter>
            </Card>
          </Col>
        );
      })}
    </Row>
  );
};
export default EnvironmentCard;
